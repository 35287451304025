import styled from "styled-components";
import { Button } from "@mui/material/";
import { styled as muiStyled } from "@mui/material/styles/";
import { returnLogo } from "../../../global/helpers";
import theme from "../../../global/theme";

export const Background = styled.div`
  height: 100vh;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url(${theme.images.login});
`;

export const Content = styled.div`
  background-color: white;
  border-radius: 25px;
  height: 55%;
  width: 60%;
  opacity: 100%;
  padding: 10%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const Logo = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 25%;
  width: 100%;
  margin-bottom: 40px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  background-image: url(${returnLogo(theme.images.logo)});
`;

export const FilledButton = muiStyled(Button)({
  textAlign: "center",
  textTransform: "none",
  borderRadius: 15,
  fontSize: 16,
  marginTop: "15px",
  padding: "6px 12px",
  height: "50px",
  width: "100%",
  lineHeight: 1.5,
  backgroundColor: theme.colors.login,
  border: "none",
  "&:hover": {
    backgroundColor: theme.colors.login,
    boxShadow: "none",
  },
  "&:active": {
    boxShadow: "none",
    backgroundColor: theme.colors.login,
  },
});

export const OutlinedButton = muiStyled(Button)({
  color: "black",
  textAlign: "center",
  textTransform: "none",
  fontSize: 16,
  marginTop: "15px",
  padding: "6px 12px",
  height: "50px",
  lineHeight: 1.5,
  backgroundColor: "white",
  border: "2px solid #231F20",
  width: "100%",
  "&:hover": {
    backgroundColor: "white",
    border: "2px solid #231F20",
    boxShadow: "none",
  },
  "&:active": {
    border: "2px solid #231F20",
    boxShadow: "none",
    backgroundColor: "white",
  },
});

export const ButtonContainer = styled.div`
  width: 100%;
  margin-top: 25px;
`;

export const Separator = styled.div`
  width: 20px;
`;

export const Link = styled.p`
  color: ${(props) => (props.disabled ? "#E0E0E0" : "#1c75bc")};
  padding-left: 5px;
  cursor: pointer;
  margin: 0px;
`;

export const LinkContainer = styled.div`
  margin-top: 40px;
  display: flex;
  flex-direction: row;
`;
