import styled from "styled-components";

export const CustomTitle = styled.p`
  margin-bottom: 10px;
  margin-top: 15px;
  display: flex;
  justify-content: flex-start;
  width: 100%;
  font-size: 15px;
`;

export const Space = styled.div`
  height: 10px;
  margin: 0px;
`;
export const MenuContainer = styled.div`
  width: 100% !important;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  overflow-wrap: break-word;
  word-wrap: break-word;
  white-space: normal;
  height: 100%;
`;
export const MenuSubtitle = styled.p`
  line-height: 12px;
  margin: 0px;
  padding: 0px;
  font-size: 10px;
  opacity: 0.5;
`;

export const Red = styled.div`
  color: red;
`;
