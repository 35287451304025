import React from "react";
import {
  TransactionsContainer,
  TransactionContent,
  TransactionDetail,
  TransactionTitle,
  LeftDetail,
  RightDetail,
  DateDetail,
  SuccessDetail,
  FailedDetail,
  NeutralDetail,
  ReferenceDetail,
  AmountDetail,
} from "./styles";
import { connect } from "react-redux";
import * as messages from "./messages";
import TitleHeader from "../../common/TitleHeader";

const mapStateToProps = ({
  dispatch,
  loadReducer,
  globalReducer,
  wifiReducer,
  emoneyReducer,
  billsReducers,
  cignalReducers,
  eGroceryReducer,
  gamingReducer,
  signUpReducer,
}) => ({
  dispatch,
  globalStore: globalReducer,
  loadStore: loadReducer,
  wifiStore: wifiReducer,
  emoneyStore: emoneyReducer,
  billsStore: billsReducers,
  cignalStore: cignalReducers,
  eGroceryStore: eGroceryReducer,
  gamingStore: gamingReducer,
  signUpStore: signUpReducer,
});

const TransactionHistory = ({
  loadStore,
  globalStore,
  emoneyStore,
  wifiStore,
  billsStore,
  cignalStore,
  eGroceryStore,
  signUpStore,
  gamingStore,
}) => {
  const Transaction = ({ transaction, index }) => {
    const createdAt = transaction && transaction.time;
    const newDate = new Date(createdAt);
    const splitDate = newDate.toString().split(" ");

    return (
      <TransactionDetail>
        <LeftDetail>
          <DateDetail>
            <strong>{transaction.name}</strong>
          </DateDetail>
          <ReferenceDetail>{transaction.reference}</ReferenceDetail>
          <DateDetail>
            Date: {splitDate[1]} {splitDate[2]}, {splitDate[3]}
          </DateDetail>
        </LeftDetail>
        <RightDetail>
          <DateDetail>Time: {splitDate[4]}</DateDetail>
          <AmountDetail>
            {messages.peso} {transaction && transaction.amount}
          </AmountDetail>
          {transaction && transaction.status.toUpperCase() === "COMPLETED" ? (
            <SuccessDetail>SUCCESS</SuccessDetail>
          ) : transaction.status.toUpperCase() === "PENDING" ||
            transaction.status.toUpperCase() === "ENQUEUED" ? (
            <NeutralDetail>{transaction.status.toUpperCase()}</NeutralDetail>
          ) : (
            <FailedDetail>FAILED</FailedDetail>
          )}
        </RightDetail>
      </TransactionDetail>
    );
  };

  const TransactionList = ({ store }) => {
    return (
      <>
        {store.transactions.length > 0 ? (
          store.transactions.map((transaction, index) => {
            return <Transaction transaction={transaction} index={index} />;
          })
        ) : store.loading ? (
          <div> Loading ... </div>
        ) : (
          <div> No Recent transactions</div>
        )}
      </>
    );
  };

  const TransactionStoreSwitch = ({ title }) => {
    switch (title) {
      case "BUY LOAD":
        return <TransactionList store={loadStore} />;
      case "WIFI DATA":
        return <TransactionList store={wifiStore} />;
      case "E-Wallet Loading":
        return <TransactionList store={emoneyStore} />;
      case "GAMING EPINS":
        return <TransactionList store={gamingStore} />;
      case "CIGNAL PREPAID":
      case "BUY PREPAID KIT":
      case "APPLY FOR POSTPAID":
      case "PAY POSTPAID":
      case "CIGNAL PLAY":
        return <TransactionList store={cignalStore} />;
      case "HANEPMART":
        return <TransactionList store={eGroceryStore} />;
      case "SIGN UP":
        return <TransactionList store={signUpStore} />;
      default:
        return <TransactionList store={billsStore} />;
    }
  };

  return (
    <TransactionsContainer>
      <TitleHeader
        title={globalStore.pageTitle}
        type={globalStore.sessionDetails.sessionType}
      />{" "}
      <TransactionContent>
        <TransactionTitle>{messages.title}</TransactionTitle>
        <TransactionStoreSwitch title={globalStore.pageTitle} />
      </TransactionContent>
    </TransactionsContainer>
  );
};

export default connect(mapStateToProps)(TransactionHistory);
