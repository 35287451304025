import React, { useState, useRef, useEffect } from "react";
import {
  CustomTitle,
  UploadImageContainer,
  Upload,
  UploadTitle,
  Image,
  HideInput,
  Red,
  Subtitle,
} from "./styles";
import { toastError } from "../../../global/constants";

const UploadImageDetail = ({ title, onChange, value, required, subtitle }) => {
  const [img, setImg] = useState(value);
  const [clicked, setClicked] = useState(false);
  const inputRef = useRef(null);
  const uploadImage = (e) => {
    if (e.target.files[0].size < 4718592) {
      onChange(e.target.files[0]);
      setImg(URL.createObjectURL(e.target.files[0]));
    } else {
      setClicked(false);
      toastError(
        `Your image is too big, kindly select an image less than 4.5mb`
      );
    }
  };

  useEffect(() => {
    function run() {
      if (!img) {
        setClicked(false);
      }
    }
    run();
  }, [img, clicked]);

  return (
    <>
      <CustomTitle>
        {title}
        {required && <Red>*</Red>}
      </CustomTitle>
      <Subtitle>{subtitle}</Subtitle>

      <UploadImageContainer
        onClick={() => {
          if (!clicked && !img) {
            inputRef.current.click();
            setClicked(true);
          }
        }}
      >
        {!img ? (
          <>
            <HideInput type="file" onChange={uploadImage} ref={inputRef} />
            <Upload />
            <UploadTitle> Upload Photo</UploadTitle>
          </>
        ) : (
          <Image src={img} alt="" />
        )}
      </UploadImageContainer>
    </>
  );
};

export default UploadImageDetail;
