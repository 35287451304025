import React, { useEffect } from "react";
import { connect } from "react-redux";
import {
  CategoryButton,
  RightIcon,
  Loading,
  Content,
  CategoryContent,
  Categories,
  TitleBar,
} from "./styles";
import { useLocation } from "react-router";
import { push } from "connected-react-router";
import queryString from "query-string";
import FooterContainer from "../../common/FooterContainer";
import TitleHeader from "../../common/TitleHeader";

const mapStateToProps = ({ dispatch, billsReducers, globalReducer }) => ({
  dispatch,
  billsStore: billsReducers,
  globalStore: globalReducer,
});

const BillersCategories = ({ dispatch, billsStore, globalStore }) => {
  const location = useLocation();
  const params = queryString.parse(location.search);

  useEffect(() => {
    dispatch({
      type: "global/CURRENT_ENDPOINT",
      payload: "CATEGORIES",
    });
    dispatch({
      type: "global/BILLS_FLAG",
      payload: true,
    });
    dispatch({
      type: "bills/REQUEST_TOKEN",
      payload: {
        userId: params.userId,
        sessionId: params.sessionId,
      },
    });
  }, [dispatch]);

  useEffect(() => {
    if (
      billsStore.sessionDetails.sessionToken &&
      billsStore.billers.length < 1
    ) {
      dispatch({
        type: "bills/REQUEST_BILLERS",
        payload: {
          token: billsStore.sessionDetails.sessionToken,
        },
      });
    }
  }, [billsStore.sessionDetails.sessionToken]);

  const chooseCategory = (category) => {
    dispatch({
      type: "bills/SET_CATEGORY",
      payload: category,
    });
    dispatch(push("/pay-bills/list"));
  };

  const retrieveCategories = () => {
    return billsStore.billers.map((bill) => {
      return bill.name;
    });
  };

  return (
    <Content>
      <div>
        <TitleHeader
          title={globalStore.pageTitle}
          type={
            globalStore.sessionDetails && globalStore.sessionDetails.sessionType
          }
        />

        {billsStore.loading ? (
          <Loading>Loading ...</Loading>
        ) : (
          <CategoryContent>
            <TitleBar>Biller Categories</TitleBar>

            <Categories>
              {billsStore.billers.length > 0 &&
                retrieveCategories().map((bills) => {
                  return (
                    <CategoryButton
                      key={bills}
                      onClick={() => {
                        chooseCategory(bills);
                      }}
                    >
                      <div>{bills}</div>
                      <RightIcon />
                    </CategoryButton>
                  );
                })}{" "}
            </Categories>
          </CategoryContent>
        )}
      </div>
      <FooterContainer />
    </Content>
  );
};

export default connect(mapStateToProps)(BillersCategories);
