import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import {
  Content,
  ProceedButton,
  Loading,
  Wait,
  ItemContent,
  TitleBar,
  ItemContainer,
} from "./styles";
import { push } from "connected-react-router";
import ItemGrid from "../../components/common/ItemGrid";
import { useLocation } from "react-router";
import queryString from "query-string";
import { CircularProgress } from "@mui/material/";
import TitleHeader from "../../components/common/TitleHeader";

const mapStateToProps = ({ dispatch, eGroceryReducer, globalReducer }) => ({
  dispatch,
  globalStore: globalReducer,
  eGroceryStore: eGroceryReducer,
});

const BuyEGroceryScreen = ({ dispatch, eGroceryStore, globalStore }) => {
  const [cart, setCart] = useState(eGroceryStore.cartDetails);
  const location = useLocation();
  const params = queryString.parse(location.search);

  const onProceed = () => {
    dispatch({
      type: "egrocery/SET_CART_DETAILS",
      payload: cart,
    });
  };

  useEffect(() => {
    dispatch({
      type: "global/CURRENT_ENDPOINT",
      payload: "HANEPMART",
    });
    dispatch({
      type: "egrocery/REQUEST_TOKEN",
      payload: {
        userId: params.userId,
        sessionId: params.sessionId,
      },
    });
  }, [dispatch, params.userId, params.sessionId]);

  useEffect(() => {
    if (eGroceryStore.sessionDetails.sessionToken) {
      dispatch({
        type: "egrocery/REQUEST_PRODUCTS",
        payload: {
          category: "hanepmart",
          token: eGroceryStore.sessionDetails.sessionToken,
        },
      });
    }
  }, [dispatch, eGroceryStore.sessionDetails.sessionToken]);
  return (
    <>
      <Content>
        <div>
          <TitleHeader
            title={globalStore.pageTitle}
            type={
              globalStore.sessionDetails &&
              globalStore.sessionDetails.sessionType
            }
          />
          {eGroceryStore.products && (
            <ItemContent>
              <TitleBar>Select Product/s</TitleBar>
              <ItemContainer>
                <ItemGrid
                  list={eGroceryStore.products}
                  setter={setCart}
                  value={cart}
                  multiSelect
                  noDescription
                />
              </ItemContainer>
            </ItemContent>
          )}
          {eGroceryStore.loading && (
            <Loading>
              <CircularProgress />
              <Wait>Please wait...</Wait>
            </Loading>
          )}

          <div>
            <ProceedButton
              variant="contained"
              onClick={() => {
                dispatch(push("/hanepmart/fields"));
                onProceed();
              }}
              disabled={!cart.length > 0}
            >
              Checkout ({cart.length})
            </ProceedButton>
          </div>
        </div>
      </Content>
    </>
  );
};

export default connect(mapStateToProps)(BuyEGroceryScreen);
