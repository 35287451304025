import styled from "styled-components";

export const CustomTitle = styled.p`
  margin-bottom: 0px;
  margin-top: 0px;
  display: flex;
  font-size: 15px;
  justify-content: flex-start;
  width: 100%;
  color: black;
`;

export const Value = styled.p`
  font-size: 17px;
  margin-top: 5px;
  display: flex;
  justify-content: flex-start;
  color: black;
  width: 100%;
  margin-left: 15px;
`;

export const LineBreak = styled.hr`
  opacity: 0.2;
`;

export const Space = styled.div`
  height: 10px;
`;

export const Container = styled.div``;
