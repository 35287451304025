import React from "react";
import { Container } from "./styles";
import ImageFooter from "../ImageFooter";
import Footer from "../Footer";

const FooterContainer = () => {
  return (
    <Container>
      {/* <ImageFooter /> */}
      <Footer />
    </Container>
  );
};

export default FooterContainer;
