import React from "react";
import { Typography, Modal } from "@mui/material/";
import { ModalContent } from "./styles";

export default function ModalDetail({
  open,
  handleClose,
  title,
  textContent,
  content,
  buttons,
  extra,
}) {
  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <ModalContent>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            {title}
          </Typography>
          {content ? (
            content
          ) : (
            <>
              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                {textContent}
              </Typography>
            </>
          )}
          {extra && extra()}
          {buttons && buttons()}
        </ModalContent>
      </Modal>
    </div>
  );
}
