import { all, takeEvery, put, call } from "redux-saga/effects";
import { push } from "connected-react-router";
import * as cignal from "./services";
import { actions } from "./reducers";

export function* REQUEST_TOKEN({ payload }) {
  const tokenRes = yield call(cignal.getToken, payload);
  yield put({
    type: "cignal/RECEIVE_TOKEN",
    payload: tokenRes,
  });
  yield put({
    type: "global/RECEIVE_SESSION_DETAILS",
    payload: tokenRes,
  });

  if (tokenRes.error) {
    yield put(push("/not-found"));
  }
}

export function* REQUEST_PRODUCTS({ payload }) {
  yield put({
    type: "cignal/SET_LOADING",
    payload: true,
  });
  const { token, category } = payload;
  const productsRes = yield call(cignal.getProducts, token, category);
  if (productsRes) {
    yield put({
      type: "cignal/SET_LOADING",
      payload: false,
    });
  }
  yield put({
    type: "cignal/RECEIVE_PRODUCTS",
    payload: productsRes,
  });
}

export function* REQUEST_BILL_INFO({ payload }) {
  yield put({
    type: "cignal/SET_LOADING",
    payload: true,
  });
  const { token } = payload;
  const response = yield call(cignal.getBillInfo, token);
  if (response) {
    yield put({
      type: "cignal/SET_LOADING",
      payload: false,
    });
  }
  yield put({
    type: "cignal/RECEIVE_BILL_INFO",
    payload: response,
  });
}

export function* REQUEST_BILLER_FEE({ payload }) {
  yield put({
    type: "cignal/SET_LOADING",
    payload: true,
  });
  const { name, amount, token } = payload;
  const response = yield call(cignal.getBillerFee, name, amount, token);
  if (response) {
    yield put({
      type: "cignal/SET_LOADING",
      payload: false,
    });
  }
  yield put({
    type: "cignal/RECEIVE_BILLER_FEE",
    payload: response,
  });
}

export function* REQUEST_TRANSACT({ payload }) {
  const mobilePayload = {
    sku: payload.sku ? payload.sku : payload.product,
    recipientMobile: payload.accountNumber,
    sessionId: payload.sessionId,
    userId: payload.userId,
    category: payload.category,
  };
  const cignalPayload = {
    sku: payload.sku ? payload.sku : payload.product,
    recipientAccountBox: payload.accountNumber,
    sessionId: payload.sessionId,
    userId: payload.userId,
    category: payload.category,
  };

  let res = "";
  const { userId, sessionId, token, run, sku, ...refactoredPayload } = payload;

  if (payload.accountType === "Mobile Number") {
    res = yield call(cignal.payTransact, mobilePayload, token);
  } else {
    res = yield call(cignal.payTransact, cignalPayload, token);
  }

  if (res.error) {
    yield put({
      type: "global/RECEIVE_TRANSACTION_OUTPUT",
      payload: { ...res, result: res.error },
    });
    yield put({
      type: "cignal/RECEIVE_TRANSACT",
      payload: { ...refactoredPayload, result: res.error },
    });
    yield put(push("/result"));
  } else {
    yield put({
      type: "global/RECEIVE_TRANSACTION_OUTPUT",
      payload: { ...res, result: "success" },
    });
    yield put({
      type: "cignal/RECEIVE_TRANSACT",
      payload: { ...refactoredPayload, result: "success" },
    });
    if (res.transStatus !== "ENQUEUED") {
      yield put(push("/result"));
    }
  }
}

export function* REQUEST_TRANSACT_BILL({ payload }) {
  const returnReformatField = (title) => {
    let label = "";
    const splitKey = title.split("_");

    if (splitKey.length > 1) {
      const newString = splitKey.reduce((previous, current) => {
        return previous + current + " ";
      }, "");

      label = newString;
    } else {
      label = title;
    }
    return label;
  };

  const { token, billerFee, ...removeBillerFee } = payload;
  const res = yield call(cignal.payTransactBill, removeBillerFee, token);
  const resPayload = {
    biller: payload.billerName,
  };

  Object.keys(payload.billerFields).forEach((key, index) => {
    const billObject = payload.billerFields;
    resPayload[returnReformatField(key)] = billObject[key];
  });

  if (res.error) {
    yield put({
      type: "cignal/RECEIVE_TRANSACT_BILL",
      payload: {
        ...resPayload,
        billerFee: billerFee,
        total: billerFee + payload.billerFields.amount,
        result: res.error,
      },
    });
    yield put(push("/result"));
  } else {
    yield put({
      type: "cignal/RECEIVE_TRANSACT_BILL",
      payload: {
        ...resPayload,
        billerFee: billerFee,
        total: billerFee + payload.billerFields.amount,
        result: "success",
      },
    });
    yield put(push("/result"));
  }
}

export function* REQUEST_TRANSACT_KIT({ payload }) {
  const { userId, sessionId, token, category, ...refactoredPayload } = payload;
  const reqPayload = {
    sku: payload.product.sku,
    recipientMobile: payload["Mobile Number"],
    sessionId: sessionId,
    userId: userId,
    category: category,
    firstName: payload["First Name"],
    lastName: payload["Last Name"],
    recipientAddress: payload.address,
    recipientEmail: payload.email,
  };

  const res = yield call(cignal.payTransactKit, reqPayload, token);
  if (res.error) {
    yield put({
      type: "cignal/RECEIVE_TRANSACT_KIT",
      payload: {
        ...refactoredPayload,
        product: payload.product.name,
        result: res.error,
      },
    });
    yield put(push("/result"));
  } else {
    yield put({
      type: "cignal/RECEIVE_TRANSACT_KIT",
      payload: {
        ...refactoredPayload,
        product: payload.product.name,
        result: "success",
      },
    });
    yield put(push("/result"));
  }
}

export function* REQUEST_APPLY_POSTPAID({ payload }) {
  const { userId, sessionId, token, category, ...refactoredPayload } = payload;
  const reqPayload = {
    sku: payload.product.sku,
    recipientMobile: payload["Mobile Number"],
    sessionId: sessionId,
    userId: userId,
    category: category,
    firstName: payload["First Name"],
    lastName: payload["Last Name"],
    recipientAddress: payload.address,
    recipientEmail: payload.email,
    productName: payload.product.name,
  };
  const res = yield call(cignal.applyPostpaid, reqPayload, token);
  if (res.error) {
    yield put({
      type: "cignal/RECEIVE_APPLY_POSTPAID",
      payload: {
        ...refactoredPayload,
        product: payload.product.name,
        result: res.error,
      },
    });
    yield put(push("/result"));
  } else {
    yield put({
      type: "cignal/RECEIVE_APPLY_POSTPAID",
      payload: {
        ...refactoredPayload,
        product: payload.product.name,
        result: "success",
      },
    });
    yield put(push("/result"));
  }
}

export function* REQUEST_TRANSACTIONS({ payload }) {
  yield put({
    type: "cignal/SET_LOADING",
    payload: true,
  });
  const { limit, token } = payload;
  const transactionsRes = yield call(cignal.getTransactions, token, limit);
  let transactions = [];
  if (transactionsRes) {
    transactions = transactionsRes.map((transactions) => {
      return {
        status: transactions.status,
        time: transactions.updatedAt,
        amount: transactions.totalPrice,
        name: transactions.items[0].productSku,
        reference: transactions.referenceId,
      };
    });
  }
  yield put({
    type: "cignal/SET_LOADING",
    payload: false,
  });
  yield put({
    type: "cignal/RECEIVE_TRANSACTIONS",
    payload: transactions,
  });
}

export function* REQUEST_TRANSACTIONS_BILL({ payload }) {
  yield put({
    type: "cignal/SET_LOADING",
    payload: true,
  });
  const { limit, token } = payload;
  const transactionsRes = yield call(cignal.getTransactionsBill, token, limit);
  let transactions = [];
  if (transactionsRes) {
    transactions = transactionsRes.map((transactions) => {
      return {
        status: transactions.status,
        time: transactions.createdAt,
        amount: transactions.amountPaid,
        name: transactions.billerCode,
        reference: transactions.referenceId,
      };
    });
  }
  yield put({
    type: "cignal/SET_LOADING",
    payload: false,
  });
  yield put({
    type: "cignal/RECEIVE_TRANSACTIONS_BILL",
    payload: transactions,
  });
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.REQUEST_TOKEN, REQUEST_TOKEN),
    takeEvery(actions.REQUEST_PRODUCTS, REQUEST_PRODUCTS),
    takeEvery(actions.REQUEST_TRANSACT, REQUEST_TRANSACT),
    takeEvery(actions.REQUEST_TRANSACT_BILL, REQUEST_TRANSACT_BILL),
    takeEvery(actions.REQUEST_TRANSACT_KIT, REQUEST_TRANSACT_KIT),
    takeEvery(actions.REQUEST_APPLY_POSTPAID, REQUEST_APPLY_POSTPAID),
    takeEvery(actions.REQUEST_TRANSACTIONS, REQUEST_TRANSACTIONS),
    takeEvery(actions.REQUEST_TRANSACTIONS_BILL, REQUEST_TRANSACTIONS_BILL),
    takeEvery(actions.REQUEST_BILL_INFO, REQUEST_BILL_INFO),
    takeEvery(actions.REQUEST_BILLER_FEE, REQUEST_BILLER_FEE),
  ]);
}
