import styled from "styled-components";
import { Button } from "@mui/material/";
import { styled as muiStyled } from "@mui/material/styles/";
import { primaryColor } from "../../../global/constants";

export const ModalButtonGreen = muiStyled(Button)({
  textAlign: "center",
  textTransform: "none",
  fontSize: 16,
  padding: "6px 12px",
  height: "40px",
  backgroundColor: primaryColor,
  border: "none",
  width: "40%",
});

export const ProceedBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 20px;
`;

export const CountDown = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
`;

export const Timer = styled.div`
  font-size: 40px;
  color: ${(props) => (props.time < 4 ? primaryColor : `black`)};
`;

export const Content = styled.div`
  width: 100%;
  padding-bottom: 10px;
  display: flex;
`;

export const Link = styled.a`
  color: #1c75bc;
  padding-left: 5px;
  margin: 0px;
  cursor: pointer;
`;

export const Output = styled.div`
  width: 100%;
  word-wrap: break-word;
`;
export const Details = styled.div`
  word-wrap: break-word;

  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const DetailItem = styled.div`
  margin-top: 10px;
  display: flex;
`;
