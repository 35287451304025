import React, { useState } from "react";
import { connect } from "react-redux";
import {
  Content,
  ProceedButton,
  CustomTitle,
  Value,
  ButtonContent,
  FieldsContent,
} from "./styles";
import { push } from "connected-react-router";
import InputDetail from "../../common/InputDetail";
import FooterContainer from "../../common/FooterContainer";
import TitleHeader from "../../common/TitleHeader";
const mapStateToProps = ({ dispatch, cignalReducers, globalReducer }) => ({
  dispatch,
  globalStore: globalReducer,
  cignalStore: cignalReducers,
});

const CignalFields = ({ dispatch, cignalStore, globalStore }) => {
  const [payDetails, setPayDetails] = useState({
    "Mobile Number": cignalStore.payDetails["Mobile Number"],
    "First Name": cignalStore.payDetails["First Name"],
    "Last Name": cignalStore.payDetails["Last Name"],
    address: cignalStore.payDetails.address,
    email: cignalStore.payDetails.email,
    product: cignalStore.product.name,
  });

  const onProceed = () => {
    dispatch({
      type: "cignal/SET_PAY_DETAILS",
      payload: {
        ...payDetails,
        amount: cignalStore.product.basePrice,
        userId: cignalStore.sessionDetails.userId,
        sessionId: cignalStore.sessionDetails.sessionId,
      },
    });
  };

  return (
    <>
      <Content>
        <div>
          <TitleHeader
            title={globalStore.pageTitle}
            type={
              globalStore.sessionDetails &&
              globalStore.sessionDetails.sessionType
            }
          />
          <FieldsContent>
            <CustomTitle>Product</CustomTitle>
            <Value>{payDetails.product}</Value>
            <InputDetail
              title={"Mobile Number"}
              subtitle={"Enter Account/Mobile Number"}
              onChange={(e) => {
                setPayDetails({
                  ...payDetails,
                  "Mobile Number": e.target.value,
                });
              }}
              value={payDetails["Mobile Number"]}
              type="number"
            />
            <InputDetail
              title={"First Name"}
              subtitle={"Enter First Name"}
              onChange={(e) => {
                setPayDetails({ ...payDetails, "First Name": e.target.value });
              }}
              value={payDetails["First Name"]}
            />
            <InputDetail
              title={"Last Name"}
              subtitle={"Enter Last Name"}
              onChange={(e) => {
                setPayDetails({ ...payDetails, "Last Name": e.target.value });
              }}
              value={payDetails["Last Name"]}
            />
            <InputDetail
              title={"Address"}
              subtitle={"Enter Address"}
              onChange={(e) => {
                setPayDetails({ ...payDetails, address: e.target.value });
              }}
              value={payDetails.address}
            />
            <InputDetail
              title={"Email"}
              subtitle={"Enter Email"}
              onChange={(e) => {
                setPayDetails({ ...payDetails, email: e.target.value });
              }}
              value={payDetails.email}
            />
          </FieldsContent>

          <ButtonContent>
            <ProceedButton
              variant="contained"
              onClick={() => {
                dispatch(push("/summary"));
                onProceed();
              }}
              disabled={
                !payDetails.product ||
                !payDetails["First Name"] ||
                !payDetails["Last Name"] ||
                !payDetails.address ||
                !payDetails.email
              }
            >
              Proceed
            </ProceedButton>
          </ButtonContent>
        </div>
        <FooterContainer />
      </Content>
    </>
  );
};

export default connect(mapStateToProps)(CignalFields);
