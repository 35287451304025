import React from "react";
import { CustomTitle, Red } from "./styles";
import { DatePicker } from "@mui/lab";
import { TextField } from "@mui/material/";

const DateDetail = ({ title, onChange, value, required, format }) => {
  return (
    <>
      <CustomTitle>
        {title}
        {required && <Red>*</Red>}
      </CustomTitle>
      <DatePicker
        value={value}
        onChange={(newValue) => {
          onChange(newValue);
        }}
        views={
          format === "YYYY" ? ["year"] : format === "MM" ? ["month"] : ["day"]
        }
        renderInput={(params) => (
          <TextField
            style={{
              width: "100%",
              backgroundColor: "white",
              borderRadius: "5px",
            }}
            {...params}
            inputProps={{
              ...params.inputProps,
              placeholder: format,
              readOnly: true,
            }}
          />
        )}
      />
    </>
  );
};

export default DateDetail;
