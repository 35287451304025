import React, { useState, useEffect } from "react";
import BuyLoadSummary from "../BuyLoadSummary";
import BuyWifiSummary from "../BuyWifiSummary";
import BuyEmoneySummary from "../BuyEmoneySummary";
import BillsPaymentSummary from "../BillsPaymentSummary";
import PrepaidSummary from "../PrepaidSummary";
import DirectLoadSummary from "../DirectLoadSummary";
import KitSummary from "../KitSummary";
import PlaySummary from "../PlaySummary";
import EGrocerySummary from "../EGrocerySummary";
import GamingEpinsSummary from "../GamingEpinsSummary";
import SignUpSummary from "../SignUpSummary";
import * as messages from "./messages";
import {
  TitleBar,
  Content,
  ConfirmButton,
  ButtonBox,
  ModalButtonGreen,
  ModalButtonRed,
  ContentContainer,
  ButtonContent,
  Container,
  HanepmartConfirmSpiel,
} from "./styles";
import ModalDetail from "../../common/ModalDetail";
import InquireCountdown from "../../common/InquireCountdown";
import configs from "../../../configs";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import FooterContainer from "../../common/FooterContainer";
import TitleHeader from "../../common/TitleHeader";

const mapStateToProps = ({
  dispatch,
  loadReducer,
  globalReducer,
  wifiReducer,
  emoneyReducer,
  billsReducers,
  cignalReducers,
  cignalwebReducers,
  gamingReducer,
  eGroceryReducer,
  signUpReducer,
}) => ({
  dispatch,
  globalStore: globalReducer,
  loadStore: loadReducer,
  wifiStore: wifiReducer,
  emoneyStore: emoneyReducer,
  billsStore: billsReducers,
  gamingStore: gamingReducer,
  cignalStore: cignalReducers,
  cignalwebStore: cignalwebReducers,
  eGroceryStore: eGroceryReducer,
  signUpStore: signUpReducer,
});

const PaymentSummary = ({
  dispatch,
  loadStore,
  globalStore,
  wifiStore,
  emoneyStore,
  billsStore,
  cignalStore,
  cignalwebStore,
  gamingStore,
  eGroceryStore,
  signUpStore,
}) => {
  const [disabled, setDisabled] = useState(false);
  const [confirmed, setConfirmed] = useState(false);
  const [confirm, setConfirm] = useState(false);

  const RenderContents = ({ title }) => {
    switch (title) {
      case "BUY LOAD":
        return <BuyLoadSummary detail={loadStore.loadDetails} />;
      case "WIFI DATA":
        return <BuyWifiSummary detail={wifiStore.wifiDetails} />;
      case "E-Wallet Loading":
        return <BuyEmoneySummary detail={emoneyStore.buyDetails} />;
      case "CIGNAL DIRECT LOAD TOOL":
        return <DirectLoadSummary detail={cignalwebStore.payDetails} />;
      case "CIGNAL PREPAID":
        return <PrepaidSummary detail={cignalStore.payDetails} />;
      case "BUY PREPAID KIT":
      case "APPLY FOR POSTPAID":
        return <KitSummary detail={cignalStore.payDetails} />;
      case "CIGNAL PLAY":
        return <PlaySummary detail={cignalStore.payDetails} />;
      case "PAY POSTPAID":
        return <BillsPaymentSummary detail={cignalStore} title={title} />;
      case "HANEPMART":
        return <EGrocerySummary detail={eGroceryStore.groceryDetails} />;
      case "GAMING EPINS":
        return <GamingEpinsSummary detail={gamingStore.payDetails} />;
      case "SIGN UP":
        return (
          <SignUpSummary
            detail={{
              ...signUpStore.formDetails,
              ...signUpStore.idDetails,
            }}
          />
        );
      default:
        return <BillsPaymentSummary detail={billsStore} title={title} />;
    }
  };

  const onConfirm = () => {
    const { request, payload } = dispatchFunction();
    dispatch({
      type: request,
      payload: payload,
    });
  };

  const dispatchFunction = () => {
    switch (globalStore.pageTitle) {
      case "BUY LOAD":
        return {
          request: "load/REQUEST_BUY_LOAD",
          payload: {
            ...loadStore.loadDetails,
            token: loadStore.sessionDetails.sessionToken,
            category: loadStore.category,
            sessionType: globalStore.sessionDetails.sessionType,
          },
        };
      case "E-Wallet Loading":
        return {
          request: "emoney/REQUEST_BUY_EMONEY",
          payload: {
            ...emoneyStore.buyDetails,
            token: emoneyStore.sessionDetails.sessionToken,
            category: emoneyStore.category,
            sessionType: globalStore.sessionDetails.sessionType,
          },
        };
      case "WIFI DATA":
        return {
          request: "wifi/REQUEST_BUY_WIFI",
          payload: {
            ...wifiStore.wifiDetails,
            token: wifiStore.sessionDetails.sessionToken,
            category: wifiStore.category,
            sessionType: globalStore.sessionDetails.sessionType,
          },
        };
      case "GAMING EPINS":
        return {
          request: "gaming/REQUEST_BUY_GAME",
          payload: {
            ...gamingStore.payDetails,
            token: gamingStore.sessionDetails.sessionToken,
            category: "GAMES",
            sessionType: globalStore.sessionDetails.sessionType,
          },
        };
      case "CIGNAL PREPAID":
      case "CIGNAL PLAY":
        return {
          request: "cignal/REQUEST_TRANSACT",
          payload: {
            ...cignalStore.payDetails,
            token: cignalStore.sessionDetails.sessionToken,
            category: cignalStore.category,
          },
        };
      case "BUY PREPAID KIT":
        return {
          request: "cignal/REQUEST_TRANSACT_KIT",
          payload: {
            ...cignalStore.payDetails,
            category: cignalStore.category,
            product: cignalStore.product,
            token: cignalStore.sessionDetails.sessionToken,
          },
        };
      case "APPLY FOR POSTPAID":
        return {
          request: "cignal/REQUEST_APPLY_POSTPAID",
          payload: {
            ...cignalStore.payDetails,
            product: cignalStore.product,
            category: cignalStore.category,
            token: cignalStore.sessionDetails.sessionToken,
          },
        };
      case "HANEPMART":
        return {
          request: "egrocery/REQUEST_BUY_GROCERY",
          payload: {
            ...eGroceryStore.groceryDetails,
            token: eGroceryStore.sessionDetails.sessionToken,
            sessionType: globalStore.sessionDetails.sessionType,
          },
        };
      case "PAY POSTPAID":
        return {
          request: "cignal/REQUEST_TRANSACT_BILL",
          payload: {
            billerCode: cignalStore.billInfo.code,
            billerFields: cignalStore.payDetails,
            billerName: cignalStore.billInfo.name,
            userId: cignalStore.sessionDetails.userId,
            sessionId: cignalStore.sessionDetails.sessionId,
            token: cignalStore.sessionDetails.sessionToken,
            billerFee: cignalStore.billerFee.billerFee,
            category: cignalStore.billInfo.category,
          },
        };
      case "SIGN UP":
        return {
          request: "signup/REQUEST_SIGNUP",
          payload: {
            formDetails: signUpStore.formDetails,
            idDetails: signUpStore.idDetails,
          },
        };
      case "CIGNAL DIRECT LOAD TOOL":
        return {
          request: "cignalweb/REQUEST_TRANSACT",
          payload: {
            ...cignalwebStore.payDetails,
            token: cignalStore.sessionDetails.sessionToken,
            sessionId: cignalStore.sessionDetails.sessionId,
            sessionType: globalStore.sessionDetails.sessionType,
          },
        };
      default:
        return {
          request: "bills/REQUEST_PAY_BILL",
          payload: {
            token: billsStore.sessionDetails.sessionToken,
            billerCode: billsStore.billInfo.code,
            billerFields: billsStore.billDetails,
            billerName: billsStore.billInfo.name,
            billerFee: billsStore.billerFee.billerFee,
            userId: billsStore.sessionDetails.userId,
            sessionId: billsStore.sessionDetails.sessionId,
          },
        };
    }
  };

  const isFeesLoaded = () => {
    switch (globalStore.pageTitle) {
      case "BUY LOAD":
      case "E-Wallet Loading":
      case "WIFI DATA":
      case "CIGNAL DIRECT LOAD TOOL":
      case "CIGNAL PREPAID":
      case "CIGNAL PLAY":
      case "BUY PREPAID KIT":
      case "APPLY FOR POSTPAID":
      case "HANEPMART":
      case "GAMING EPINS":
      case "SIGN UP":
        return false;
      case "PAY POSTPAID":
        return isNaN(cignalStore.billerFee.billerFee);
      default:
        return isNaN(billsStore.billerFee.billerFee);
    }
  };

  const handleDfo = (confirmBool) => {
    dispatch({
      type: "bills/REQUEST_PAY_BILL",
      payload: {
        ...billsStore.transactRequest,
        billTransId: billsStore.transactResponse.billTransId,
        shouldProceed: confirmBool,
      },
    });
  };

  const inquire = () => {
    dispatch({
      type: "bills/REQUEST_INQUIRE",
      payload: {
        token: billsStore.sessionDetails.sessionToken,
        transactionRequest: billsStore.transactRequest,
        billerFee: billsStore.billerFee.billerFee,
      },
    });
  };

  const generalInquire = () => {
    dispatch({
      type: "global/REQUEST_GENERAL_INQUIRE",
      payload: globalStore.sessionDetails.sessionToken,
    });
  };

  useEffect(() => {
    function run() {
      if (
        (isNaN(cignalStore.billerFee.billerFee) ||
          isNaN(billsStore.billerFee.billerFee)) &&
        !confirmed
      ) {
        setDisabled(isFeesLoaded());
      }
    }
    run();
  }, [cignalStore.billerFee, billsStore.billerFee]);

  return (
    <>
      <ModalDetail
        open={
          globalStore.pageTitle === "MERALCO" &&
          billsStore.transactResponse &&
          billsStore.transactResponse.sessionStatus === "FOR_CONFIRMATION"
        }
        handleClose={() => { }}
        title={billsStore.transactResponse.isDFO ? "Heads up!" : "Oh no!"}
        textContent={billsStore.transactResponse.billerMessage}
        buttons={() => {
          return (
            <ButtonBox>
              <ModalButtonGreen
                variant="contained"
                onClick={() => {
                  handleDfo(true);
                }}
              >
                {billsStore.transactResponse.isDFO ? "Proceed" : "Ok"}
              </ModalButtonGreen>
              <ModalButtonRed
                variant="contained"
                onClick={() => {
                  handleDfo(false);
                }}
              >
                Cancel
              </ModalButtonRed>
            </ButtonBox>
          );
        }}
      />
      <InquireCountdown
        startCountDown={
          billsStore.transactResponse &&
          billsStore.transactResponse.sessionStatus === "PENDING"
        }
        openCountdownModal={
          billsStore.transactResponse &&
          billsStore.transactResponse.sessionStatus === "PENDING"
        }
        startCountDownChecker={billsStore.transactResponse.sessionStatus}
        inquire={inquire}
        billsFlag
        handleClose={() => { }}
        setConfirm={setConfirm}
        seconds={configs.config.inquireCountdown}
      />
      {globalStore.sessionDetails &&
        globalStore.sessionDetails.sessionType === "WEB" && (
          <InquireCountdown
            startCountDown={
              globalStore.transactResponse &&
              globalStore.transactResponse.transStatus === "ENQUEUED"
            }
            openCountdownModal={
              globalStore.transactResponse &&
              globalStore.transactResponse.transStatus === "ENQUEUED"
            }
            startCountDownChecker={
              globalStore.transactResponse &&
              globalStore.transactResponse.transStatus
            }
            inquire={generalInquire}
            handleClose={() => { }}
            setConfirm={setConfirm}
            seconds={configs.config.inquireCountdown}
          />
        )}
      {/* {globalStore.pageTitle === "SIGN UP" ? (
        <TitleBar>{messages.applicationTitle}</TitleBar>
      ) : (
        <TitleBar>{messages.title}</TitleBar>
      )} */}
      <Content>
        <div>
          <TitleHeader
            title={globalStore.pageTitle}
            type={
              globalStore.sessionDetails &&
              globalStore.sessionDetails.sessionType
            }
          />{" "}
          <Container>
            {globalStore.pageTitle === "SIGN UP" ? (
              <TitleBar>{messages.applicationTitle}</TitleBar>
            ) : (
              <TitleBar>{messages.title}</TitleBar>
            )}
            <ContentContainer>
              <RenderContents title={globalStore.pageTitle} />
            </ContentContainer>
          </Container>
          <ButtonContent>
            {globalStore.pageTitle === "HANEPMART" && (
              <HanepmartConfirmSpiel>
                Once an order has been submitted, it is considered final and
                non-refundable.
              </HanepmartConfirmSpiel>
            )}
            <ConfirmButton
              variant="contained"
              onClick={() => {
                setDisabled(true);
                setConfirmed(true);
                onConfirm();
              }}
              disabled={disabled}
            >
              {disabled ? messages.processing : messages.confirm}
            </ConfirmButton>
          </ButtonContent>
        </div>
        <FooterContainer />
      </Content>
    </>
  );
};

export default connect(mapStateToProps)(PaymentSummary);
