export const actions = {
  SET_LOADING: "gaming/SET_LOADING",
  REQUEST_TOKEN: "gaming/REQUEST_TOKEN",
  RECEIVE_TOKEN: "gaming/RECEIVE_TOKEN",
  SET_PAY_DETAILS: "gaming/SET_PAY_DETAILS",
  REQUEST_PRODUCTS: "gaming/REQUEST_PRODUCTS",
  RECEIVE_PRODUCTS: "gaming/RECEIVE_PRODUCTS",
  REQUEST_BUY_GAME: "gaming/REQUEST_BUY_GAME",
  RECEIVE_BUY_GAME: "gaming/RECEIVE_BUY_GAME",
  REQUEST_TRANSACTIONS: "gaming/REQUEST_TRANSACTIONS",
  RECEIVE_TRANSACTIONS: "gaming/RECEIVE_TRANSACTIONS",
};

const initialState = {
  loading: true,
  payDetails: {},
  sessionDetails: {},
  products: [],
  transactions: [],
};

export default function gamingReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_LOADING:
      return { ...state, loading: action.payload };
    case actions.SET_PAY_DETAILS:
      return { ...state, payDetails: action.payload };
    case actions.RECEIVE_TOKEN:
      return { ...state, sessionDetails: action.payload };
    case actions.RECEIVE_BUY_GAME:
      return { ...state, payDetails: action.payload };
    case actions.RECEIVE_TRANSACTIONS:
      return { ...state, transactions: action.payload };
    case actions.RECEIVE_PRODUCTS:
      return { ...state, products: action.payload };
    default:
      return state;
  }
}
