import { all, takeEvery, put, call } from "redux-saga/effects";
import * as emoney from "./services";
import { push } from "connected-react-router";
import { actions } from "./reducers";

export function* REQUEST_TOKEN({ payload }) {
  const tokenRes = yield call(emoney.getToken, payload);
  yield put({
    type: "emoney/RECEIVE_TOKEN",
    payload: tokenRes,
  });
  yield put({
    type: "global/RECEIVE_SESSION_DETAILS",
    payload: tokenRes,
  });

  if (tokenRes.error) {
    if (tokenRes.type === "WEB") {
      yield put(push("/dashboard"));
    } else {
      yield put(push("/not-found"));
    }
  }
}

export function* REQUEST_PRODUCTS({ payload }) {
  const { token } = payload;
  const productsRes = yield call(emoney.getProducts, token);
  yield put({
    type: "emoney/RECEIVE_PRODUCTS",
    payload: productsRes,
  });
}

export function* REQUEST_BUY_EMONEY({ payload }) {
  const reqPayload = {
    sku: payload.product,
    recipientMobile: payload.accountNumber,
    sessionId: payload.sessionId,
    userId: payload.userId,
    category: payload.category,
    denom: payload.amount,
  };
  const { token, service } = payload;

  let res = {};
  if (service && service === "Cash out (withdraw)") {
    res = yield call(emoney.cashOut, reqPayload, token);
  } else {
    res = yield call(emoney.buyEmoney, reqPayload, token);
  }
  let resPayload = {
    "E-Money": payload["E-Money"],
    accountNumber: payload.accountNumber,
    product: payload.product,
    amount: payload.amount,
  };
  resPayload = service ? { ...resPayload, service: service } : resPayload;

  if (res.error) {
    yield put({
      type: "global/RECEIVE_TRANSACTION_OUTPUT",
      payload: { ...res, result: res.error },
    });
    yield put({
      type: "emoney/RECEIVE_BUY_EMONEY",
      payload: { ...resPayload, result: res.error },
    });
    yield put(push("/result"));
  } else {
    yield put({
      type: "global/RECEIVE_TRANSACTION_OUTPUT",
      payload: { ...res, result: "success" },
    });
    yield put({
      type: "emoney/RECEIVE_BUY_EMONEY",
      payload: { ...resPayload, result: "success" },
    });

    if (payload.sessionType === "BOT") {
      yield put(push("/result"));
    }
  }
}

export function* REQUEST_TRANSACTIONS({ payload }) {
  const { limit, token } = payload;
  const transactionsRes = yield call(emoney.getTransactions, token, limit);
  if (transactionsRes) {
    yield put({
      type: "emoney/SET_LOADING",
      payload: false,
    });
  }
  const transactions = transactionsRes.map((transactions) => {
    return {
      status: transactions.status,
      time: transactions.updatedAt,
      amount: transactions.totalPrice,
      name: transactions.items[0].productSku,
      reference: transactions.referenceId,
    };
  });
  yield put({
    type: "emoney/RECEIVE_TRANSACTIONS",
    payload: transactions,
  });
}

export default function* rootSaga() {
  yield all([takeEvery(actions.REQUEST_PRODUCTS, REQUEST_PRODUCTS)]);
  yield all([takeEvery(actions.REQUEST_BUY_EMONEY, REQUEST_BUY_EMONEY)]);
  yield all([takeEvery(actions.REQUEST_TOKEN, REQUEST_TOKEN)]);
  yield all([takeEvery(actions.REQUEST_TRANSACTIONS, REQUEST_TRANSACTIONS)]);
}
