import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Content, ProceedButton, ButtonContent, FieldsContent } from "./styles";
import { push } from "connected-react-router";
import InputDetail from "../../components/common/InputDetail";
import SelectDetail from "../../components/common/SelectDetail";
import { useLocation } from "react-router";
import queryString from "query-string";
import FooterContainer from "../../components/common/FooterContainer";
import TitleHeader from "../../components/common/TitleHeader";

const mapStateToProps = ({ dispatch, cignalReducers, globalReducer }) => ({
  dispatch,
  cignalStore: cignalReducers,
  globalStore: globalReducer,
});

const CignalPlayScreen = ({ dispatch, cignalStore, globalStore }) => {
  const location = useLocation();
  const params = queryString.parse(location.search);

  const returnFilteredProducts = (products, subcategory) => {
    const filtered = products.filter((products) => {
      return (
        products.subcategory &&
        products.subcategory.toUpperCase() === subcategory.toUpperCase()
      );
    });
    return filtered;
  };

  const returnAmount = (products, name) => {
    const product = products.filter((product) => {
      return name === product.name;
    });
    return product[0].basePrice;
  };

  const returnSku = (products, name) => {
    const product = products.filter((product) => {
      return name === product.name;
    });
    return product[0].sku;
  };

  const [playDetails, setPlayDetails] = useState({
    product: cignalStore.payDetails.product,
    "Cignal Account/Mobile Number":
      cignalStore.payDetails["Cignal Account/Mobile Number"],
  });

  const onProceed = () => {
    dispatch({
      type: "cignal/SET_PAY_DETAILS",
      payload: {
        ...playDetails,
        userId: cignalStore.sessionDetails.userId,
        sessionId: cignalStore.sessionDetails.sessionId,
      },
    });
  };

  useEffect(() => {
    dispatch({
      type: "global/CURRENT_ENDPOINT",
      payload: "CIGNAL PLAY",
    });
    dispatch({
      type: "cignal/REQUEST_TOKEN",
      payload: {
        userId: params.userId,
        sessionId: params.sessionId,
      },
    });
    dispatch({
      type: "cignal/SET_CATEGORY",
      payload: "PLAY",
    });
  }, [dispatch, params.userId, params.sessionId]);

  useEffect(() => {
    if (cignalStore.sessionDetails.sessionToken) {
      dispatch({
        type: "cignal/REQUEST_PRODUCTS",
        payload: {
          token: cignalStore.sessionDetails.sessionToken,
          category: "cignal",
        },
      });
    }
  }, [cignalStore.sessionDetails.sessionToken, dispatch]);

  return (
    <>
      <Content>
        <div>
          <TitleHeader
            title={globalStore.pageTitle}
            type={
              globalStore.sessionDetails &&
              globalStore.sessionDetails.sessionType
            }
          />
          <FieldsContent>
            <InputDetail
              title={"Cignal Account/Mobile Number"}
              subtitle={"Enter Account/Mobile Number"}
              onChange={(e) => {
                setPlayDetails({
                  ...playDetails,
                  "Cignal Account/Mobile Number": e.target.value,
                });
              }}
              value={playDetails["Cignal Account/Mobile Number"]}
              type="number"
            />

            {cignalStore.products &&
              returnFilteredProducts(cignalStore.products, "PLAY").length >
                0 && (
                <SelectDetail
                  title={"Product"}
                  subtitle={"Choose Product"}
                  options={returnFilteredProducts(cignalStore.products, "PLAY")}
                  value={playDetails.product}
                  menuTitle="name"
                  menuSubtitle="description"
                  onChange={(e) => {
                    setPlayDetails({
                      ...playDetails,
                      product: e.target.value,
                      amount: returnAmount(
                        cignalStore.products,
                        e.target.value
                      ),
                      sku: returnSku(cignalStore.products, e.target.value),
                    });
                  }}
                  optionSubtitle
                />
              )}
          </FieldsContent>
          <ButtonContent>
            <ProceedButton
              variant="contained"
              onClick={() => {
                dispatch(push("/summary"));
                onProceed();
              }}
              disabled={
                !playDetails.product ||
                !playDetails["Cignal Account/Mobile Number"]
              }
            >
              Proceed
            </ProceedButton>
          </ButtonContent>
        </div>
        <FooterContainer />
      </Content>
    </>
  );
};

export default connect(mapStateToProps)(CignalPlayScreen);
