import styled from "styled-components";

export const Content = styled.div`
  display: flex;
  // color: white;
  flex-direction: column;
  justify-content: space-between;
  height: 90vh;
  overflow-y: auto;
`;

export const SectionTitle = styled.div`
  padding: 25px 0px 20px 0px;
  color: white;
  display: flex;
  font-weight: bold;
  font-size: 25px;
  justify-content: center;
  align-items: center;
`;

export const FAQ = styled.div`
  padding: 10px 50px 5px 50px;
`;

export const CenteredFAQ = styled.div`
  display: flex;
  justify-content: center;
  padding: 30px 50px 5px 50px;
  align-items: center;
`;
