const staging = {
  variable_sku: [
    {
      category: "GCASH",
      sku: "GCASHV1",
      minAmount: 10,
      maxRange: 9999,
      isActive: true,
    },
    {
      category: "GCASH",
      sku: "GCASHV2",
      minAmount: 10000,
      maxRange: 25000,
      isActive: true,
    },
    {
      category: "PAYMAYA",
      sku: "PMYV1",
      minAmount: 100,
      maxRange: 4999,
      isActive: true,
    },
    {
      category: "PAYMAYA",
      sku: "PMYV2",
      minAmount: 5000,
      maxRange: 10000,
      isActive: true,
    },
  ],
};

const prod = {
  variable_sku: [
    {
      category: "GCASH",
      sku: "GCASHV1",
      minAmount: 10,
      maxRange: 9999,
      isActive: true,
    },
    {
      category: "GCASH",
      sku: "GCASHV2",
      minAmount: 10000,
      maxRange: 25000,
      isActive: true,
    },
    {
      category: "PAYMAYA",
      sku: "PMYV1",
      minAmount: 100,
      maxRange: 4999,
      isActive: true,
    },
    {
      category: "PAYMAYA",
      sku: "PMYV2",
      minAmount: 5000,
      maxRange: 10000,
      isActive: true,
    },
  ],
};

const { REACT_APP_STAGE } = process.env;
let sku = staging;

if (REACT_APP_STAGE === "production") sku = prod;
else if (REACT_APP_STAGE === "staging") sku = staging;

export default sku;
export const spiels = [
  {
    spiel:
      "There is a HanepBuhay 1% service charge for every transaction. If the cash in amount exceeds the recipient's monthly limit (P8,000 for GCash, P10,000 for PayMaya), a service fee of 2% will be deducted from the amount cashed into the customer's wallet. This is based on GCash and PayMaya's protocols.",
    options: ["GCash", "Paymaya"],
  },
  {
    spiel:
      "There is a HanepBuhay 1% service charge for every transaction. If the cash in amount exceeds the recipient's monthly limit (P10,000 for PayMaya), a service fee of 2% will be deducted from the amount cashed into the customer's wallet. This is based on PayMaya's protocols.",
    options: ["Paymaya"],
  },
  {
    spiel:
      "There is a HanepBuhay 1% service charge for every transaction. If the cash in amount exceeds the recipient's monthly limit (P8,000 for GCash), a service fee of 2% will be deducted from the amount cashed into the customer's wallet. This is based on GCash's protocols.",
    options: ["GCash"],
  },
];
export const services = ["Cash in (add funds)", "Cash out (withdraw)"];
