import React from "react";
import PaymentDetail from "../../common/PaymentDetail";

const KitSummary = ({ detail }) => {
  return (
    <>
      <PaymentDetail title="Product:" value={detail.product} />
      <PaymentDetail title="Mobile Number:" value={detail["Mobile Number"]} />
      <PaymentDetail title="First Name:" value={detail["First Name"]} />
      <PaymentDetail title="Last Name:" value={detail["Last Name"]} />
      <PaymentDetail title="Address:" value={detail.address} />
      <PaymentDetail title="Email:" value={detail.email} />
    </>
  );
};

export default KitSummary;
