import { all, takeEvery, put, call } from "redux-saga/effects";
import * as load from "./service";
import { actions } from "./reducers";
import { push } from "connected-react-router";

export function* REQUEST_BUY_LOAD({ payload }) {
  let reqPayload = {
    sku: payload.sku,
    recipientMobile: payload.mobileNumber,
    sessionId: payload.sessionId,
    userId: payload.userId,
    category: payload.category,
  };
  if (payload.products === "LOAD") {
    reqPayload = { ...reqPayload, denom: payload.amount };
  }
  const { token } = payload;
  const res = yield call(load.buyLoad, reqPayload, token);
  const resPayload = {
    mobileNumber: payload.mobileNumber,
    selectTelco: payload.telco,
    availableProducts: payload.load,
    amount: payload.amount,
    discount: parseFloat(payload.discount.toFixed(2)),
    netTotal: payload.amount - payload.discount,
  };

  if (res.error) {
    yield put({
      type: "global/RECEIVE_TRANSACTION_OUTPUT",
      payload: { ...res, result: res.error },
    });
    yield put({
      type: "load/RECEIVE_BUY_LOAD",
      payload: { ...resPayload, result: res.error },
    });
    yield put(push("/result"));
  } else {
    yield put({
      type: "global/RECEIVE_TRANSACTION_OUTPUT",
      payload: { ...res, result: "success" },
    });
    yield put({
      type: "load/RECEIVE_BUY_LOAD",
      payload: { ...resPayload, result: "success" },
    });
    if (payload.sessionType === "BOT") {
      yield put(push("/result"));
    }
  }
}
export function* REQUEST_PRODUCTS({ payload }) {
  yield put({
    type: "load/SET_LOADING",
    payload: true,
  });
  const { token } = payload;
  const productsRes = yield call(load.getProducts, token);
  if (productsRes) {
    yield put({
      type: "load/SET_LOADING",
      payload: false,
    });
  }
  yield put({
    type: "load/RECEIVE_PRODUCTS",
    payload: productsRes,
  });
}

export function* REQUEST_TOKEN({ payload }) {
  const tokenRes = yield call(load.getToken, payload);
  yield put({
    type: "load/RECEIVE_TOKEN",
    payload: tokenRes,
  });
  yield put({
    type: "global/RECEIVE_SESSION_DETAILS",
    payload: tokenRes,
  });

  if (tokenRes.error) {
    if (tokenRes.type === "WEB") {
      yield put(push("/dashboard"));
    } else {
      yield put(push("/not-found"));
    }
  }
}

export function* REQUEST_TRANSACTIONS({ payload }) {
  yield put({
    type: "load/SET_LOADING",
    payload: true,
  });
  const { limit, token } = payload;
  const transactionsRes = yield call(load.getTransactions, token, limit);
  if (transactionsRes) {
    yield put({
      type: "load/SET_LOADING",
      payload: false,
    });
  }
  const transactions = transactionsRes.map((transactions) => {
    return {
      status: transactions.status,
      time: transactions.updatedAt,
      amount:
        transactions.totalPrice ||
        transactions.items[0].discountedPrice ||
        transactions.items[0].denom,
      name: transactions.items[0].productSku,
      reference: transactions.referenceId,
    };
  });
  yield put({
    type: "load/RECEIVE_TRANSACTIONS",
    payload: transactions,
  });
}

export default function* rootSaga() {
  yield all([takeEvery(actions.REQUEST_BUY_LOAD, REQUEST_BUY_LOAD)]);
  yield all([takeEvery(actions.REQUEST_PRODUCTS, REQUEST_PRODUCTS)]);
  yield all([takeEvery(actions.REQUEST_TOKEN, REQUEST_TOKEN)]);
  yield all([takeEvery(actions.REQUEST_TRANSACTIONS, REQUEST_TRANSACTIONS)]);
}
