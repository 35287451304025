import apiClient from "../axios";
import { toastError } from "./constants";

export async function inquire(token) {
  try {
    const response = await apiClient.get("/web/inquire", {
      headers: {
        Authorization: token,
      },
    });
    return response.data.data;
  } catch (e) {
    const message = e.response.data.error.message;
    const code = e.response.data.error.code;
    toastError(`[${code}] ${message}`);
    return { error: message };
  }
}

export async function createSession(payload) {
  try {
    const response = await apiClient.post("/web/create-session  ", payload, {
      auth: {
        username: "test",
        password: "test",
      },
    });

    return response.data.data;
  } catch (e) {
    const message = e.response.data.error.message;
    const code = e.response.data.error.code;
    toastError(`[${code}] ${message}`);
    return { error: message };
  }
}

export async function getToken(payload) {
  try {
    const response = await apiClient.post("/web/get-token", payload);
    return response.data.data;
  } catch (e) {
    const message = e.response.data.error.message;
    const code = e.response.data.error.code;
    toastError(`[${code}] ${message}`);
    return { error: message };
  }
}
