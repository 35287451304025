import React from "react";
import { NavBar, Logo, LogoutIcon, Space } from "./styles";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import { getLocalStorage, returnLogo } from "../../../global/helpers";
import theme from "../../../global/theme";

const mapStateToProps = ({ dispatch, loginReducer, globalReducer }) => ({
  dispatch,
  loginStore: loginReducer,
  globalStore: globalReducer,
});
const ToolBar = ({ dispatch, loginStore, globalStore }) => {
  return (
    <>
      <NavBar>
        {globalStore.sessionDetails &&
        globalStore.sessionDetails.sessionType === "WEB" ? (
          <LogoutIcon
            onClick={() => {
              const otpDetails = getLocalStorage("otpDetails");
              if (
                loginStore.createResponse &&
                loginStore.createResponse.otpId
              ) {
                dispatch({
                  type: "login/REQUEST_LOGOUT_OTP",
                  payload: { otpId: loginStore.createResponse.otpId },
                });
              } else if (otpDetails) {
                dispatch({
                  type: "login/REQUEST_LOGOUT_OTP",
                  payload: { otpId: otpDetails.otpId },
                });
              } else {
                dispatch(push("/login"));
              }
            }}
          />
        ) : (
          <div></div>
        )}
        <Logo src={returnLogo(theme.images.logo)} />
      </NavBar>
    </>
  );
};

export default connect(mapStateToProps)(ToolBar);
