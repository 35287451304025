import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import {
  Content,
  ProceedButton,
  ButtonContent,
  FieldsContent,
  Red,
} from "./styles";
import { push } from "connected-react-router";
import InputDetail from "../../components/common/InputDetail";
import SelectDetail from "../../components/common/SelectDetail";
import { useLocation } from "react-router";
import queryString from "query-string";
import FooterContainer from "../../components/common/FooterContainer";
import TitleHeader from "../../components/common/TitleHeader";
import config from "../../configs";
import { epinsEncrypt } from "../../global/helpers";

const mapStateToProps = ({ dispatch, cignalwebReducers, globalReducer }) => ({
  dispatch,
  cignalwebStore: cignalwebReducers,
  globalStore: globalReducer,
});

const CignalDirectLoadTool = ({ dispatch, cignalwebStore, globalStore }) => {
  const location = useLocation();
  const params = queryString.parse(location.search);
  const [directLoadDetails, setDirectLoadDetails] = useState({
    epinValue: cignalwebStore.payDetails.epinValue,
    accountNumber: cignalwebStore.payDetails.accountNumber,
  });
  const [errorA, setErrorA] = useState(false);
  const [errorB, setErrorB] = useState(false);

  const onProceed = () => {
    const { epinValue, accountNumber } = directLoadDetails;
    if (epinValue.length > 16 || epinValue.length < 14) {
      setErrorA(true);
    }
    if (accountNumber.length > 10 || accountNumber.length < 8) {
      setErrorB(true);
    }

    if (
      !(epinValue.length > 16 || epinValue.length < 14) &&
      !(accountNumber.length > 10 || accountNumber.length < 8)
    ) {
      dispatch({
        type: "cignalweb/SET_PAY_DETAILS",
        payload: {
          ...directLoadDetails,
          encrypted: epinsEncrypt(
            config.config.epinEncryptor,
            directLoadDetails.epinValue
          ),
          sessionId: cignalwebStore.sessionDetails.sessionId,
        },
      });
      dispatch(push("/summary"));
    }
  };

  useEffect(() => {
    function run() {
      setErrorA(false);
      setErrorB(false);
    }
    run();
  }, [directLoadDetails]);

  useEffect(() => {
    dispatch({
      type: "global/CURRENT_ENDPOINT",
      payload: "CIGNAL DIRECT LOAD TOOL",
    });
    dispatch({
      type: "cignal/REQUEST_TOKEN",
      payload: {
        userId: params.userId,
        sessionId: params.sessionId,
      },
    });
  }, [dispatch, params.userId, params.sessionId]);
  return (
    <>
      <Content>
        <div>
          <TitleHeader
            title={globalStore.pageTitle}
            type={
              globalStore.sessionDetails &&
              globalStore.sessionDetails.sessionType
            }
          />
          <FieldsContent>
            {
              <InputDetail
                title={"Epin Value"}
                subtitle={"Enter Epin Value"}
                onChange={(e) => {
                  setDirectLoadDetails({
                    ...directLoadDetails,
                    epinValue: e.target.value,
                  });
                }}
                value={directLoadDetails.epinValue}
              />
            }
            {errorA && <Red> {"Kindly input a valid epin."}</Red>}

            {directLoadDetails.epinValue && (
              <InputDetail
                title={"Account Number"}
                subtitle={"Enter Account Number"}
                onChange={(e) => {
                  setDirectLoadDetails({
                    ...directLoadDetails,
                    accountNumber: e.target.value,
                  });
                }}
                value={directLoadDetails.accountNumber}
                type="number"
              />
            )}
            {errorB && <Red> {"Kindly input a valid account number."}</Red>}
          </FieldsContent>

          <ButtonContent>
            <ProceedButton
              variant="contained"
              onClick={() => {
                onProceed();
              }}
              disabled={
                !directLoadDetails.epinValue || !directLoadDetails.accountNumber
              }
            >
              Proceed
            </ProceedButton>
          </ButtonContent>
        </div>
        <FooterContainer />
      </Content>
    </>
  );
};

export default connect(mapStateToProps)(CignalDirectLoadTool);
