import { all, takeEvery, put, call } from "redux-saga/effects";
import { push } from "connected-react-router";
import * as cignalweb from "./services";
import { actions } from "./reducers";

export function* REQUEST_TRANSACT({ payload }) {
  const loadPayload = {
    epin: payload.encrypted,
    recipientAccountNo: payload.accountNumber,
    sessionId: payload.sessionId
  };
  const resPayload = {
    epin: payload.epinValue,
    recipientAccountNo: payload.accountNumber,
    sessionId: payload.sessionId
  }

  const res = yield call(cignalweb.payTransact, loadPayload, payload.token);

  if (res.error) {
    yield put({
      type: "global/RECEIVE_TRANSACTION_OUTPUT",
      payload: { ...res, result: res.error },
    });
    yield put({
      type: "cignalweb/RECEIVE_TRANSACT",
      payload: { ...resPayload, result: res.error },
    });
    yield put(push("/result"));
  } else {
    yield put({
      type: "global/RECEIVE_TRANSACTION_OUTPUT",
      payload: { ...res, result: "success" },
    });
    yield put({
      type: "cignalweb/RECEIVE_TRANSACT",
      payload: { ...resPayload, result: "success" },
    });
    if (res.transStatus !== "ENQUEUED") {
      yield put(push("/result"));
    }
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.REQUEST_TRANSACT, REQUEST_TRANSACT),
  ]);
}
