import React, { useEffect } from "react";
import PaymentDetail from "../../common/PaymentDetail";
import { connect } from "react-redux";

const mapStateToProps = ({ dispatch }) => ({
  dispatch,
});

const BillsPaymentSummary = ({ dispatch, detail, title }) => {
  const completeSummary =
    title === "PAY POSTPAID" ? detail.payDetails : detail.billDetails;
  const { userId, sessionId, result, ...summary } = completeSummary;

  const returnReformatTitle = (title) => {
    let label = "";
    const splitKey = title.split("_");
    const upperCase =
      splitKey[0].charAt(0).toUpperCase() + splitKey[0].slice(1);

    if (splitKey.length > 1) {
      splitKey.splice(0, 1);
      const newString = splitKey
        .reduce((previous, current) => {
          return previous + current + " ";
        }, "")
        .slice(0, -1);

      label = upperCase + " " + newString;
    } else {
      label = upperCase;
    }
    return label;
  };

  useEffect(() => {
    if (isNaN(detail.billerFee.billerFee) && !detail.loading) {
      title === "PAY POSTPAID"
        ? dispatch({
            type: "cignal/REQUEST_BILLER_FEE",
            payload: {
              amount: summary.amount,
              token: detail.sessionDetails.sessionToken,
              name: "CGNAL",
            },
          })
        : dispatch({
            type: "bills/REQUEST_BILLER_FEE",
            payload: {
              amount: summary.amount,
              token: detail.sessionDetails.sessionToken,
              name: detail.billInfo.code,
            },
          });
    }
  }, []);

  return (
    <>
      {Object.keys(summary).map((key, index) => {
        if (detail.billInfo.name === "MERALCO" && key === "account_number") {
          return (
            <PaymentDetail
              key={key}
              title={"Meralco Customer Account Number (CAN)"}
              value={summary[key]}
            />
          );
        } else {
          return (
            <PaymentDetail
              key={key}
              title={returnReformatTitle(key)}
              value={summary[key]}
            />
          );
        }
      })}
      <PaymentDetail
        title="Fee"
        value={
          isNaN(detail.billerFee.billerFee)
            ? "Loading..."
            : detail.billerFee.billerFee
        }
      />
      <PaymentDetail
        title="Total"
        value={
          (summary.amount &&
            detail.billerFee.billerFee + parseFloat(summary.amount)) ||
          "Loading..."
        }
      />
    </>
  );
};

export default connect(mapStateToProps)(BillsPaymentSummary);
