export const actions = {
  REQUEST_TOKEN: "cignal/REQUEST_TOKEN",
  RECEIVE_TOKEN: "cignal/RECEIVE_TOKEN",
  REQUEST_PRODUCTS: "cignal/REQUEST_PRODUCTS",
  RECEIVE_PRODUCTS: "cignal/RECEIVE_PRODUCTS",
  SET_PAY_DETAILS: "cignal/SET_PAY_DETAILS",
  REQUEST_TRANSACT: "cignal/REQUEST_TRANSACT",
  RECEIVE_TRANSACT: "cignal/RECEIVE_TRANSACT",
  REQUEST_TRANSACT_BILL: "cignal/REQUEST_TRANSACT_BILL",
  RECEIVE_TRANSACT_BILL: "cignal/RECEIVE_TRANSACT_BILL",
  REQUEST_TRANSACT_KIT: "cignal/REQUEST_TRANSACT_KIT",
  RECEIVE_TRANSACT_KIT: "cignal/RECEIVE_TRANSACT_KIT",
  REQUEST_APPLY_POSTPAID: "cignal/REQUEST_APPLY_POSTPAID",
  RECEIVE_APPLY_POSTPAID: "cignal/RECEIVE_APPLY_POSTPAID",
  REQUEST_TRANSACTIONS: "cignal/REQUEST_TRANSACTIONS",
  RECEIVE_TRANSACTIONS: "cignal/RECEIVE_TRANSACTIONS",
  REQUEST_TRANSACTIONS_BILL: "cignal/REQUEST_TRANSACTIONS_BILL",
  RECEIVE_TRANSACTIONS_BILL: "cignal/RECEIVE_TRANSACTIONS_BILL",
  REQUEST_BILL_INFO: "cignal/REQUEST_BILL_INFO",
  RECEIVE_BILL_INFO: "cignal/RECEIVE_BILL_INFO",
  REQUEST_BILLER_FEE: "cignal/REQUEST_BILLER_FEE",
  RECEIVE_BILLER_FEE: "cignal/RECEIVE_BILLER_FEE",
  SET_CATEGORY: "cignal/SET_CATEGORY",
  SET_PRODUCT: "cignal/SET_PRODUCT",
  SET_LOADING: "cignal/SET_LOADING",
};

const initialState = {
  products: [],
  payDetails: {},
  sessionDetails: {},
  transactions: [],
  category: {},
  product: {},
  billInfo: {},
  billerFee: {},
  loading: true,
};

export default function cignalReducers(state = initialState, action) {
  switch (action.type) {
    case actions.RECEIVE_TOKEN:
      return { ...state, sessionDetails: action.payload };
    case actions.RECEIVE_BILL_INFO:
      return { ...state, billInfo: action.payload };
    case actions.RECEIVE_PRODUCTS:
      return { ...state, products: action.payload };
    case actions.SET_PAY_DETAILS:
    case actions.RECEIVE_TRANSACT:
    case actions.RECEIVE_TRANSACT_BILL:
    case actions.RECEIVE_APPLY_POSTPAID:
    case actions.RECEIVE_TRANSACT_KIT:
      return { ...state, payDetails: action.payload };
    case actions.RECEIVE_TRANSACTIONS:
    case actions.RECEIVE_TRANSACTIONS_BILL:
      return { ...state, transactions: action.payload };
    case actions.RECEIVE_BILLER_FEE:
      return { ...state, billerFee: action.payload };
    case actions.SET_CATEGORY:
      return { ...state, category: action.payload };
    case actions.SET_PRODUCT:
      return { ...state, product: action.payload };
    case actions.SET_LOADING:
      return { ...state, loading: action.payload };
    default:
      return state;
  }
}
