import React from "react";
import { CustomTitle, MenuContainer, MenuSubtitle, Red } from "./styles";
import { FormControl, InputLabel, Select, MenuItem } from "@mui/material/";

const SelectDetail = ({
  title,
  subtitle,
  options,
  onChange,
  value,
  optionSubtitle,
  menuTitle,
  menuSubtitle,
  disabled,
  required,
  selectStyle,
  underCaption,
  containerStyle,
}) => {
  return (
    <div style={containerStyle ? containerStyle : undefined}>
      <CustomTitle>
        {title}
        {required && <Red>*</Red>}
      </CustomTitle>
      <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label">{subtitle}</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          label={subtitle}
          onChange={onChange}
          value={value}
          style={selectStyle ? selectStyle : { backgroundColor: "white" }}
          disabled={disabled ? disabled : false}
        >
          {options.map((option) => {
            if (optionSubtitle) {
              return (
                <MenuItem
                  value={option.title || option[`${menuTitle}`]}
                  key={option.title || option[`${menuTitle}`]}
                >
                  <MenuContainer>
                    {option.title || option[`${menuTitle}`]}
                    <MenuSubtitle>
                      {option.subtitle || option[`${menuSubtitle}`]}
                    </MenuSubtitle>
                  </MenuContainer>
                </MenuItem>
              );
            }
            return (
              <MenuItem value={option} key={option}>
                {option}
              </MenuItem>
            );
          })}
        </Select>
        {underCaption && underCaption()}
      </FormControl>
    </div>
  );
};

export default SelectDetail;
