export const telco = ["GLOBE", "SMART", "SUN CELLULAR", "TM", "TNT", "DITO"];
export const products = ["LOAD", "CALL AND TEXT PROMO", "DATA"];
const staging = {
  variable_sku: [
    {
      network: "globe",
      sku: "GCGLOBEV1",
      minAmount: 5,
      maxRange: 50,
      isActive: true,
    },
    {
      network: "globe",
      sku: "GCGLOBEV2",
      minAmount: 51,
      maxRange: 150,
      isActive: true,
    },
    {
      network: "tm",
      sku: "GCTMV1",
      minAmount: 5,
      maxRange: 50,
      isActive: true,
    },
    {
      network: "tm",
      sku: "GCTMV2",
      minAmount: 51,
      maxRange: 150,
      isActive: true,
    },
    {
      network: "smart",
      sku: "GCSMARTV1",
      minAmount: 5,
      maxRange: 50,
      isActive: true,
    },
    {
      network: "smart",
      sku: "GCSMARTV2",
      minAmount: 51,
      maxRange: 150,
      isActive: true,
    },
    {
      network: "tnt",
      sku: "GCTNTV1",
      minAmount: 5,
      maxRange: 50,
      isActive: true,
    },
    {
      network: "tnt",
      sku: "GCTNTV2",
      minAmount: 51,
      maxRange: 150,
      isActive: true,
    },
    {
      network: "sun",
      sku: "GCSUNV1",
      minAmount: 5,
      maxRange: 50,
      isActive: true,
    },
    {
      network: "sun",
      sku: "GCSUNV2",
      minAmount: 51,
      maxRange: 150,
      isActive: true,
    },
  ],
};

const prod = {
  variable_sku: [
    {
      network: "globe",
      sku: "GCGLOBEV1",
      minAmount: 5,
      maxRange: 50,
      isActive: true,
    },
    {
      network: "globe",
      sku: "GCGLOBEV2",
      minAmount: 51,
      maxRange: 150,
      isActive: true,
    },
    {
      network: "tm",
      sku: "GCTMV1",
      minAmount: 5,
      maxRange: 50,
      isActive: true,
    },
    {
      network: "tm",
      sku: "GCTMV2",
      minAmount: 51,
      maxRange: 150,
      isActive: true,
    },
    {
      network: "smart",
      sku: "GCSMARTV1",
      minAmount: 5,
      maxRange: 50,
      isActive: true,
    },
    {
      network: "smart",
      sku: "GCSMARTV2",
      minAmount: 51,
      maxRange: 150,
      isActive: true,
    },
    {
      network: "tnt",
      sku: "GCTNTV1",
      minAmount: 5,
      maxRange: 50,
      isActive: true,
    },
    {
      network: "tnt",
      sku: "GCTNTV2",
      minAmount: 51,
      maxRange: 150,
      isActive: true,
    },
    {
      network: "sun",
      sku: "GCSUNV1",
      minAmount: 5,
      maxRange: 50,
      isActive: true,
    },
    {
      network: "sun",
      sku: "GCSUNV2",
      minAmount: 51,
      maxRange: 150,
      isActive: true,
    },
  ],
};

const { REACT_APP_STAGE } = process.env;
let sku = staging;

if (REACT_APP_STAGE === "production") sku = prod;
else if (REACT_APP_STAGE === "staging") sku = staging;

export default sku;
