import styled from "styled-components";

export const CustomTitle = styled.p`
  margin-bottom: 10px;
  font-size: 15px;
  margin-top: 15px;
  display: flex;
  justify-content: flex-start;
  width: 100%;
`;

export const Red = styled.div`
  color: red;
`;
