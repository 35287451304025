import styled from "styled-components";

export const ModalContent = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: 1px solid white;
  background-color: white;
  padding: 40px;
  width: 50vw;
  @media (min-width: 1200px), {
    width: 400px;
  }
`;
