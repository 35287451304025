import styled from "styled-components";

export const BackButtonContainer = styled.div`
  color: white;
  font-size: 15px;
  display: flex;
  align-items: center;
  cursor: pointer;
`;

export const Back = styled.div`
  cursor: pointer;
  margin-left: 10px;
`;
