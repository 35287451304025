import styled from "styled-components";

export const Container = styled.div`
  width: 300px;
  margin-top: 20px;
  margin-bottom: 10%;
  background-color: white;
  height: 150px;
  border-radius: 20px;
  background-size: cover;
  background-image: url("https://phx-hanepbuhay.s3.amazonaws.com/web/homepage-mobile-banner.jpg");
`;
