import apiClient from "../../axios";
import { toastError } from "../../global/constants";

export async function getToken(payload) {
  try {
    const response = await apiClient.post("/paybills/get-token", payload);
    return response.data.data;
  } catch (e) {
    const message = e.response.data.error.message;
    const code = e.response.data.error.code;
    toastError(`[${code}] ${message}`);
    return { error: message, type: e.response.data.error.sessionType };
  }
}

export async function getBillers(token) {
  try {
    const response = await apiClient.get(`/paybills/categories`, {
      headers: {
        Authorization: token,
      },
    });
    return response.data.data.categories;
  } catch (e) {
    const message = e.response.data.error.message;
    const code = e.response.data.error.code;
    toastError(`[${code}] ${message}`);
    return { error: message };
  }
}

export async function getBillInfo(name, token) {
  try {
    const response = await apiClient.get(`/paybills/biller-info/${name}`, {
      headers: {
        Authorization: token,
      },
    });
    return response.data.data.biller;
  } catch (e) {
    const message = e.response.data.error.message;
    const code = e.response.data.error.code;
    toastError(`[${code}] ${message}`);
    return { error: message };
  }
}

export async function getBillerFee(name, amount, token) {
  try {
    const response = await apiClient.get(
      `/paybills/biller-fee/${name}?amount=${amount}&includeMerchantFees=true`,
      {
        headers: {
          Authorization: token,
        },
      }
    );
    return response.data.data;
  } catch (e) {
    const message = e.response.data.error.message;
    const code = e.response.data.error.code;
    toastError(`[${code}] ${message}`);
    return { error: message };
  }
}

export async function payBills(payload, token) {
  try {
    const response = await apiClient.post(`/paybills/transact`, payload, {
      headers: {
        Authorization: token,
      },
    });
    return {
      ...response.data.data,
      transactionReferenceNo:
        response.data.data.transactionReferenceNo || "N/A",
    };
  } catch (e) {
    const detailObject = e.response.data.error.details;
    let message = "";
    let popup = "";
    let code = "";
    if (detailObject) {
      Object.keys(detailObject).forEach((key) => {
        popup =
          e.response.data.error.billerMessage || e.response.data.error.message;
        code = detailObject[key][0].code;
        message = detailObject[key][0].message;
      });
    } else {
      popup =
        e.response.data.error.billerMessage || e.response.data.error.message;
      message = "There is an error in processing your request";
      code = e.response.data.error.code;
    }
    toastError(`[${code}] ${popup}`, true);
    return { error: message, errorObject: e.response.data.error };
  }
}

export async function inquire(token) {
  try {
    const response = await apiClient.get(`/paybills/inquire`, {
      headers: {
        Authorization: token,
      },
    });
    return response.data.data;
  } catch (e) {
    const message = e.response.data.error.message;
    const code = e.response.data.error.code;
    toastError(`[${code}] ${message}`);
  }
}

export async function getTransactions(token, limit) {
  try {
    const response = await apiClient.get(
      `/paybills/transactions?limit=${limit}`,
      {
        headers: {
          Authorization: token,
        },
      }
    );
    return response.data.data.transactions;
  } catch (e) {
    const message = e.response.data.error.message;
    const code = e.response.data.error.code;
    toastError(`[${code}] ${message}`);
  }
}
