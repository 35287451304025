import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import {
  Content,
  ProceedButton,
  ButtonContent,
  FieldsContent,
  Wait,
  Loading,
  Red,
} from "./styles";
import { push } from "connected-react-router";
import { CircularProgress } from "@mui/material/";
import InputDetail from "../../components/common/InputDetail";
import SelectDetail from "../../components/common/SelectDetail";
import { useLocation } from "react-router";
import queryString from "query-string";
import FooterContainer from "../../components/common/FooterContainer";
import TitleHeader from "../../components/common/TitleHeader";
import { checkValidMobile } from "../../global/helpers";

const mapStateToProps = ({ dispatch, cignalReducers, globalReducer }) => ({
  dispatch,
  cignalStore: cignalReducers,
  globalStore: globalReducer,
});

const CignalPrepaidScreen = ({ dispatch, cignalStore, globalStore }) => {
  const location = useLocation();
  const params = queryString.parse(location.search);

  const categories = [
    "Cignal Prepaid",
    "SatLite Prepaid",
    "Cignal Lite",
    "Pay-Per-View (PPV)",
  ];

  const categoryCodes = [
    { title: "Cignal Prepaid", code: "PREPAID" },
    { title: "SatLite Prepaid", code: "SATLITE" },
    { title: "Cignal Lite", code: "LITE" },
    { title: "Pay-Per-View (PPV)", code: "PPV" },
  ];

  const [categoryCode, setCategoryCode] = useState(cignalStore.category);
  const [prepaidDetails, setPrepaidDetails] = useState({
    category: cignalStore.payDetails.category,
    product: cignalStore.payDetails.product,
    accountType: cignalStore.payDetails.accountType,
    accountNumber: cignalStore.payDetails.accountNumber,
  });
  const [error, setError] = useState(false);

  const onProceed = () => {
    if (
      prepaidDetails.accountType === "Mobile Number" &&
      !checkValidMobile(prepaidDetails.accountNumber)
    ) {
      setError("Invalid mobile number");
    } else if (
      prepaidDetails.accountType === "Cignal Account" &&
      checkValidMobile(prepaidDetails.accountNumber)
    ) {
      setError("Invalid cignal account");
    } else {
      dispatch({
        type: "cignal/SET_PAY_DETAILS",
        payload: {
          ...prepaidDetails,
          amount: returnAmount(cignalStore.products, prepaidDetails.product),
          sku: returnSku(cignalStore.products, prepaidDetails.product),
          userId: cignalStore.sessionDetails.userId,
          sessionId: cignalStore.sessionDetails.sessionId,
        },
      });
      dispatch(push("/summary"));
    }
  };

  const chooseCategory = (category) => {
    const code = categoryCodes.filter((categoryObj) => {
      return categoryObj.title === category;
    });
    setCategoryCode(code[0].code);
    dispatch({
      type: "cignal/SET_CATEGORY",
      payload: code[0].code,
    });
  };

  const returnFilteredProducts = (products, subcategory) => {
    const filtered = products.filter((products) => {
      return (
        products.subcategory &&
        products.subcategory.toUpperCase() === subcategory.toUpperCase()
      );
    });

    return filtered;
  };

  const returnAmount = (products, name) => {
    const product = products.filter((product) => {
      return name === product.name;
    });
    return product[0].basePrice;
  };

  const returnSku = (products, name) => {
    const product = products.filter((product) => {
      return name === product.name;
    });
    return product[0].sku;
  };

  useEffect(() => {
    dispatch({
      type: "global/CURRENT_ENDPOINT",
      payload: "CIGNAL PREPAID",
    });
    dispatch({
      type: "cignal/REQUEST_TOKEN",
      payload: {
        userId: params.userId,
        sessionId: params.sessionId,
      },
    });
  }, [dispatch, params.userId, params.sessionId]);

  useEffect(() => {
    if (cignalStore.sessionDetails.sessionToken) {
      dispatch({
        type: "cignal/REQUEST_PRODUCTS",
        payload: {
          token: cignalStore.sessionDetails.sessionToken,
          category: "cignal",
        },
      });
    }
  }, [cignalStore.sessionDetails.sessionToken, dispatch, location]);
  return (
    <>
      <Content>
        <div>
          <TitleHeader
            title={globalStore.pageTitle}
            type={
              globalStore.sessionDetails &&
              globalStore.sessionDetails.sessionType
            }
          />
          <FieldsContent>
            <SelectDetail
              title={"Select Category"}
              subtitle={"Choose Category"}
              options={categories}
              value={prepaidDetails.category}
              onChange={(e) => {
                setPrepaidDetails({
                  ...prepaidDetails,
                  category: e.target.value,
                  product: null,
                  accountType: null,
                  accountNumber: null,
                });
                chooseCategory(e.target.value);
              }}
            />

            {(!!prepaidDetails.category)
              && (cignalStore.products  &&
              returnFilteredProducts(cignalStore.products, categoryCode)
                .length > 0) && (
                <SelectDetail
                  title={"Product"}
                  subtitle={"Choose Product"}
                  options={returnFilteredProducts(
                    cignalStore.products,
                    categoryCode
                  )}
                  menuTitle="name"
                  menuSubtitle="description"
                  value={prepaidDetails.product}
                  optionSubtitle
                  onChange={(e) => {
                    setPrepaidDetails({
                      ...prepaidDetails,
                      product: e.target.value,
                      accountType: null,
                      accountNumber: null,
                    });
                  }}
                />
              )}

            {prepaidDetails.product && (
              <SelectDetail
                title={"Account Number type"}
                subtitle={"Choose Type"}
                options={["Cignal Account", "Mobile Number"]}
                menuTitle="name"
                menuSubtitle="description"
                value={prepaidDetails.accountType}
                onChange={(e) => {
                  setPrepaidDetails({
                    ...prepaidDetails,
                    accountType: e.target.value,
                    accountNumber: '',
                  });
                }}
              />
            )}

            {prepaidDetails.accountType && (
              <InputDetail
                title={"Account Number"}
                subtitle={"Enter Account Number"}
                onChange={(e) => {
                  setPrepaidDetails({
                    ...prepaidDetails,
                    accountNumber: e.target.value,
                  });
                }}
                value={prepaidDetails.accountNumber}
                type="number"
              />
            )}
            {cignalStore.loading && (
              <Loading>
                <CircularProgress />
                <Wait>Please wait...</Wait>
              </Loading>
            )}
            {error && <Red> {error}</Red>}
          </FieldsContent>

          <ButtonContent>
            <ProceedButton
              variant="contained"
              onClick={() => {
                onProceed();
              }}
              disabled={
                !prepaidDetails.category ||
                !prepaidDetails.product ||
                !prepaidDetails.accountType ||
                !prepaidDetails.accountNumber
              }
            >
              Proceed
            </ProceedButton>
          </ButtonContent>
        </div>
        <FooterContainer />
      </Content>
    </>
  );
};

export default connect(mapStateToProps)(CignalPrepaidScreen);
