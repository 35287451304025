import styled from "styled-components";
import { TextField } from "@mui/material/";

export const CustomInput = styled(TextField)`
  margin-bottom: 10px;
  width: 100%;
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
  }
`;
export const CustomTitle = styled.p`
  margin-bottom: 5px;
  font-size: 16px;
  margin-top: 15px;
  display: flex;
  justify-content: flex-start;
  width: 100%;
`;

export const Red = styled.div`
  color: red;
`;
