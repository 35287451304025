import apiClient from "../../axios";
import { toastError } from "../../global/constants";

export async function getToken(payload) {
  try {
    const response = await apiClient.post("/gaming/get-token", payload);
    return response.data.data;
  } catch (e) {
    const message = e.response.data.error.message;
    const code = e.response.data.error.code;
    toastError(`[${code}] ${message}`);
    return { error: message, type: e.response.data.error.sessionType };
  }
}

export async function getProducts(token) {
  try {
    const response = await apiClient.get(`/gaming/products?category=GAMES`, {
      headers: {
        Authorization: token,
      },
    });
    return response.data.data.products;
  } catch (e) {
    const message = e.response.data.error.message;
    const code = e.response.data.error.code;
    toastError(`[${code}] ${message}`);
  }
}

export async function buyGame(payload, token) {
  try {
    const response = await apiClient.post(`/gaming/transact`, payload, {
      headers: {
        Authorization: token,
      },
    });
    return response.data.data;
  } catch (e) {
    const message = e.response.data.error.message;
    const code = e.response.data.error.code;
    toastError(`[${code}] ${message}`);
    return { error: message };
  }
}

export async function getTransactions(token, limit) {
  try {
    const response = await apiClient.get(
      `/gaming/transactions?limit=${limit}&categories=GAMES`,
      {
        headers: {
          Authorization: token,
        },
      }
    );
    return response.data.data;
  } catch (e) {
    const message = e.response.data.error.message;
    const code = e.response.data.error.code;
    toastError(`[${code}] ${message}`);
  }
}
