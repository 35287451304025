import styled from "styled-components";

export const Content = styled.div`
  display: flex;
  align-items: center;
  height: 90vh;
  background-color: white;
  flex-direction: column;
`;

export const Title = styled.div`
  padding-top: 20%;
  font-size: 100px;
  color: #e74c3c;
  letter-spacing: 5px;
`;

export const Subtitle = styled.div`
  font-size: 25px;
  padding-top: 2%;
  letter-spacing: 3px;
  color: black;
`;
