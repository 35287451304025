import React from "react";
import PaymentDetail from "../../common/PaymentDetail";

const PlaySummary = ({ detail }) => {
  return (
    <>
      <PaymentDetail title="Product:" value={detail.product} />
      <PaymentDetail
        title="Cignal Account/Mobile Number:"
        value={detail["Cignal Account/Mobile Number"]}
      />{" "}
    </>
  );
};

export default PlaySummary;
