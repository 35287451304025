import styled from "styled-components";
import { Button } from "@mui/material/";
import { styled as muiStyled } from "@mui/material/styles/";
import { primaryColor, secondaryColor } from "../../../global/constants";

export const BalanceContainer = styled.div`
  margin-top: 5%;
  width: 100%;
  background-color: white;
  height: 130px;
  border-radius: 20px;
`;

export const AvailableBalanceContainer = styled.div`
  background-color: ${primaryColor};
  height: 30%;
  width: 100%;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

export const Container = styled.div`
  height: 70%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const TopUp = muiStyled(Button)({
  color: "white",
  textAlign: "center",
  textTransform: "none",
  fontSize: 16,
  marginTop: "10px",
  padding: "6px 12px",
  height: "35px",
  lineHeight: 1.5,
  backgroundColor: primaryColor,
  border: "none",
  width: "85%",
  "&:hover": {
    backgroundColor: secondaryColor,
    boxShadow: "none",
  },
  "&:active": {
    boxShadow: "none",
    backgroundColor: secondaryColor,
  },
});

export const Balance = styled.div`
  font-size: 36px;
  height: 30px;
  font-weight: bold;
  line-height: 0.75;
`;
