import styled from "styled-components";

export const Background = styled.div`
  height: 89vh;
  background-size: cover;
  display: flex;
  overflow-y: auto;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`;

export const Container = styled.div`
  padding: 5%;
  width: 70%;
`;

export const WelcomeContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: white;
  font-size: 20px;
`;

export const User = styled.p`
  font-size: 30px;
  margin: 0px;
  padding: 0px;
`;

export const CircleButton = styled.div`
  background-color: white;
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  cursor: pointer;
`;
export const Img = styled.div`
  width: 40px;
  height: 40px;
  background-repeat: no-repeat;
  background-color: white;
  background-size: contain;
  background-image: url(${(props) => props.img});
`;

export const DashboardContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 25px;
  width: 100%;
`;

export const GridItem = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 15px;
  width: 33%;
`;

export const Service = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  justify-content: space-between;
  align-items: center;
`;

export const ServiceName = styled.div`
  margin-top: 10px;
  height: 20px;
`;
