export const actions = {
  REQUEST_BUY_EMONEY: "emoney/REQUEST_BUY_EMONEY",
  RECEIVE_BUY_EMONEY: "emoney/RECEIVE_BUY_EMONEY",
  SET_BUY_DETAILS: "emoney/SET_BUY_DETAILS",
  REQUEST_TRANSACTIONS: "emoney/REQUEST_TRANSACTIONS",
  RECEIVE_TRANSACTIONS: "emoney/RECEIVE_TRANSACTIONS",
  REQUEST_TOKEN: "emoney/REQUEST_TOKEN",
  RECEIVE_TOKEN: "emoney/RECEIVE_TOKEN",
  REQUEST_PRODUCTS: "emoney/REQUEST_PRODUCTS",
  RECEIVE_PRODUCTS: "emoney/RECEIVE_PRODUCTS",
  SET_CATEGORY: "emoney/SET_CATEGORY",
  SET_LOADING: "emoney/SET_LOADING",
};

const initialState = {
  // TODO: change buyDetails to buyDetails
  buyDetails: {},
  sessionDetails: {},
  products: [],
  transactions: [],
  category: "",
  loading: true,
};

export default function emoneyReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_BUY_DETAILS:
      return { ...state, buyDetails: action.payload };
    case actions.RECEIVE_BUY_EMONEY:
      return { ...state, buyDetails: action.payload };
    case actions.RECEIVE_TRANSACTIONS:
      return { ...state, transactions: action.payload };
    case actions.RECEIVE_TOKEN:
      return { ...state, sessionDetails: action.payload };
    case actions.RECEIVE_PRODUCTS:
      return { ...state, products: action.payload };
    case actions.SET_CATEGORY:
      return { ...state, category: action.payload };
    case actions.SET_LOADING:
      return { ...state, loading: action.payload };
    default:
      return state;
  }
}
