export const actions = {
  SET_LOAD_DETAILS: "load/SET_LOAD_DETAILS",
  REQUEST_BUY_LOAD: "load/REQUEST_BUY_LOAD",
  RECEIVE_BUY_LOAD: "load/RECEIVE_BUY_LOAD",
  REQUEST_PRODUCTS: "load/REQUEST_PRODUCTS",
  RECEIVE_PRODUCTS: "load/RECEIVE_PRODUCTS",
  REQUEST_TRANSACTIONS: "load/REQUEST_TRANSACTIONS",
  RECEIVE_TRANSACTIONS: "load/RECEIVE_TRANSACTIONS",
  REQUEST_TOKEN: "load/REQUEST_TOKEN",
  RECEIVE_TOKEN: "load/RECEIVE_TOKEN",
  SET_CATEGORY: "load/SET_CATEGORY",
  SET_LOADING: "load/SET_LOADING",
};

const initialState = {
  loadDetails: {},
  sessionDetails: {},
  products: [],
  transactions: [],
  category: "",
  loading: true,
};

export default function loadReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_LOAD_DETAILS:
      return { ...state, loadDetails: action.payload };
    case actions.RECEIVE_BUY_LOAD:
      return { ...state, loadDetails: action.payload };
    case actions.RECEIVE_PRODUCTS:
      return { ...state, products: action.payload };
    case actions.RECEIVE_TRANSACTIONS:
      return { ...state, transactions: action.payload };
    case actions.RECEIVE_TOKEN:
      return { ...state, sessionDetails: action.payload };
    case actions.SET_CATEGORY:
      return { ...state, category: action.payload };
    case actions.SET_LOADING:
      return { ...state, loading: action.payload };
    default:
      return state;
  }
}
