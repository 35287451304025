import { toast } from "react-toastify";
import theme from "./theme";

export const toastError = (msg, disableAutoClose) => {
  toast.error(msg, {
    position: "top-left",
    autoClose: disableAutoClose ? false : 5000,
    hideProgressBar: true,
    closeOnClick: false,
    pauseOnHover: true,
    draggable: false,
    progress: undefined,
  });
};
export const toastSuccess = (msg) => {
  toast.success(msg, {
    position: "top-left",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: false,
    pauseOnHover: true,
    draggable: false,
    progress: undefined,
  });
};
const url = window.location.hostname;
const splitURL = url.split(".");
export const domainType = !!(splitURL[0] && theme.types[`${splitURL[0]}`])
  ? splitURL[0]
  : "default";

export const primaryColor = theme.types[`${domainType}`].colors ? theme.types[`${domainType}`].colors.primaryColor : theme.colors.primaryColor;
export const secondaryColor = theme.types[`${domainType}`].colors ? theme.types[`${domainType}`].secondaryColor : theme.colors.secondaryColor;

export const reformatDateAndTime = (date) => {
  const newDate = new Date(date);
  const splitDate = newDate.toString().split(" ");
  const splitTime = splitDate[4].split(":");
  const pm = parseInt(splitTime[0]) > 12;
  const hr = pm ? parseInt(splitTime[0]) - 12 : splitTime[0];
  const time = `${hr}:${splitTime[1]}:${splitTime[2]}`;
  return {
    date: `${splitDate[1]} ${splitDate[2]}, ${splitDate[3]}`,
    time: `${time} ${pm ? "PM" : "AM"}`,
  };
};
