import React from "react";
import { CustomTitle, Space, Value, LineBreak } from "./styles";

const PaymentDetail = ({ title, value, subtitle }) => {
  return (
    <>
      {title ? <CustomTitle>{title}</CustomTitle> : <Space></Space>}
      <Value>{value}</Value>
      <LineBreak />
    </>
  );
};

export default PaymentDetail;
