import axios from "axios";
import configs from "./configs";

const apiClient = axios.create({
  baseURL: configs.config.apiRoot,
  headers: {
    "Content-Type": "application/json",
  },
});

export default apiClient;
