import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import {
  ProceedBox,
  ModalButtonGreen,
  CountDown,
  Timer,
  Content,
  Link,
  Output,
  Details,
  DetailItem,
} from "./styles";
import ModalDetail from "../../common/ModalDetail";
import { formatPrice } from "../../../global/helpers";
const mapStateToProps = ({ dispatch, globalReducer, emoneyReducer }) => ({
  dispatch,
  globalStore: globalReducer,
  emoneyStore: emoneyReducer,
});

const InquireCountdown = ({
  startCountDown,
  openCountdownModal,
  startCountDownChecker,
  inquire,
  billsFlag,
  setConfirm,
  handleClose,
  emoneyStore,
  globalStore,
  dispatch,
  seconds,
}) => {
  const [time, setTime] = useState(seconds);
  const [inquireResponse, setInquireResponse] = useState(
    globalStore.inquireResponse
  );
  const countDown = () => {
    setTime(time - 1);
  };

  useEffect(() => {
    function run() {
      setInquireResponse(globalStore.inquireResponse);
    }
    run();
  }, [globalStore.inquireResponse]);

  useEffect(() => {
    //   Keep countingDown
    function run() {
      if (time > 0 && startCountDown) {
        setTimeout(countDown, 1000);
      } else if (time === 0) {
        inquire();
      }
    }
    run();
  }, [time]);

  useEffect(() => {
    //   Start countDown
    if (startCountDown) {
      setTimeout(countDown, 1000);
    }
  }, [startCountDownChecker]);

  const CompletedOutput = ({ response }) => (
    <Output>
      Successfully processed transaction of {response.productSku} to
      account/mobile number {response.recipientMobile} with status{" "}
      {response.status}
      <Details>
        <DetailItem>
          Reference ID:
          {response.referenceId}
        </DetailItem>
        <DetailItem>
          Total amount:
          {formatPrice(response.amount)}
        </DetailItem>
        <DetailItem>
          Initial balance:
          {formatPrice(response.initialBalance)}
        </DetailItem>
        <DetailItem>
          Current balance:
          {formatPrice(response.endBalance)}
        </DetailItem>
      </Details>
    </Output>
  );

  const FailedOutput = ({ response }) => (
    <Output>
      Sorry, error on processing transaction of {response.productSku} to
      account/mobile number {response.recipientMobile} with status{" "}
      {response.status}
      <Details>
        <DetailItem>Error received:{response.errorMessage}</DetailItem>
      </Details>
      <DetailItem>
        Reference ID:
        {response.referenceId}
      </DetailItem>
    </Output>
  );

  const EnqueuedOutput = () =>
    globalStore.pageTitle === "E-Wallet Loading" &&
    emoneyStore.buyDetails["E-Money"] === "GCash" &&
    emoneyStore.buyDetails.service === "Cash out (withdraw)" ? (
      <Output>
        Transaction is still being processed. Click to inquire about the status
        of your order.
      </Output>
    ) : (
      <Output>
        Oops! There is a timeout while trying to process your order . Click to
        inquire about the status of your order.
      </Output>
    );

  const switchOutput = (response) => {
    const { status } = response;
    switch (status) {
      case "COMPLETED":
        return <CompletedOutput response={response} />;
      case "FAILED":
        return <FailedOutput response={response} />;
      case "ENQUEUED":
      case "PENDING":
      case "PAID":
        return <EnqueuedOutput response={response} />;
      default:
        return {};
    }
  };
  return (
    <ModalDetail
      open={openCountdownModal}
      handleClose={handleClose}
      content={
        billsFlag ? null : (
          <>
            <Content>
              {inquireResponse && time < 1 ? (
                switchOutput(inquireResponse)
              ) : (
                <Output>
                  Please wait while we are fetching the final result of this
                  enqueued transaction…
                </Output>
              )}
            </Content>
            You can always view here the
            <Link
              onClick={() => {
                dispatch(push("/transactionsList"));
              }}
            >
              list of your transactions
            </Link>
          </>
        )
      }
      textContent={
        billsFlag
          ? "Oops! Your request timed out. Check the status of transaction in 10 seconds."
          : null
      }
      extra={() => {
        return (
          <>
            {time > 0 && (
              <CountDown>
                <Timer time={time}>{time}</Timer>
              </CountDown>
            )}
          </>
        );
      }}
      buttons={() => {
        return (
          <ProceedBox>
            {billsFlag && (
              <ModalButtonGreen
                disabled={time > 0}
                variant="contained"
                onClick={() => {
                  setConfirm(true);
                  inquire();
                }}
              >
                Proceed
              </ModalButtonGreen>
            )}
            {inquireResponse &&
              (inquireResponse.status === "ENQUEUED" ||
                inquireResponse.status === "PENDING" ||
                inquireResponse.status === "PAID") && (
                <ModalButtonGreen
                  disabled={time > 0}
                  variant="contained"
                  onClick={() => {
                    setConfirm(true);
                    setTime(10);
                  }}
                >
                  Check Status
                </ModalButtonGreen>
              )}
            {inquireResponse &&
              (inquireResponse.status === "FAILED" ||
                inquireResponse.status === "COMPLETED") && (
                <ModalButtonGreen
                  variant="contained"
                  onClick={() => {
                    if (
                      globalStore.sessionDetails &&
                      globalStore.sessionDetails.sessionType === "WEB"
                    ) {
                      dispatch(push("/dashboard"));
                    } else if (
                      globalStore.sessionDetails &&
                      globalStore.sessionDetails.sessionType === "BOT"
                    ) {
                      dispatch(push("/result"));
                    }
                    setInquireResponse(null);
                  }}
                >
                  Ok
                </ModalButtonGreen>
              )}
          </ProceedBox>
        );
      }}
    />
  );
};

export default connect(mapStateToProps)(InquireCountdown);
