import styled from "styled-components";
import { Button } from "@mui/material/";
import { styled as muiStyled } from "@mui/material/styles/";
import { primaryColor, secondaryColor } from "../../global/constants";

export const Content = styled.div`
  display: flex;
  color: white;
  flex-direction: column;
  justify-content: space-between;
  height: 90vh;
  overflow-y: auto;
`;
export const FieldsContent = styled.div`
  padding: 5% 10% 0 10%;
  display: flex;
  flex-direction: column;
`;

export const ButtonContent = styled.div`
  padding: 5% 10% 0 10%;
`;

export const ProceedButton = muiStyled(Button)({
  textAlign: "center",
  textTransform: "none",
  fontSize: 16,
  marginTop: "10px",
  padding: "6px 12px",
  height: "50px",
  lineHeight: 1.5,
  backgroundColor: primaryColor,
  border: "none",
  width: "100%",
  "&:hover": {
    backgroundColor: secondaryColor,
    boxShadow: "none",
  },
  "&:active": {
    boxShadow: "none",
    backgroundColor: secondaryColor,
  },
});

export const Loading = styled.div`
  padding-top: 20px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
