import styled from "styled-components";
import { primaryColor } from "../../global/constants";
import { styled as muiStyled } from "@mui/material/styles/";
import { Button } from "@mui/material/";

export const Background = styled.div`
  height: 89vh;
  background-size: cover;
  display: flex;
  overflow-y: auto;
  flex-direction: column;
  justify-content: space-between;
`;

export const Container = styled.div``;

export const FooterContainer = styled.div`
  margin-top: 30px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

export const SelectContainer = styled.div`
  padding-left: 10%;
  padding-right: 10%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const TransactionContainer = styled.div`
  margin-top: 20px;
  width: 100%;
`;

export const Transaction = styled.div`
  width: 100%;
  margin-top: 10px;
  display: flex;
  cursor: pointer;
`;

export const GreenStrip = styled.div`
  background-color: ${(props) =>
    props.status === "COMPLETED" || props.status === "DELIVERED"
      ? primaryColor
      : props.status === "PENDING" ||
        props.status === "ENQUEUED" ||
        props.status === "FOR_DELIVERY"
        ? "gray"
        : "red"};
  width: 20px;
`;

export const Content = styled.div`
  padding: 10px 30px 10px 30px;
  background-color: white;
  display: flex;
  justify-content: space-between;
  width: calc(100% - 20px);
`;

export const Col = styled.div`
  display: flex;
  flex-direction: column;
`;

export const BottomDetail = styled.div`
  margin-top: 15px;
`;

export const ModalText = styled.div`
  color: #1c75bc;
`;

export const ButtonBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
`;

export const ModalButtonRed = muiStyled(Button)({
  textAlign: "center",
  textTransform: "none",
  fontSize: 16,
  padding: "6px 12px",
  height: "40px",
  border: "none",
  width: "40%",
});

export const Details = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const DetailItem = styled.div`
  margin-top: 10px;
  display: ${(props) => (props.newLine ? "block" : "flex")};
  justify-content: space-between;
  word-break: break-all;
`;

export const Error = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  font-size: 20px;
`;

export const LeftDetail = styled.div`
  min-width: 140px;
  margin-right: 20px;
`;

export const Loading = styled.div`
  margin-top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const Wait = styled.div`
  margin-top: 5px;
  text-align: center;
`;

export const Empty = styled.div`
  text-align: center;
  color: white;
`