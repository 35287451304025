import React from "react";
import { connect } from "react-redux";
import { Content, SectionTitle, FAQ, CenteredFAQ } from "./styles";
import TitleHeader from "../../components/common/TitleHeader";
import FooterContainer from "../../components/common/FooterContainer";
import theme from "../../global/theme";
import { domainType } from "../../global/constants";

const FAQScreen = () => {
  const hasFAQDetail = () => {
    if (theme.types) {
      const typesDetail = theme.types[`${domainType}`];
      return typesDetail?.faqDetails || false;
    } else {
      return false;
    }
  };

  return (
    <>
      <Content>
        <div>
          <TitleHeader title={"FAQs"} type={"WEB"} />
          {hasFAQDetail() ? (
            <>
              {hasFAQDetail().map((detail) => (
                <div>
                  {detail.type === "title" ? (
                    <SectionTitle>{detail.text}</SectionTitle>
                  ) : detail.type === "centeredfaq" ? (
                    <CenteredFAQ>{detail.text}</CenteredFAQ>
                  ) : (
                    <FAQ>{detail.text}</FAQ>
                  )}
                </div>
              ))}
            </>
          ) : (
            <>
              <CenteredFAQ>ScratchIt™ licensed by PCSO</CenteredFAQ>
              <CenteredFAQ>
                🎯 Earn more sa pagbenta ng ScratchIt™ lotto cards!
              </CenteredFAQ>
            </>
          )}
        </div>
        <FooterContainer />
      </Content>
    </>
  );
};

export default connect()(FAQScreen);
