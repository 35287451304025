import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import {
  BillersContent,
  ProceedButton,
  FieldContent,
  Loading,
  ButtonContent,
  FieldsContent,
} from "./styles";
import { push } from "connected-react-router";
import InputDetail from "../../components/common/InputDetail";
import SelectDetail from "../../components/common/SelectDetail";
import { useLocation } from "react-router";
import queryString from "query-string";
import FooterContainer from "../../components/common/FooterContainer";
import TitleHeader from "../../components/common/TitleHeader";

const mapStateToProps = ({ dispatch, cignalReducers, globalReducer }) => ({
  dispatch,
  cignalStore: cignalReducers,
  globalStore: globalReducer,
});

const CignalPayScreen = ({ dispatch, cignalStore, globalStore }) => {
  const location = useLocation();
  const params = queryString.parse(location.search);
  const [billDetails, setBillDetails] = useState(cignalStore.payDetails);

  const reformatIntoOptions = (options) => {
    return options.map((option) => {
      return option.key;
    });
  };

  const returnObject = (name, options) => {
    const optionObj = options.filter((option) => {
      return name === option.key;
    });
    return optionObj[0];
  };

  const onProceed = () => {
    dispatch({
      type: "cignal/SET_PAY_DETAILS",
      payload: {
        ...billDetails,
        userId: cignalStore.sessionDetails.userId,
        sessionId: cignalStore.sessionDetails.sessionId,
      },
    });
  };

  const checkRequiredFields = (fields, details) => {
    const required = fields.filter((field) => {
      return field.is_required;
    });
    const filled = required.filter((req) => {
      return details[req.field];
    });
    return required.length !== filled.length;
  };

  useEffect(() => {
    dispatch({
      type: "global/CURRENT_ENDPOINT",
      payload: "PAY POSTPAID",
    });
    dispatch({
      type: "cignal/REQUEST_TOKEN",
      payload: {
        userId: params.userId,
        sessionId: params.sessionId,
      },
    });
  }, [dispatch, params.userId, params.sessionId]);

  useEffect(() => {
    if (cignalStore.sessionDetails.sessionToken) {
      dispatch({
        type: "cignal/REQUEST_BILL_INFO",
        payload: {
          token: cignalStore.sessionDetails.sessionToken,
        },
      });
    }
  }, [cignalStore.sessionDetails.sessionToken, dispatch]);

  return (
    <>
      <BillersContent>
        <div>
          <TitleHeader
            title={globalStore.pageTitle}
            type={
              globalStore.sessionDetails &&
              globalStore.sessionDetails.sessionType
            }
          />
          <FieldsContent>
            <FieldContent>
              {cignalStore.loading ? (
                <Loading>Loading ...</Loading>
              ) : (
                cignalStore.billInfo.fields &&
                cignalStore.billInfo.fields.map((field) => {
                  const regExp = /\(([^)]+)\)/;
                  const subtitle = regExp.exec(field.label);
                  const title = field.label.split("(")[0];

                  return field.type === "Dropdown" ? (
                    <div>
                      <SelectDetail
                        title={subtitle ? title : field.label}
                        subtitle={subtitle ? subtitle : `Enter ${field.label}`}
                        options={reformatIntoOptions(field.options)}
                        value={billDetails[`${field.field}`]}
                        required={field.is_required}
                        onChange={(e) => {
                          setBillDetails({
                            ...billDetails,
                            [`${field.field}`]: returnObject(
                              e.target.value,
                              field.options
                            ).value,
                          });
                        }}
                      />
                    </div>
                  ) : (
                    <InputDetail
                      title={subtitle ? title : field.label}
                      subtitle={subtitle ? subtitle : `Enter ${field.label}`}
                      value={billDetails[`${field.field}`]}
                      required={field.is_required}
                      onChange={(e) => {
                        setBillDetails({
                          ...billDetails,
                          [`${field.field}`]:
                            field.type === "Number"
                              ? parseInt(e.target.value)
                              : e.target.value,
                        });
                      }}
                      type={field.type}
                    />
                  );
                })
              )}
            </FieldContent>
          </FieldsContent>

          <ButtonContent>
            <ProceedButton
              variant="contained"
              onClick={() => {
                dispatch(push("/summary"));
                onProceed();
              }}
              disabled={
                cignalStore.billInfo.fields
                  ? checkRequiredFields(
                      cignalStore.billInfo.fields,
                      billDetails
                    )
                  : true
              }
            >
              Proceed
            </ProceedButton>
          </ButtonContent>
        </div>
        <FooterContainer />
      </BillersContent>
    </>
  );
};

export default connect(mapStateToProps)(CignalPayScreen);
