import React from "react";
import { Grid } from "@mui/material/";
import { GridBox } from "./styles";
import Item from "../Item";

const ItemGrid = ({ list, setter, value, multiSelect, noDescription }) => {
  const returnCartItem = (name) => {
    if (multiSelect) {
      return value.filter((v) => {
        return v.name === name;
      });
    }
  };

  const deletedFromCart = (name) => {
    if (multiSelect) {
      return value.filter((v) => {
        return v.name !== name;
      });
    }
  };

  return (
    <>
      <GridBox>
        <Grid container spacing={2}>
          {list.map((item) => {
            return (
              <Item
                item={item}
                value={value}
                deletedFromCart={deletedFromCart}
                returnCartItem={returnCartItem}
                multiSelect={multiSelect}
                noDescription={noDescription}
                setter={setter}
              />
            );
          })}
        </Grid>
      </GridBox>{" "}
    </>
  );
};

export default ItemGrid;
