import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import {
  Content,
  ProceedButton,
  Loading,
  Wait,
  FieldsContent,
  ButtonContent,
} from "./styles";
import * as constants from "./constants";
import { push } from "connected-react-router";
import { useLocation } from "react-router";
import { CircularProgress } from "@mui/material/";
import queryString from "query-string";
import InputDetail from "../../components/common/InputDetail";
import SelectDetail from "../../components/common/SelectDetail";
import TitleHeader from "../../components/common/TitleHeader";
import FooterContainer from "../../components/common/FooterContainer";

const mapStateToProps = ({ dispatch, wifiReducer, globalReducer }) => ({
  dispatch,
  globalStore: globalReducer,
  wifiStore: wifiReducer,
});

const BuyWifiScreen = ({ dispatch, wifiStore, globalStore }) => {
  const location = useLocation();
  const params = queryString.parse(location.search);
  const [wifiDetails, setWifiDetails] = useState({
    "Account/Mobile Number": wifiStore.wifiDetails["Account/Mobile Number"],
    telco: wifiStore.wifiDetails.telco,
    product: wifiStore.wifiDetails.product,
    amount: wifiStore.wifiDetails.amount,
  });

  const onProceed = () => {
    dispatch({
      type: "wifi/SET_WIFI_DETAILS",
      payload: {
        ...wifiDetails,
        discount: wifiDetails.amount - returnDiscount(wifiDetails.product),
        userId: wifiStore.sessionDetails.userId,
        sessionId: wifiStore.sessionDetails.sessionId,
      },
    });
  };

  const returnDiscount = (sku) => {
    const product = wifiStore.products.filter((product) => {
      return sku === product.sku;
    });
    return product[0] ? product[0].discountedPrice : 0;
  };

  const returnAmount = (name) => {
    const product = wifiStore.products.filter((product) => {
      return name === product.sku;
    });
    return product[0].basePrice;
  };

  const requestProducts = (telco) => {
    setWifiDetails({ ...wifiDetails, telco: telco });

    dispatch({
      type: "wifi/SET_CATEGORY",
      payload: telco,
    });
  };

  const returnFilteredProducts = (mode) => {
    const filtered = wifiStore.products.filter((products) => {
      return products.category.toUpperCase() === mode.toUpperCase();
    });
    return filtered;
  };

  useEffect(() => {
    dispatch({
      type: "global/CURRENT_ENDPOINT",
      payload: "WIFI DATA",
    });
    dispatch({
      type: "wifi/REQUEST_TOKEN",
      payload: {
        userId: params.userId,
        sessionId: params.sessionId,
      },
    });
  }, [dispatch]);

  useEffect(() => {
    if (wifiStore.sessionDetails.sessionToken) {
      dispatch({
        type: "wifi/REQUEST_PRODUCTS",
        payload: {
          token: wifiStore.sessionDetails.sessionToken,
          subcategory: "wifi data",
        },
      });
    }
  }, [wifiStore.sessionDetails.sessionToken]);

  return (
    <>
      <Content>
        <div>
          <TitleHeader
            title={globalStore.pageTitle}
            type={
              globalStore.sessionDetails &&
              globalStore.sessionDetails.sessionType
            }
          />{" "}
          <FieldsContent>
            <InputDetail
              title="Account/Mobile Number"
              subtitle="Enter Mobile Number"
              value={wifiDetails["Account/Mobile Number"]}
              onChange={(e) => {
                setWifiDetails({
                  ...wifiDetails,
                  "Account/Mobile Number": e.target.value,
                });
              }}
              type="number"
            />
            {wifiDetails["Account/Mobile Number"] !== "" &&
              wifiDetails["Account/Mobile Number"] &&
              wifiDetails["Account/Mobile Number"].length === 11 && (
                <SelectDetail
                  title="Select Telco"
                  subtitle="Choose your Telco"
                  options={constants.telco}
                  value={wifiDetails.telco}
                  onChange={(e) => {
                    requestProducts(e.target.value);
                  }}
                />
              )}

            {wifiDetails.telco &&
              wifiStore.products &&
              returnFilteredProducts(wifiDetails.telco).length > 0 && (
                <SelectDetail
                  subtitle={"Choose your wifi product:"}
                  options={returnFilteredProducts(wifiDetails.telco)}
                  value={wifiDetails.product}
                  menuTitle="sku"
                  menuSubtitle="description"
                  onChange={(e) => {
                    setWifiDetails({
                      ...wifiDetails,
                      product: e.target.value,
                      amount: returnAmount(e.target.value),
                    });
                  }}
                  optionSubtitle
                />
              )}
            {wifiStore.loading && (
              <Loading>
                <CircularProgress />
                <Wait>Please wait...</Wait>
              </Loading>
            )}
          </FieldsContent>
          <ButtonContent>
            <ProceedButton
              variant="contained"
              onClick={() => {
                dispatch(push("/summary"));
                onProceed();
              }}
              disabled={
                !wifiDetails.product ||
                !wifiDetails.telco ||
                !(
                  wifiDetails["Account/Mobile Number"] &&
                  wifiDetails["Account/Mobile Number"].length === 11
                )
              }
            >
              Proceed
            </ProceedButton>
          </ButtonContent>
        </div>
        <FooterContainer />
      </Content>
    </>
  );
};

export default connect(mapStateToProps)(BuyWifiScreen);
