import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import {
  Content,
  ProceedButton,
  Red,
  Loading,
  Wait,
  FieldsContent,
  ButtonContent,
} from "./styles";
import * as constants from "./constants";
import sku from "./constants";
import * as messages from "./messages";
import { push } from "connected-react-router";
import InputDetail from "../../components/common/InputDetail";
import SelectDetail from "../../components/common/SelectDetail";
import { CircularProgress } from "@mui/material/";
import { useLocation } from "react-router";
import queryString from "query-string";
import TitleHeader from "../../components/common/TitleHeader";
import FooterContainer from "../../components/common/FooterContainer";
import { checkValidMobile } from "../../global/helpers";

const mapStateToProps = ({ dispatch, loadReducer, globalReducer }) => ({
  dispatch,
  loadStore: loadReducer,
  globalStore: globalReducer,
});

const BuyLoadScreen = ({ dispatch, loadStore, globalStore }) => {
  const location = useLocation();
  const params = queryString.parse(location.search);
  const [loadDetails, setLoadDetails] = useState({
    mobileNumber: loadStore.loadDetails.mobileNumber,
    telco: loadStore.loadDetails.telco,
    products: loadStore.loadDetails.products,
    load: loadStore.loadDetails.load,
    amount: loadStore.loadDetails.amount,
  });
  const [hideInput, setHideInput] = useState(
    loadStore.loadDetails.load
      ? !loadStore.loadDetails.load.includes("Regular Load")
      : true
  );
  const [hideSelect, setHideSelect] = useState(
    loadStore.loadDetails.load
      ? loadStore.loadDetails.load.includes("Regular Load")
      : false
  );
  const [error, setError] = useState(false);

  const onProceed = () => {
    if (
      loadDetails.products === "LOAD" &&
      !hideInput &&
      !returnLoadSku(loadDetails.telco, loadDetails.amount)
    ) {
      setError("Input should be within 5 and 150");
    } else if (!checkValidMobile(loadDetails.mobileNumber)) {
      setError("Invalid mobile number");
    } else {
      const payloadSku =
        loadDetails.products === "LOAD" && hideSelect
          ? returnLoadSku(loadDetails.telco, loadDetails.amount)
          : loadDetails.load;
      dispatch({
        type: "load/SET_LOAD_DETAILS",
        payload: {
          ...loadDetails,
          sku: payloadSku,
          discount:
            returnDiscount(payloadSku) === 0
              ? 0
              : loadDetails.amount - returnDiscount(payloadSku),
          userId: loadStore.sessionDetails.userId,
          sessionId: loadStore.sessionDetails.sessionId,
        },
      });

      dispatch(push("/summary"));
    }
  };

  const requestProducts = (category) => {
    dispatch({
      type: "load/SET_CATEGORY",
      payload: category,
    });
  };

  const returnFilteredProducts = (category, subcategory) => {
    const cat = category === "SUN CELLULAR" ? "SUN" : category;
    const filtered = loadStore.products.filter((products) => {
      return (
        products.category.toUpperCase() === cat.toUpperCase() &&
        (products.subcategory && products.subcategory.toUpperCase()) ===
          subcategory.toUpperCase() &&
        products.priceType !== "VARIABLE"
      );
    });
    return filtered;
  };

  const returnSku = (basePrice, products) => {
    const product = products.filter((product) => {
      return basePrice === product.basePrice;
    });
    return product[0].sku;
  };

  const returnDiscount = (sku) => {
    const product = loadStore.products.filter((product) => {
      return sku === product.sku;
    });
    const discount =
      loadDetails.amount - product[0].discountPercent * loadDetails.amount ||
      product[0].discountedPrice;

    return discount || 0;
  };

  const returnAmount = (sku) => {
    const product = loadStore.products.filter((product) => {
      return sku === product.sku;
    });
    return product[0].basePrice;
  };

  const returnLoadSku = (telco, amount) => {
    const chosenSku = sku.variable_sku.filter((product) => {
      return (
        product.network.toUpperCase() === telco.toUpperCase() &&
        product.minAmount <= amount &&
        product.maxRange >= amount
      );
    });
    return chosenSku.length > 0 ? chosenSku[0].sku : null;
  };

  useEffect(() => {
    function run() {
      if (!loadDetails.amount || loadDetails.amount === "") {
        setHideSelect(false);
      }
      setError(false);
    }
    run();
  }, [loadDetails.amount]);

  useEffect(() => {
    dispatch({
      type: "global/CURRENT_ENDPOINT",
      payload: "BUY LOAD",
    });

    dispatch({
      type: "load/REQUEST_TOKEN",
      payload: {
        userId: params.userId,
        sessionId: params.sessionId,
      },
    });
  }, []);

  useEffect(() => {
    function run() {
      setLoadDetails({
        ...loadDetails,
        products: null,
        load: null,
        amount: null,
      });
    }
    run();
  }, [loadDetails.telco]);

  useEffect(() => {
    function run() {
      setLoadDetails({ ...loadDetails, load: null, amount: null });
    }
    run();
  }, [loadDetails.products]);

  useEffect(() => {
    if (loadStore.sessionDetails.sessionToken) {
      dispatch({
        type: "load/REQUEST_PRODUCTS",
        payload: {
          token: loadStore.sessionDetails.sessionToken,
        },
      });
    }
  }, [loadStore.sessionDetails.sessionToken]);

  return (
    <>
      <Content>
        <div>
          <TitleHeader
            title={globalStore.pageTitle}
            type={
              globalStore.sessionDetails &&
              globalStore.sessionDetails.sessionType
            }
          />{" "}
          <FieldsContent>
            <InputDetail
              title={messages.numberTitle}
              subtitle={messages.numberSubtitle}
              onChange={(e) => {
                setLoadDetails({
                  ...loadDetails,
                  mobileNumber: e.target.value,
                });
              }}
              value={loadDetails.mobileNumber}
              type="number"
            />
            {loadDetails.mobileNumber !== "" &&
              loadDetails.mobileNumber &&
              loadDetails.mobileNumber.length === 11 && (
                <SelectDetail
                  title={messages.telcoTitle}
                  subtitle={messages.telcoSubtitle}
                  options={constants.telco}
                  value={loadDetails.telco}
                  onChange={(e) => {
                    setLoadDetails({ ...loadDetails, telco: e.target.value });
                  }}
                />
              )}

            {loadDetails.telco && (
              <SelectDetail
                title={messages.productTitle}
                subtitle={messages.productSubtitle}
                value={loadDetails.products || null}
                options={constants.products}
                onChange={(e) => {
                  setLoadDetails({ ...loadDetails, products: e.target.value });
                  requestProducts(loadDetails.telco, e.target.value);
                  setHideInput(true);
                }}
              />
            )}

            {loadDetails.products &&
              !hideSelect &&
              loadStore.products &&
              returnFilteredProducts(loadDetails.telco, loadDetails.products)
                .length > 0 &&
              (loadDetails.products === "LOAD" ? (
                <SelectDetail
                  subtitle={messages.loadSubtitle}
                  options={
                    loadDetails.telco !== "DITO" &&
                    loadDetails.telco !== "SUN CELLULAR" &&
                    loadDetails.telco !== "SMART" &&
                    loadDetails.telco !== "TNT"
                      ? [{ basePrice: "ENTER AMOUNT LOAD" }].concat(
                          returnFilteredProducts(
                            loadDetails.telco,
                            loadDetails.products
                          ).sort((a, b) => a.basePrice - b.basePrice)
                        )
                      : returnFilteredProducts(
                          loadDetails.telco,
                          loadDetails.products
                        ).sort((a, b) => a.basePrice - b.basePrice)
                  }
                  value={loadDetails.amount}
                  menuTitle="basePrice"
                  onChange={(e) => {
                    if (e.target.value === "ENTER AMOUNT LOAD") {
                      setHideInput(false);
                      setLoadDetails({
                        ...loadDetails,
                        amount: "ENTER AMOUNT LOAD",
                        load: "",
                      });
                    } else {
                      setLoadDetails({
                        ...loadDetails,
                        load: returnSku(
                          e.target.value,
                          returnFilteredProducts(
                            loadDetails.telco,
                            loadDetails.products
                          )
                        ),
                        amount: e.target.value,
                      });
                      setHideInput(true);
                    }
                  }}
                  optionSubtitle
                />
              ) : (
                <SelectDetail
                  subtitle={messages.loadSubtitle}
                  options={returnFilteredProducts(
                    loadDetails.telco,
                    loadDetails.products
                  )}
                  value={loadDetails.load}
                  menuTitle="sku"
                  menuSubtitle="description"
                  onChange={(e) => {
                    setLoadDetails({
                      ...loadDetails,
                      load: e.target.value,
                      amount: returnAmount(e.target.value),
                    });
                  }}
                  optionSubtitle
                />
              ))}
            {loadDetails.products &&
              loadDetails.products === "LOAD" &&
              !hideInput && (
                <InputDetail
                  title={"Amount"}
                  subtitle={"5-150"}
                  onChange={(e) => {
                    setLoadDetails({
                      ...loadDetails,
                      amount: e.target.value,
                      load: `Regular Load ${e.target.value}`,
                    });
                    setHideSelect(true);
                  }}
                  value={loadDetails.amount}
                  type="number"
                />
              )}
            {loadStore.loading && (
              <Loading>
                <CircularProgress />
                <Wait>Please wait...</Wait>
              </Loading>
            )}

            {error && <Red> {error}</Red>}
          </FieldsContent>
          <ButtonContent>
            <ProceedButton
              variant="contained"
              onClick={() => {
                onProceed();
              }}
              disabled={
                !loadDetails.amount ||
                !loadDetails.products ||
                !loadDetails.telco ||
                !loadDetails.mobileNumber
              }
            >
              Proceed
            </ProceedButton>
          </ButtonContent>
        </div>
        <FooterContainer />
      </Content>
    </>
  );
};

export default connect(mapStateToProps)(BuyLoadScreen);
