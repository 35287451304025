import React from "react";
import PaymentDetail from "../../common/PaymentDetail";
import { CustomTitle, DeliveryTitle, LineBreak } from "./styles";

const EGrocerySummary = ({ detail }) => {
  const ProductDetail = ({ product }) => {
    return (
      <>
        <CustomTitle>
          {product.quantity} {product.name}
        </CustomTitle>
      </>
    );
  };

  return (
    <>
      <DeliveryTitle>
        <> Selected Product/s</>
      </DeliveryTitle>
      {detail.cartDetails &&
        detail.cartDetails.length > 0 &&
        detail.cartDetails.map((product) => {
          return <ProductDetail product={product} />;
        })}
      <LineBreak />
      <PaymentDetail title="First Name:" value={detail.firstName} />
      <PaymentDetail title="Last Name:" value={detail.lastName} />
      <PaymentDetail title="Mobile Number:" value={detail.mobileNumber} />
      <PaymentDetail title="Address:" value={detail.address} />
      <PaymentDetail title="Email:" value={detail.email} />
      <PaymentDetail title="Total:" value={detail.cartTotal.toFixed(2)} />
    </>
  );
};

export default EGrocerySummary;
