export const actions = {
  REQUEST_SIGNUP: "signup/REQUEST_SIGNUP",
  RECEIVE_SIGNUP: "signup/RECEIVE_SIGNUP",
  SET_FORM_DETAILS: "signup/SET_FORM_DETAILS",
  SET_ID_DETAILS: "signup/SET_ID_DETAILS",
};

const initialState = {
  formDetails: {},
  idDetails: {},
};

export default function signUpReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_FORM_DETAILS:
      return { ...state, formDetails: action.payload };
    case actions.SET_ID_DETAILS:
      return { ...state, idDetails: action.payload };
    case actions.RECEIVE_SIGNUP:
      return { ...state, formDetails: action.payload };
    default:
      return state;
  }
}
