import React from "react";
import {
  BalanceContainer,
  AvailableBalanceContainer,
  TopUp,
  Container,
  Balance,
} from "./styles";
import configs from "../../../configs";
import theme from "../../../global/theme";
import { domainType } from "../../../global/constants";

const AvailableBalance = ({ balance, mobileNumber }) => {
  const topupLink = theme.topupLink;
  return (
    <>
      <BalanceContainer>
        <AvailableBalanceContainer>Available Balance</AvailableBalanceContainer>
        <Container>
          <Balance>
            {balance || typeof balance === "number"
              ? new Intl.NumberFormat("en-US", {
                  style: "currency",
                  currency: "PHP",
                }).format(balance)
              : "loading..."}
          </Balance>
          {theme.types[`${domainType}`].id === "ASKANI" ? (
            <div> </div>
          ) : (
            <TopUp
              variant="container"
              onClick={() => {
                if (topupLink) {
                  if (
                    domainType === "gentrade" ||
                    domainType === "stg-gentrade" ||
                    domainType === "localhost"
                  ) {
                    window.open(
                      `${topupLink}/hanepbayad?mobile=${mobileNumber}`,
                      "_blank"
                    );
                  } else {
                    window.open(
                      `${topupLink}?mobile=${mobileNumber}`,
                      "_blank"
                    );
                  }
                } else {
                  window.open(
                    `${configs.config.topUpLink}?mobile=${mobileNumber}`,
                    "_blank"
                  );
                }
              }}
            >
              {domainType === "gentrade" ||
              domainType === "stg-gentrade" ||
              domainType === "localhost"
                ? "HanepBayad"
                : "Top-up"}
            </TopUp>
          )}
        </Container>
      </BalanceContainer>
    </>
  );
};

export default AvailableBalance;
