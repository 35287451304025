import React from "react";
import PaymentDetail from "../../common/PaymentDetail";

const BuyEmoneySummary = ({ detail }) => {
  return (
    <>
      <PaymentDetail title="Select E-Money:" value={detail["E-Money"]} />
      {detail.service && (
        <PaymentDetail title="Select Service:" value={detail.service} />
      )}
      <PaymentDetail title="Account Number:" value={detail.accountNumber} />
      <PaymentDetail title="Product:" value={detail.product} />
      <PaymentDetail title="Amount:" value={"P" + detail.amount} />
    </>
  );
};

export default BuyEmoneySummary;
