export const actions = {
  REQUEST_CREATE_OTP: "login/REQUEST_CREATE_OTP",
  RECEIVE_CREATE_OTP: "login/RECEIVE_CREATE_OTP",
  REQUEST_VERIFY_OTP: "login/REQUEST_VERIFY_OTP",
  RECEIVE_VERIFY_OTP: "login/RECEIVE_VERIFY_OTP",
  REQUEST_RESEND_OTP: "login/REQUEST_RESEND_OTP",
  RECEIVE_RESEND_OTP: "login/RECEIVE_RESEND_OTP",
  REQUEST_LOGOUT_OTP: "login/REQUEST_LOGOUT_OTP",
  SET_LOADING: "login/SET_LOADING",
};

const initialState = {
  mobileNumber: {},
  loading: false,
};

export default function loginReducer(state = initialState, action = {}) {
  switch (action.type) {
    case actions.REQUEST_CREATE_OTP:
      return { ...state, mobileNumber: action.payload };
    case actions.RECEIVE_CREATE_OTP:
      return { ...state, createResponse: action.payload };
    case actions.RECEIVE_VERIFY_OTP:
      return { ...state, verifyResponse: action.payload };
    case actions.SET_LOADING:
      return { ...state, loading: action.payload };
    default:
      return state;
  }
}
