import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Content, ProceedButton, Title, Red } from "./styles";
import { push } from "connected-react-router";
import InputDetail from "../../components/common/InputDetail";
import { useLocation } from "react-router";
import queryString from "query-string";

const mapStateToProps = ({ dispatch, signUpReducer }) => ({
  dispatch,
  signUpStore: signUpReducer,
});

const SignUpScreen = ({ dispatch, signUpStore }) => {
  const location = useLocation();
  const params = queryString.parse(location.search);

  const [formDetails, setFormDetails] = useState(signUpStore.formDetails);
  const [error, setError] = useState(false);

  const checkFields = () => {
    if (
      /[^a-zA-Z ]/.test(formDetails.firstName) ||
      /[^a-zA-Z ]/.test(formDetails.lastName)
    ) {
      return "First/Last name should not have any numbers or special characters";
    } else if (!/\S+@\S+\.\S+/.test(formDetails.email)) {
      return "Input a valid email address.";
    } else if (formDetails.mobileNumber.length !== 11) {
      return "Input a valid mobile number.";
    } else {
      return false;
    }
  };

  const onProceed = () => {
    const checkedFields = checkFields();
    if (checkedFields) {
      setError(checkedFields);
    } else {
      dispatch(push("/sign-up/id"));
      dispatch({
        type: "signup/SET_FORM_DETAILS",
        payload: { ...formDetails, userId: params.userId },
      });
    }
  };

  useEffect(() => {
    function run() {
      setError(false);
    }
    run();
  }, [formDetails]);

  useEffect(() => {
    dispatch({
      type: "global/CURRENT_ENDPOINT",
      payload: "SIGN UP",
    });
  }, [dispatch]);

  return (
    <>
      <Content>
        <div>
          <Title>
            <strong>BE A HANEPBUHAY AGENT AND START EARNING TODAY!</strong>
          </Title>
          <InputDetail
            title="First Name"
            subtitle="Enter First Name"
            value={formDetails.firstName}
            onChange={(e) => {
              setFormDetails({
                ...formDetails,
                firstName: e.target.value,
              });
            }}
            type="string"
            required
          />
          <InputDetail
            title="Last Name"
            subtitle="Enter Last Name"
            value={formDetails.lastName}
            onChange={(e) => {
              setFormDetails({
                ...formDetails,
                lastName: e.target.value,
              });
            }}
            type="string"
            required
          />
          <InputDetail
            title="Mobile Number"
            subtitle="Enter Mobile Number"
            value={formDetails.mobileNumber}
            onChange={(e) => {
              setFormDetails({
                ...formDetails,
                mobileNumber: e.target.value,
              });
            }}
            type="number"
            required
          />
          <InputDetail
            title="Email"
            subtitle="Enter Email"
            value={formDetails.email}
            onChange={(e) => {
              setFormDetails({
                ...formDetails,
                email: e.target.value,
              });
            }}
            type="string"
            required
          />
          <InputDetail
            title="Full Address"
            subtitle="Enter Full Address"
            value={formDetails.fullAddress}
            onChange={(e) => {
              setFormDetails({
                ...formDetails,
                fullAddress: e.target.value,
              });
            }}
            type="string"
            required
          />
          <InputDetail
            title="If referred, enter the referral code"
            subtitle="Enter Referral Code"
            value={formDetails.referralCode}
            onChange={(e) => {
              setFormDetails({
                ...formDetails,
                referralCode: e.target.value,
              });
            }}
            type="string"
          />
        </div>
        <div>
          {error && <Red>{error}</Red>}
          <ProceedButton
            variant="contained"
            onClick={() => {
              onProceed();
            }}
            disabled={
              !formDetails.firstName ||
              !formDetails.lastName ||
              !formDetails.mobileNumber ||
              !formDetails.email ||
              !formDetails.fullAddress
            }
          >
            Next
          </ProceedButton>
        </div>
      </Content>
    </>
  );
};

export default connect(mapStateToProps)(SignUpScreen);
