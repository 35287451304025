import styled from "styled-components";

export const DeliveryTitle = styled.div`
  margin-bottom: 10px;
  color: black;
  margin-top: 15px;
`;

export const CustomTitle = styled.p`
  margin-bottom: 10px;
  margin-top: 15px;
  color: black;
  display: flex;
  font-size: 16px;
  justify-content: flex-start;
  width: 100%;
`;

export const LineBreak = styled.hr`
  opacity: 0.2;
  margin-top: 25px;
`;
