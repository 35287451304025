import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import {
  BillersContent,
  ProceedButton,
  FieldContent,
  UnderCaption,
  ButtonContent,
} from "./styles";
import { push } from "connected-react-router";
import InputDetail from "../../common/InputDetail";
import SelectDetail from "../../common/SelectDetail";
import DateDetail from "../../common/DateDetail";
import FooterContainer from "../../common/FooterContainer";
import TitleHeader from "../../common/TitleHeader";

const mapStateToProps = ({ dispatch, billsReducers, globalReducer }) => ({
  dispatch,
  billsStore: billsReducers,
  globalStore: globalReducer,
});

const BillersFields = ({ dispatch, billsStore, globalStore }) => {
  const [billDetails, setBillDetails] = useState(billsStore.billDetails);
  const captions = {
    "MERALCO CUSTOMER ACCOUNT NUMBER (CAN)":
      "10-digit Meralco Cust. Acct. No. found at the upperleft portion of Meralco bill.",
    Amount:
      "Please make sure to pay the exact amount stated on your bill or higher on or before the due date to avoid inconvenience.",
  };

  const onProceed = () => {
    dispatch({
      type: "bills/SET_BILL_DETAILS",
      payload: reformatBillDetailsDate(),
    });
  };

  useEffect(() => {
    addSingularPaymentMethod();
  }, []);

  const reformatBillDetailsDate = () => {
    const dateFields = billsStore.billInfo.fields.filter((field) => {
      return field.type === "Calendar";
    });
    let tempObject = billDetails;
    Object.keys(billDetails).map((key, index) => {
      dateFields.map((date) => {
        if (date.field === key) {
          tempObject = {
            ...tempObject,
            [key]: formatDate(date.format, billDetails[key]),
          };
        }
      });
    });
    return tempObject;
  };

  const reformatIntoOptions = (options) => {
    return options.map((option) => {
      return option.key;
    });
  };

  const addSingularPaymentMethod = () => {
    const cashFields = billsStore.billInfo.fields.filter((field) => {
      return (
        field.label === "Payment Method" &&
        reformatIntoOptions(field.options).length === 1
      );
    });
    if (cashFields.length > 0) {
      setBillDetails({
        ...billDetails,
        [`${cashFields[0].field}`]: cashFields[0].options[0].value,
      });
    }
  };

  const returnObject = (name, options) => {
    const optionObj = options.filter((option) => {
      return name === option.key;
    });
    return optionObj[0];
  };

  const checkRequiredFields = (fields, details) => {
    const required = fields.filter((field) => {
      return field.is_required;
    });
    const filled = required.filter((req) => {
      return details[req.field];
    });
    return required.length !== filled.length;
  };

  const formatDate = (format, value) => {
    const localString = new Date(value).toLocaleDateString();
    const localStringSplit = localString.split("/");
    var mapObj = {
      YYYY: localStringSplit[2],
      DD: localStringSplit[1].padStart(2, "0"),
      MM: localStringSplit[0].padStart(2, "0"),
    };
    const date = format.replace(/YYYY|DD|MM/gi, function (matched) {
      return mapObj[matched];
    });
    return date;
  };

  useEffect(() => {
    dispatch({
      type: "global/CURRENT_ENDPOINT",
      payload: billsStore.billInfo.name,
    });
  }, [dispatch]);
  return (
    <BillersContent>
      <div>
        <TitleHeader
          title={globalStore.pageTitle}
          type={
            globalStore.sessionDetails && globalStore.sessionDetails.sessionType
          }
        />
        <FieldContent>
          {billsStore.billInfo.fields.map((field) => {
            const regExp = /\(([^)]+)\)/;
            const subtitle = regExp.exec(field.label);
            const title = field.label.split("(")[0];

            return field.type === "Dropdown" ? (
              <div>
                {(field.label !== "Payment Method" ||
                  reformatIntoOptions(field.options).length !== 1) && (
                  <SelectDetail
                    title={subtitle ? title : field.label}
                    subtitle={subtitle ? subtitle : `Enter ${field.label}`}
                    options={reformatIntoOptions(field.options)}
                    required={field.is_required}
                    value={billDetails[`${field.field}`]}
                    onChange={(e) => {
                      setBillDetails({
                        ...billDetails,
                        [`${field.field}`]: returnObject(
                          e.target.value,
                          field.options
                        ).value,
                      });
                    }}
                  />
                )}
              </div>
            ) : field.type === "Calendar" ? (
              <>
                <DateDetail
                  title={subtitle ? title : field.label}
                  required={field.is_required}
                  format={field.format}
                  value={new Date(billDetails[`${field.field}`])}
                  onChange={(newValue) => {
                    setBillDetails({
                      ...billDetails,
                      [`${field.field}`]: newValue,
                    });
                  }}
                />
              </>
            ) : billsStore.billInfo.name === "MERALCO" ? (
              <InputDetail
                title={
                  field.label === "MERALCO CUSTOMER ACCOUNT NUMBER (CAN)"
                    ? field.label
                    : subtitle
                    ? title
                    : field.label
                }
                required={field.is_required}
                subtitle={
                  field.label === "MERALCO CUSTOMER ACCOUNT NUMBER (CAN)"
                    ? "Enter Account Number"
                    : subtitle
                    ? subtitle
                    : `Enter ${field.label}`
                }
                value={billDetails[`${field.field}`]}
                underCaption={() => {
                  return <UnderCaption>{captions[field.label]}</UnderCaption>;
                }}
                onChange={(e) => {
                  setBillDetails({
                    ...billDetails,
                    [`${field.field}`]:
                      field.type === "Number"
                        ? parseFloat(e.target.value)
                        : e.target.value,
                  });
                }}
                type={field.type}
              />
            ) : (
              <InputDetail
                title={subtitle ? title : field.label}
                required={field.is_required}
                subtitle={subtitle ? subtitle : `Enter ${field.label}`}
                value={billDetails[`${field.field}`]}
                onChange={(e) => {
                  setBillDetails({
                    ...billDetails,
                    [`${field.field}`]:
                      field.type === "Number" ? e.target.value : e.target.value,
                  });
                }}
                type={field.type}
              />
            );
          })}
        </FieldContent>

        <ButtonContent>
          <ProceedButton
            variant="contained"
            onClick={() => {
              dispatch(push("/summary"));
              onProceed();
            }}
            disabled={checkRequiredFields(
              billsStore.billInfo.fields,
              billDetails
            )}
          >
            Proceed
          </ProceedButton>
        </ButtonContent>
      </div>
      <FooterContainer />
    </BillersContent>
  );
};

export default connect(mapStateToProps)(BillersFields);
