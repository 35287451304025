import styled from "styled-components";
import { Button } from "@mui/material/";
import { styled as muiStyled } from "@mui/material/styles/";
import { Delete } from "@mui/icons-material/";
import { primaryColor, secondaryColor } from "../../../global/constants";

export const Content = styled.div`
  display: flex;
  color: white;
  flex-direction: column;
  height: 90vh;
  overflow-y: auto;
  justify-content: space-between;
`;

export const CustomTitle = styled.p`
  margin-bottom: 10px;
  margin-top: 15px;
  display: flex;
  font-size: 15px;
  justify-content: flex-start;
  width: 100%;
`;

export const Value = styled.p`
  margin-bottom: 10px;
  font-size: 17px;
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  width: 95%;
  margin-left: 15px;
`;

export const ProceedButton = muiStyled(Button)({
  textAlign: "center",
  marginTop: "10px",
  textTransform: "none",
  fontSize: 16,
  padding: "6px 12px",
  height: "50px",
  lineHeight: 1.5,
  backgroundColor: primaryColor,
  border: "none",
  width: "100%",
  "&:hover": {
    backgroundColor: secondaryColor,
    boxShadow: "none",
  },
  "&:active": {
    boxShadow: "none",
    backgroundColor: secondaryColor,
  },
});

export const LineBreak = styled.hr`
  opacity: 0.2;
  margin-top: 25px;
`;

export const ErrorMessage = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  text-align: center;
  color: #e74c3c;
`;

export const DeleteIcon = styled(Delete)`
  &:hover {
    color: red;
  }
  &:active {
    color: red;
  }
`;

export const ItemContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Price = styled.div`
  padding-left: 20px;
`;

export const FieldsContent = styled.div`
  padding: 5% 10% 0 10%;
  display: flex;
  flex-direction: column;
`;

export const ButtonContent = styled.div`
  padding: 5% 10% 5% 10%;
`;
