export const buttons = (sessionId, userId) => {
  return [
    {
      name: "Prepaid",
      icon: "https://phx-hanepbuhay.s3.amazonaws.com/web/icons/v2/HanepBuhay+-+Icons+2.0_Prepaid.png",
      route: `/buy-load?sessionId=${sessionId}&userId=${userId}`,
    },
    {
      name: "Wifi Data",
      icon: "https://phx-hanepbuhay.s3.amazonaws.com/web/icons/v2/HanepBuhay+-+Icons+2.0_Wifi+Data.png",
      route: `/buy-wifi?sessionId=${sessionId}&userId=${userId}`,
    },
    {
      name: "Cignal TV",
      icon: `https://phx-hanepbuhay.s3.amazonaws.com/web/cignaltv.png`,
      route: `/cignal/prepaid?sessionId=${sessionId}&userId=${userId}`,
    },
    {
      name: "Direct Load Tool",
      icon: `https://phx-hanepbuhay.s3.amazonaws.com/web/cignaltv.png`,
      route: `/cignal/direct-load?sessionId=${sessionId}&userId=${userId}`,
    },
    {
      name: "E-Wallet Loading",
      icon: "https://phx-hanepbuhay.s3.amazonaws.com/web/icons/v2/HanepBuhay+-+Icons+2.0_E-Wallet+Loading.png",
      route: `/buy-emoney?sessionId=${sessionId}&userId=${userId}`,
    },
    {
      name: "Bills Payment",
      icon: "https://phx-hanepbuhay.s3.amazonaws.com/web/icons/v2/HanepBuhay+-+Icons+2.0_Bills+Payment.png",
      route: `/pay-bills?sessionId=${sessionId}&userId=${userId}`,
    },
    // {
    //   name: "Hanep Games",
    //   icon: "https://phx-hanepbuhay.s3.amazonaws.com/web/hanepgames.png",
    //   route: `https://amy.24bet7.com/mapbo/Login.aspx?fbclid=IwAR3oHsdvoKEGAPqX2IlQHeWx47xtJzK0SYhX8hUoRMg_gpGQWgYF4O-bGOM`,
    // },
    {
      name: "Hanep Mart",
      icon: "https://phx-hanepbuhay.s3.amazonaws.com/web/icons/v2/HanepBuhay+-+Icons+2.0_Hanep+Mart.png",
      route: `/hanepmart?sessionId=${sessionId}&userId=${userId}`,
    },
    {
      name: "Hanep Scanner",
      icon: "https://phx-hanepbuhay.s3.amazonaws.com/web/icons/v2/HanepBuhay+-+Icons+2.0_Hanep+Scanner.png",
      route: `https://goforgold.org.ph/ScratchItWebBrowser/`,
    },
    {
      name: "Gaming Epins",
      icon: "https://phx-hanepbuhay.s3.amazonaws.com/web/icons/v2/HanepBuhay+-+Icons+2.0_Gaming+Epins.png",
      route: `/gaming?sessionId=${sessionId}&userId=${userId}`,
    },
    {
      name: "Transactions",
      icon: "https://phx-hanepbuhay.s3.amazonaws.com/web/icons/v2/HanepBuhay+-+Icons+2.0_Transaction.png",
      route: "/transactionsList",
    },
    {
      name: "Hanep FAQs",
      icon: "https://phx-hanepbuhay.s3.amazonaws.com/web/icons/v2/HanepBuhay+-+Icons+2.0_FAQ.png",
      route: "/faqs",
    },
    {
      name: "Customer Support",
      icon: "https://phx-hanepbuhay.s3.amazonaws.com/web/icons/v2/HanepBuhay+-+Icons+2.0_Tech+Support.png",
      route: "https://m.me/hanepbuhay.support",
    },
  ];
};
