import React from "react";
import { Title } from "./styles";

const ComingSoon = () => {
  return (
    <>
      <Title>
        Sorry for the inconvenience. This feature will be available soon!
      </Title>
    </>
  );
};

export default ComingSoon;
