import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  font-size: 30px;
  padding: 5% 10% 0 10%;
  color: white;
  flex-direction: column;
`;

export const Title = styled.div`
  padding-top: 2%;
`;
