import styled from "styled-components";
import { primaryColor } from "../../../global/constants";

export const Title = styled.div`
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  padding-top: 20%;
  font-size: 20px;
  color: ${primaryColor};
  letter-spacing: 2px;
`;
