import React from "react";
import PaymentDetail from "../../common/PaymentDetail";

const GamingEpinsSummary = ({ detail }) => {
  return (
    <>
      <PaymentDetail title="Mobile Number:" value={detail.mobileNumber} />
      <PaymentDetail title="Product:" value={detail.product} />
      <PaymentDetail title="Amount:" value={"P" + detail.amount} />
      <PaymentDetail
        title="Discount"
        value={"P" + detail.discount.toFixed(2)}
      />
       <PaymentDetail
        title="Net Total"
        value={"P" + (detail.amount - detail.discount)}
      />
    </>
  );
};

export default GamingEpinsSummary;
