import { domainType } from "../../global/constants";
import theme from "../../global/theme";

export const eserviceOptions =  theme.types[`${domainType}`].id === "ASKANI" ?[
  "Cignal TV",
]:[
  "Prepaid",
  "Wifi data",
  "E-Wallet Loading",
  "Bills Payment",
  "Hanepmart",
  "Cignal TV",
  "Gaming Epins"
];

export const dateOptions = [
  "Today",
  "Yesterday",
  "Last 7 days",
  "Last 30 days",
];

export const inputStyle = {
  backgroundColor: "white",
  height: "50px",
  margin: "0px",
  padding: "0px",
};
