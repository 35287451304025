import { domainType } from "./constants";
import theme from "./theme";

export const reformatName = (name) => {
  const nameSplit = name.split(" ")
  const length = nameSplit.length
  if (length > 1) {
    let name = ""
    nameSplit.map((n) => {
      const splitName = n.split(/(?=[A-Z])/);
      name = `${name} ${splitName[0].charAt(0).toUpperCase() + splitName[0].slice(1)}`
    })
    return name

  } else {
    const splitName = name.split(/(?=[A-Z])/);
    return splitName[0].charAt(0).toUpperCase() + splitName[0].slice(1);
  }
};

export const reformatDateAndTime = (date) => {
  const newDate = new Date(date);
  const splitDate = newDate.toString().split(" ");
  const splitTime = splitDate[4].split(":");
  const pm = parseInt(splitTime[0]) > 12;
  const hr = pm ? parseInt(splitTime[0]) - 12 : splitTime[0];
  const time = `${hr}:${splitTime[1]}:${splitTime[2]}`;
  return {
    date: `${splitDate[1]} ${splitDate[2]}, ${splitDate[3]}`,
    time: `${time} ${pm ? "PM" : "AM"}`,
  };
};

export const formatPrice = (price) => {
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "PHP",
  }).format(price);
};

export const getLocalStorage = (keyName) => {
  const data = localStorage.getItem(keyName);
  if (!data) {
    return null;
  }
  const item = JSON.parse(data);

  if (Date.now() > item.ttl) {
    localStorage.removeItem(keyName);
    return null;
  }
  return item.value;
};

export const setLocalStorage = (keyName, keyValue, ttl) => {
  const data = {
    value: keyValue,
    ttl: Date.now() + ttl * 1000,
  };

  localStorage.setItem(keyName, JSON.stringify(data));
};

export const removeLocalStorage = (keyName) => {
  localStorage.removeItem(keyName);
};

export const encrypt = (type, toEncrypt) => {
  const CryptoJS = require("crypto-js");
  return CryptoJS.AES.encrypt(type, toEncrypt).toString();
};

export const epinsEncrypt = (key, toEncrypt) => {
  const CryptoJS = require("crypto-js");
  const cryptkey = CryptoJS.enc.Utf8.parse(key);
  var plain = CryptoJS.AES.encrypt(toEncrypt, cryptkey, {
    mode: CryptoJS.mode.ECB,
  }).toString();
  return plain;
};

export const hasValue = (data) => {
  return data !== "" && data !== null && data !== undefined;
};

export const checkValidMobile = (number) => {
  return /^(09)\d{9}$/.test(number);
};

export const returnLogo = (logo) => {
  if (theme.types) {
    const typesDetail = theme.types[`${domainType}`];
    return typesDetail?.logo || logo;
  } else {
    return logo;
  }
};
