import React from "react";
import PaymentDetail from "../../common/PaymentDetail";

const DirectLoadSummary = ({ detail }) => {
  return (
    <>
      <PaymentDetail title="Epin Value:" value={detail.epinValue} />
      <PaymentDetail title="Account Number:" value={detail.accountNumber} />
    </>
  );
};

export default DirectLoadSummary;
