import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import {
  Content,
  ProceedButton,
  Red,
  Caption,
  ButtonContent,
  FieldsContent,
  UnderCaption,
} from "./styles";
import { push } from "connected-react-router";
import sku, { spiels, services } from "./constants";
import InputDetail from "../../components/common/InputDetail";
import SelectDetail from "../../components/common/SelectDetail";
import { useLocation } from "react-router";
import queryString from "query-string";
import FooterContainer from "../../components/common/FooterContainer";
import TitleHeader from "../../components/common/TitleHeader";
import { checkValidMobile } from "../../global/helpers";
import { domainType } from "../../global/constants";
import theme from "../../global/theme";

const mapStateToProps = ({ dispatch, emoneyReducer, globalReducer }) => ({
  dispatch,
  emoneyStore: emoneyReducer,
  globalStore: globalReducer,
});

const BuyEMoneyScreen = ({ dispatch, emoneyStore, globalStore }) => {
  let hiddenMenus = [];
  if (theme.types) {
    const typesDetail = theme.types[`${domainType}`];
    hiddenMenus = typesDetail?.hiddenMenus || [];
  }
  const location = useLocation();
  const params = queryString.parse(location.search);
  const [buyDetails, setBuyDetails] = useState({
    "E-Money": emoneyStore.buyDetails["E-Money"],
    accountNumber: emoneyStore.buyDetails.accountNumber,
    product: emoneyStore.buyDetails.product,
    amount: emoneyStore.buyDetails.amount,
    service: emoneyStore.buyDetails.service,
  });
  const emoneyOptions =
    hiddenMenus.length > 0
      ? ["GCash", "Paymaya"].filter((option) => {
          return !hiddenMenus.includes(option);
        })
      : ["GCash", "Paymaya"];
  const [error, setError] = useState(false);

  const requestProducts = (mode) => {
    setBuyDetails({
      ...buyDetails,
      "E-Money": mode,
    });
    dispatch({
      type: "emoney/SET_CATEGORY",
      payload: mode,
    });
  };

  const returnEmoneySku = (category, amount) => {
    const chosenSku = sku.variable_sku.filter((product) => {
      return (
        product.category.toUpperCase() === category.toUpperCase() &&
        product.minAmount <= amount &&
        product.maxRange >= amount
      );
    });
    return chosenSku.length > 0 ? chosenSku[0].sku : null;
  };

  const onProceed = () => {
    if (
      !returnEmoneySku(buyDetails["E-Money"], buyDetails.amount) &&
      buyDetails.service === "Cash in (add funds)"
    ) {
      {
        buyDetails["E-Money"] === "Paymaya"
          ? setError("Input should be within 100-10000")
          : !!theme.types[`${domainType}`].gcash50
          ? setError("Input should be within 50-25000")
          : setError("Input should be within 10-25000");
      }
    } else if (
      buyDetails.service === "Cash out (withdraw)" &&
      buyDetails.amount < 50
    ) {
      setError("Input should be within 50-25000");
    } else if (
      !!theme.types[`${domainType}`].gcash50 &&
      buyDetails.amount < 50
    ) {
      setError("Input should be within 50-25000");
    } else if (
      buyDetails["E-Money"] === "GCash" &&
      !checkValidMobile(buyDetails.accountNumber)
    ) {
      setError("Invalid mobile number");
    } else {
      dispatch({
        type: "emoney/SET_BUY_DETAILS",
        payload: {
          ...buyDetails,
          product:
            !!theme.types[`${domainType}`].gcash50 &&
            returnEmoneySku(buyDetails["E-Money"], buyDetails.amount) ===
              "GCASHV1"
              ? "GCASHV50"
              : returnEmoneySku(buyDetails["E-Money"], buyDetails.amount),
          userId: emoneyStore.sessionDetails.userId,
          sessionId: emoneyStore.sessionDetails.sessionId,
        },
      });
      dispatch(push("/summary"));
    }
  };
  useEffect(() => {
    function run() {
      setError(false);
    }
    run();
  }, [buyDetails]);

  useEffect(() => {
    dispatch({
      type: "global/CURRENT_ENDPOINT",
      payload: "E-Wallet Loading",
    });
    dispatch({
      type: "emoney/REQUEST_TOKEN",
      payload: {
        userId: params.userId,
        sessionId: params.sessionId,
      },
    });
  }, [dispatch, params.userId, params.sessionId]);

  useEffect(() => {
    if (emoneyStore.sessionDetails.sessionToken) {
      dispatch({
        type: "emoney/REQUEST_PRODUCTS",
        payload: {
          token: emoneyStore.sessionDetails.sessionToken,
        },
      });
    }
  }, [dispatch, emoneyStore.sessionDetails.sessionToken]);

  const returnSpiel = (option) => {
    return spiels.filter((spiel) => {
      return JSON.stringify(spiel.options) === JSON.stringify(option);
    })[0];
  };

  const renderUnderCaption = () => {
    const captions = {
      GCash:
        "Cashing in to a GCash account via HanepBuhay is free as long as it is within the recipient's Php 8,000 overall monthly limit.",
      Paymaya:
        "Cashing in to a PayMaya account via HanepBuhay is free as long as it is within the recipient's Php 10,000 overall monthly limit.",
    };
    if (buyDetails["E-Money"]) {
      return <UnderCaption>{captions[buyDetails["E-Money"]]}</UnderCaption>;
    } else {
      return <UnderCaption>{returnSpiel(emoneyOptions).spiel}</UnderCaption>;
    }
  };
  return (
    <>
      <Content>
        <div>
          <TitleHeader
            title={globalStore.pageTitle}
            type={
              globalStore.sessionDetails &&
              globalStore.sessionDetails.sessionType
            }
          />
          <FieldsContent>
            <SelectDetail
              title="Select E-Money"
              // underCaption={renderUnderCaption}
              subtitle="Choose your E-Money"
              options={emoneyOptions}
              value={buyDetails["E-Money"]}
              onChange={(e) => {
                requestProducts(e.target.value);
              }}
            />
            {buyDetails["E-Money"] && buyDetails["E-Money"] === "GCash" ? (
              <div>
                <SelectDetail
                  title="Select Service"
                  subtitle="Choose your service"
                  options={services}
                  value={buyDetails.service}
                  onChange={(e) => {
                    setBuyDetails({
                      ...buyDetails,
                      service: e.target.value,
                    });
                  }}
                />
                {buyDetails.service && (
                  <>
                    <InputDetail
                      title="Account Number"
                      subtitle="Enter Account Number"
                      value={buyDetails.accountNumber}
                      onChange={(e) => {
                        setBuyDetails({
                          ...buyDetails,
                          accountNumber: e.target.value,
                        });
                      }}
                      type="number"
                    />
                    {emoneyStore.products && (
                      <InputDetail
                        title={"Amount"}
                        subtitle={
                          buyDetails.service === "Cash in (add funds)"
                            ? !!theme.types[`${domainType}`].gcash50
                              ? "50-25000"
                              : "10-25000"
                            : "50-25000"
                        }
                        onChange={(e) => {
                          setBuyDetails({
                            ...buyDetails,
                            amount: e.target.value,
                          });
                        }}
                        value={buyDetails.amount}
                        type="number"
                      />
                    )}
                  </>
                )}
              </div>
            ) : buyDetails["E-Money"] === "Paymaya" ? (
              <div>
                <InputDetail
                  title="Money Code"
                  subtitle="Enter Money Code"
                  value={buyDetails.accountNumber}
                  onChange={(e) => {
                    setBuyDetails({
                      ...buyDetails,
                      accountNumber: e.target.value,
                    });
                  }}
                  caption={() => {
                    return (
                      <Caption>
                        Open your Paymaya App, go to cashin option and select
                        <strong> Sendah </strong> to generate money code.
                        Minimum of Php 100. 2% Convenience Fee applied in total
                        topup amount.
                      </Caption>
                    );
                  }}
                  type="number"
                />
                {emoneyStore.products && (
                  <InputDetail
                    title={"Amount"}
                    subtitle={"100-10000"}
                    onChange={(e) => {
                      setBuyDetails({
                        ...buyDetails,
                        amount: e.target.value,
                      });
                    }}
                    value={buyDetails.amount}
                    type="number"
                  />
                )}
              </div>
            ) : (
              <div></div>
            )}
            {error && <Red>{error}</Red>}
          </FieldsContent>
          <ButtonContent>
            <ProceedButton
              variant="contained"
              onClick={() => {
                onProceed();
              }}
              disabled={
                !(
                  buyDetails.accountNumber &&
                  buyDetails.accountNumber.length >= 7 &&
                  buyDetails.accountNumber.length <= 11
                ) || !buyDetails.amount
              }
            >
              Proceed
            </ProceedButton>
          </ButtonContent>
        </div>

        <FooterContainer />
      </Content>
    </>
  );
};

export default connect(mapStateToProps)(BuyEMoneyScreen);
