import apiClient from "../../axios";
import { toastError } from "../../global/constants";

export async function getToken(payload) {
  try {
    const response = await apiClient.post("/cignal/get-token", payload);
    return response.data.data;
  } catch (e) {
    const message = e.response.data.error.message;
    const code = e.response.data.error.code;
    toastError(`[${code}] ${message}`);
    return { error: message };
  }
}


export async function payTransact(payload, token) {
  try {
    const response = await apiClient.post(`/cignal/directload`, payload, {
      headers: {
        Authorization: token,
      },
    });
    return response.data.data;
  } catch (e) {
    const message = e.response.data.error.message;
    const code = e.response.data.error.code;
    toastError(`[${code}] ${message}`);
    return { error: message };
  }
}
