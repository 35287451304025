import { all, takeEvery, put, call } from "redux-saga/effects";
import { push } from "connected-react-router";
import { actions } from "./reducers";
import * as global from "./services";
import { reformatName, setLocalStorage } from "./helpers";
import configs from "../configs";

export function* REQUEST_CREATE_SESSION({ payload }) {
  yield put({
    type: "global/SET_LOADING",
    payload: true,
  });
  let reqBody = payload;
  let finalResponse = {};
  if (!payload.userId || !payload.sessionId) {
    setLocalStorage("otpDetails", reqBody, configs.config.sessionExpiry);
    const sessionRes = yield call(global.createSession, payload);
    if (sessionRes.error) {
      yield put({
        type: "global/SET_IS_SESSION_FETCHED",
        payload: true,
      });
      yield put({
        type: "global/SET_LOADING",
        payload: false,
      });
      yield put({
        type: "login/REQUEST_LOGOUT_OTP",
        payload: payload.otpId,
      });
      yield put(push("/login"));
    } else {
      reqBody = {
        userId: sessionRes.userId,
        sessionId: sessionRes.sessionId,
      };
      finalResponse["userName"] = sessionRes.userName;
      finalResponse["name"] = `${reformatName(
        sessionRes.firstName
      )} ${reformatName(sessionRes.lastName)}`;
      setLocalStorage("sessionDetails", reqBody, configs.config.sessionExpiry);
    }
  }
  const res = yield call(global.getToken, reqBody);
  if (res) {
    yield put({
      type: "global/RECEIVE_SESSION_DETAILS",
      payload: { ...res, ...finalResponse },
    });
    setLocalStorage(
      "userDetails",
      {
        userName: finalResponse["userName"],
        name: finalResponse["name"],
        balance: res.walletBalance,
      },
      configs.config.sessionExpiry
    );
    yield put({
      type: "global/SET_IS_SESSION_FETCHED",
      payload: true,
    });
    yield put({
      type: "global/SET_LOADING",
      payload: false,
    });
  }
  if (res.error) {
    yield put({
      type: "global/SET_IS_SESSION_FETCHED",
      payload: true,
    });
    yield put({
      type: "login/REQUEST_LOGOUT_OTP",
      payload: payload.otpId,
    });
    yield put({
      type: "global/SET_LOADING",
      payload: false,
    });
    yield put(push("/login"));
  }
}

export function* REQUEST_GENERAL_INQUIRE({ payload }) {
  const res = yield call(global.inquire, payload);
  if (res) {
    yield put({
      type: "global/RECEIVE_GENERAL_INQUIRE",
      payload: res,
    });
  } else if (res.error) {
    yield put(push("/dashboard"));
  }
}

export function* SET_NULL_OUTPUTS() {
  yield put({
    type: "global/RECEIVE_TRANSACTION_OUTPUT",
    payload: null,
  });
  yield put({
    type: "global/RECEIVE_GENERAL_INQUIRE",
    payload: null,
  });
  yield put({
    type: "egrocery/SET_GROCERY_DETAILS",
    payload: {},
  });
  yield put({
    type: "egrocery/SET_CART_DETAILS",
    payload: [],
  });
  yield put({
    type: "emoney/SET_BUY_DETAILS",
    payload: {},
  });
  yield put({
    type: "load/SET_LOAD_DETAILS",
    payload: {},
  });
  yield put({
    type: "gaming/SET_PAY_DETAILS",
    payload: {},
  });
  yield put({
    type: "wifi/SET_WIFI_DETAILS",
    payload: {},
  });
  yield put({
    type: "bills/SET_BILL_DETAILS",
    payload: {},
  });
  yield put({
    type: "cignal/SET_PAY_DETAILS",
    payload: {},
  });
  yield put({
    type: "cignalweb/SET_PAY_DETAILS",
    payload: {},
  });
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.REQUEST_CREATE_SESSION, REQUEST_CREATE_SESSION),
  ]);
  yield all([
    takeEvery(actions.REQUEST_GENERAL_INQUIRE, REQUEST_GENERAL_INQUIRE),
  ]);
  yield all([takeEvery(actions.SET_NULL_OUTPUTS, SET_NULL_OUTPUTS)]);
}
