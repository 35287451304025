import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Content, ProceedButton } from "./styles";
import SelectDetail from "../../components/common/SelectDetail";
import ComingSoon from "../../components/common/ComingSoon";

const mapStateToProps = ({ dispatch }) => ({
  dispatch,
});

const RemittanceScreen = ({ dispatch }) => {
  useEffect(() => {
    dispatch({
      type: "global/CURRENT_ENDPOINT",
      payload: "E-Wallet Loading",
    });
  }, [dispatch]);
  return (
    <>
      <Content>
        <div>
          <SelectDetail
            title="Select Remittance"
            options={[]}
            subtitle="Choose Remittance"
            disabled={true}
          />
          <ComingSoon />
        </div>
        <div>
          <ProceedButton variant="contained" disabled={true}>
            Proceed
          </ProceedButton>
        </div>
      </Content>
    </>
  );
};

export default connect(mapStateToProps)(RemittanceScreen);
