import React from "react";
import { Content, Title, Subtitle } from "./styles";

const NotFound = () => {
  return (
    <>
      <Content>
        <Title> 404</Title>
        <Subtitle> Nothing was found! </Subtitle>
      </Content>
    </>
  );
};

export default NotFound;
