import React from "react";
import PaymentDetail from "../../common/PaymentDetail";
import * as constants from "./constants";

const BuyLoadSummary = ({ detail }) => {
  return (
    <>
      <PaymentDetail
        title={constants.numberTitle}
        value={detail.mobileNumber}
      />
      <PaymentDetail title={constants.telcoTitle} value={detail.telco} />
      <PaymentDetail title={constants.productsTitle} value={detail.products} />
      <PaymentDetail value={detail.load} />
      <PaymentDetail
        title={constants.amountTitle}
        value={"P" + detail.amount}
      />
      <PaymentDetail
        title="Discount"
        value={"P" + detail.discount.toFixed(2)}
      />
      <PaymentDetail
        title="Net Total"
        value={"P" + (detail.amount - detail.discount)}
      />
    </>
  );
};

export default BuyLoadSummary;
