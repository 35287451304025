import styled from "styled-components";
import UploadIcon from "@mui/icons-material/Upload";
import { primaryColor } from "../../../global/constants";

export const CustomTitle = styled.p`
  font-size: 15px;
  margin-top: 15px;
  display: flex;
  justify-content: flex-start;
  margin-bottom: 5px;
  width: 100%;
`;

export const UploadImageContainer = styled.div`
  border: 1px dashed #f1f1f1;
  box-sizing: border-box;
  border-radius: 5px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 170px;
  margin-bottom: 15px;
  color: #c0c0c0;
  &:hover {
    border-color: ${primaryColor};
    color: ${primaryColor};
  }
`;

export const Upload = styled(UploadIcon)`
  font-size: 55px !important;
`;

export const UploadTitle = styled.div`
  font-size: 17px !important;
`;

export const Image = styled.img`
  width: 100%;
  object-fit: scale-down;
  overflow: hidden;
`;

export const HideInput = styled.input`
  opacity: 0%;
  height: 0px;
  width: 0px;
`;

export const Red = styled.div`
  color: red;
`;

export const Subtitle = styled.p`
  margin: 0px;
  padding: 0px;
  font-size: 13px;
  opacity: 0.5;
  margin-bottom: 10px;
`;
