export const actions = {
  REQUEST_TOKEN: "bills/REQUEST_TOKEN",
  RECEIVE_TOKEN: "bills/RECEIVE_TOKEN",
  REQUEST_BILLERS: "bills/REQUEST_BILLERS",
  RECEIVE_BILLERS: "bills/RECEIVE_BILLERS",
  REQUEST_BILL_INFO: "bills/REQUEST_BILL_INFO",
  RECEIVE_BILL_INFO: "bills/RECEIVE_BILL_INFO",
  SET_BILL_DETAILS: "bills/SET_BILL_DETAILS",
  REQUEST_PAY_BILL: "bills/REQUEST_PAY_BILL",
  RECEIVE_PAY_BILL: "bills/RECEIVE_PAY_BILL",
  REQUEST_TRANSACTIONS: "bills/REQUEST_TRANSACTIONS",
  RECEIVE_TRANSACTIONS: "bills/RECEIVE_TRANSACTIONS",
  REQUEST_BILLER_FEE: "bills/REQUEST_BILLER_FEE",
  RECEIVE_BILLER_FEE: "bills/RECEIVE_BILLER_FEE",
  SET_CATEGORY: "bills/SET_CATEGORY",
  SET_LOADING: "bills/SET_LOADING",
  SET_TRANSACT_RESPONSE: "bills/SET_TRANSACT_RESPONSE",
  SET_TRANSACT_REQUEST: "bills/SET_TRANSACT_REQUEST",
  REQUEST_INQUIRE: "bills/REQUEST_INQUIRE",
  RECEIVE_INQUIRE: "bills/RECEIVE_INQUIRE",
};

const initialState = {
  billers: [],
  billInfo: {},
  billDetails: {},
  sessionDetails: {},
  transactions: [],
  billerFee: {},
  category: "",
  loading: true,
  transactResponse: {},
  transactRequest: {},
};

export default function billsReducers(state = initialState, action) {
  switch (action.type) {
    case actions.RECEIVE_TOKEN:
      return { ...state, sessionDetails: action.payload };
    case actions.RECEIVE_BILLERS:
      return { ...state, billers: action.payload };
    case actions.RECEIVE_BILL_INFO:
      return { ...state, billInfo: action.payload };
    case actions.SET_BILL_DETAILS:
      return { ...state, billDetails: action.payload };
    case actions.RECEIVE_PAY_BILL:
      return { ...state, billDetails: action.payload };
    case actions.RECEIVE_TRANSACTIONS:
      return { ...state, transactions: action.payload };
    case actions.RECEIVE_BILLER_FEE:
      return { ...state, billerFee: action.payload };
    case actions.SET_CATEGORY:
      return { ...state, category: action.payload };
    case actions.SET_LOADING:
      return { ...state, loading: action.payload };
    case actions.SET_TRANSACT_RESPONSE:
      return { ...state, transactResponse: action.payload };
    case actions.SET_TRANSACT_REQUEST:
      return { ...state, transactRequest: action.payload };
    case actions.RECEIVE_INQUIRE:
      return { ...state, billDetails: action.payload };
    default:
      return state;
  }
}
