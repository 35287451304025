export const telco = ["PLDT", "GLOBE", "SMART"];
export const products = [
  {
    title: "HOMESURF 199",
    subtitle:
      "5GB data (Available for Globe at Home Prepaid Wifi only.)valid for 3 days.",
  },
  {
    title: "HOMESURF 599",
    subtitle:
      "55GB valid for 15 days. 40GB + 1GB Daily for Youtube. (Available for Globe at Home Prepaid WiFi only), valid for 15 days.",
  },
  {
    title: "HOMESURF 999",
    subtitle:
      "Up to 100GB valid for 30 days. 70GB + 1GB Daily for Youtube. (Available for Globe at Home Prepaid WiFi only), valid for 30 days.",
  },
];
