export const actions = {
  REQUEST_TRANSACTIONS: "transactions/REQUEST_TRANSACTIONS",
  RECEIVE_TRANSACTIONS: "transactions/RECEIVE_TRANSACTIONS",
  SET_LOADING: "transactions/SET_LOADING",
};

const initialState = {
  loading: false,
  transactions: [],
};

export default function transactionsReducer(state = initialState, action = {}) {
  switch (action.type) {
    case actions.RECEIVE_TRANSACTIONS:
      return { ...state, transactions: action.payload };
    case action.SET_LOADING:
      return { ...state, loading: action.payload };
    default:
      return state;
  }
}
