import styled from "styled-components";
import { Button } from "@mui/material/";
import { styled as muiStyled } from "@mui/material/styles/";
import { ChevronRight } from "@mui/icons-material/";

export const BillersButton = muiStyled(Button)({
  textAlign: "left",
  color: "black",
  textTransform: "none",
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  fontSize: 16,
  padding: "15px",
  lineHeight: 1.5,
  backgroundColor: "white",
  borderRadius: 0,
  borderTop: "1px solid #C4C4C4",
  width: "100%",
  "&:hover": {
    backgroundColor: "#E3E3E3",
    boxShadow: "none",
  },
  "&:active": {
    boxShadow: "none",
    backgroundColor: "#E3E3E3",
  },
});

export const RightIcon = styled(ChevronRight)`
  font-size: 25px;
`;

export const BillersContent = styled.div`
  overflow-y: scroll;
  height: 90vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const CategoryContent = styled.div`
  display: flex;
  flex-direction: column;
  height: 50vh;
  padding: 5% 10% 0 10%;
`;
export const Categories = styled.div`
  overflow-y: auto;
  padding: 0 1% 0 1%;
  border-radius: 20px;
  background-color: white;
`;
export const Empty = styled.div`
  display: flex;
  align-content: center;
  justify-content: center;
  width: 100%;
  padding-top: 20px;
`;

export const ButtonContainer = styled.div`
  padding: 0px 15px 0px 15px;
`;

export const TitleBar = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: white;
  padding-bottom: 10px;
  height: 50px;
`;
