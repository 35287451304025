import React from "react";
import { CustomInput, CustomTitle, Red } from "./styles";

const InputDetail = ({
  title,
  subtitle,
  onChange,
  type,
  value,
  required,
  caption,
  underCaption,
}) => {
  return (
    <>
      <CustomTitle>
        {title}
        {required && <Red>*</Red>}
      </CustomTitle>
      {caption && caption()}
      <CustomInput
        id="outlined-basic"
        onChange={onChange}
        label={subtitle}
        type={type}
        autoComplete="off"
        value={value}
        variant="outlined"
        style={{ backgroundColor: "white", borderRadius: "5px" }}
      />
      {underCaption && underCaption()}
    </>
  );
};

export default InputDetail;
