import styled from "styled-components";
import { primaryColor } from "../../../global/constants";

export const Container = styled.div`
  width: 100%;
  color: white;
  background-color: ${primaryColor};
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  font-size: 15px;
  padding-top: 5px;
  padding-bottom: 5px;
`;
