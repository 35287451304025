import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import React, { useEffect } from "react";
import { push } from "connected-react-router";
import { connect } from "react-redux";
import { getLocalStorage, setLocalStorage } from "./global/helpers";
import queryString from "query-string";
import { Container, Loading } from "./styles";
import configs from "./configs";
import Favicon from "react-favicon";
import theme from "./global/theme";
import { domainType } from "./global/constants";

import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import FAQScreen from "./containers/FAQScreen";

import BuyLoadScreen from "./containers/BuyLoadScreen";
import BuyWifiScreen from "./containers/BuyWifiScreen";
import BuyEMoneyScreen from "./containers/BuyEMoneyScreen";
import BuyEGroceryScreen from "./containers/BuyEGroceryScreen";
import EGroceryFields from "./components/screens/EGroceryFields";
import RemittanceScreen from "./containers/RemittanceScreen";

import BillersCategories from "./components/screens/BillersCategories";
import BillersList from "./components/screens/BillersList";
import BillersFields from "./components/screens/BillersFields";

import CignalPrepaidScreen from "./containers/CignalPrepaidScreen";
import CignalKitScreen from "./containers/CignalKitScreen";
import CignalFields from "./components/screens/CignalFields";
import CignalPlayScreen from "./containers/CignalPlayScreen";
import CignalPostpaidScreen from "./containers/CignalPostpaidScreen";
import CignalPayScreen from "./containers/CignalPayScreen";

import CignalDirectLoadTool from "./containers/CignalDirectLoadTool";

import SignUpScreen from "./containers/SignUpScreen";
import SignUpIDFields from "./components/screens/SignUpIDFields";

import GamingEpinsScreen from "./containers/GamingEpinsScreen";

import ToolBar from "./components/common/ToolBar";
import PaymentSummary from "./components/screens/PaymentSummary";
import PaymentSuccessful from "./components/screens/PaymentSuccessful";
import TransactionHistory from "./components/screens/TransactionHistory";
import NotFound from "./components/screens/NotFound";

import LoginScreen from "./containers/LoginScreen";
import OTPScreen from "./components/screens/OTPScreen";
import DashboardScreen from "./containers/DashboardScreen";
import TransactionsScreen from "./containers/TransactionsScreen";

import { ToastContainer } from "react-toastify";
const mapStateToProps = ({ globalReducer, router, loginReducer }) => ({
  loginStore: loginReducer,
  globalStore: globalReducer,
  router,
});

console.log(window.location.href.includes('localhost'))
function App({ globalStore, history, router, loginStore, dispatch }) {
  const location = router.location;
  const params = queryString.parse(location.search);

  const needToolbar = () => {
    const paths = ["/faqs", "/login", "/otp"];
    return !paths.includes(location.pathname);
  };

  function initializeOTPDetails() {
    const otpDetails = getLocalStorage("otpDetails");

    if (!otpDetails) {
      dispatch({
        type: "global/SESSION_OFFLINE",
      });

      return;
    }

    dispatch({
      type: "global/RECEIVE_OTP_DETAILS",
      payload: otpDetails,
    });
  }

  function callCreateSessionFromOTP() {
    if (!globalStore.otpDetails) {
      return;
    }

    if (params.sessionId || params.userId) {
      return;
    }

    dispatch({
      type: "global/REQUEST_CREATE_SESSION",
      payload: {
        otpId: globalStore.otpDetails.otpId,
        phoenixId: globalStore.otpDetails.phoenixId,
      },
    });
  }

  useEffect(initializeOTPDetails, [dispatch]);
  useEffect(callCreateSessionFromOTP, [dispatch, globalStore.otpDetails]);

  const isLoggedIn = !!globalStore.sessionDetails;
  const isSessionFetched = globalStore.isSessionFetched;
  const isBot = params.sessionId && params.userId;

  const loggedInRoutes = (
    <Switch>
      <Route path="/faqs">
        <FAQScreen />
      </Route>
      <Route path="/buy-load">
        <BuyLoadScreen />
      </Route>
      <Route path="/buy-wifi">
        <BuyWifiScreen />
      </Route>
      <Route path="/buy-emoney">
        <BuyEMoneyScreen />
      </Route>
      <Route path="/hanepmart/fields">
        <EGroceryFields />
      </Route>
      <Route path="/hanepmart">
        <BuyEGroceryScreen />
      </Route>

      <Route path="/pay-bills/list">
        <BillersList />
      </Route>
      <Route path="/pay-bills/bill">
        <BillersFields />
      </Route>
      <Route path="/pay-bills">
        <BillersCategories />
      </Route>
      <Route path="/remittance">
        <RemittanceScreen />
      </Route>

      <Route path="/cignal/direct-load">
        <CignalDirectLoadTool />
      </Route>
      <Route path="/cignal/prepaid">
        <CignalPrepaidScreen />
      </Route>
      <Route path="/cignal/postpaid">
        <CignalPostpaidScreen />
      </Route>
      <Route path="/cignal/fields">
        <CignalFields />
      </Route>
      <Route path="/cignal/kit">
        <CignalKitScreen />
      </Route>
      <Route path="/cignal/play">
        <CignalPlayScreen />
      </Route>
      <Route path="/cignal/pay">
        <CignalPayScreen />
      </Route>

      <Route path="/sign-up/id">
        <SignUpIDFields />
      </Route>
      <Route path="/sign-up">
        <SignUpScreen />
      </Route>

      <Route path="/gaming">
        <GamingEpinsScreen />
      </Route>

      <Route path="/summary">
        <PaymentSummary />
      </Route>
      <Route path="/result">
        <PaymentSuccessful />
      </Route>
      <Route path="/transactions">
        <TransactionHistory />
      </Route>
      <Route path="/not-found">
        <NotFound />
      </Route>

      <Route path="/dashboard">
        <DashboardScreen />
      </Route>
      <Route path="/transactionsList">
        <TransactionsScreen />
      </Route>
      {/* <Route path="/">
    <Redirect to="/sign-up" />
  </Route> */}
      <Route path="*">
        <Redirect to="/dashboard" />
      </Route>
    </Switch>
  );

  const loggedOffRoutes = (
    <Switch>
      <Route exact path="/login">
        <LoginScreen />
      </Route>
      <Route exact path="/otp">
        <OTPScreen />
      </Route>
      <Route path="/faqs">
        <FAQScreen />
      </Route>
      <Route path="*">
        <Redirect to="/login" />
      </Route>
    </Switch>
  );

  if (isBot) {
    setLocalStorage("sessionDetails", "BOT", configs.config.sessionExpiry);
    return (
      <Container theme="nebula">
        {needToolbar() && <ToolBar title={globalStore.pageTitle} />}
        <ToastContainer limit={1} className="toast" />
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          {loggedInRoutes}
        </LocalizationProvider>
      </Container>
    );
  }

  if (!isSessionFetched) {
    return <Loading> Loading ...</Loading>;
  }

  return (
    <Container theme="nebula">
      {needToolbar() && <ToolBar title={globalStore.pageTitle} />}
      <ToastContainer limit={1} className="toast" />
      <Favicon url={theme.types[`${domainType}`].favicon || theme.images.logo} />

      <LocalizationProvider dateAdapter={AdapterDateFns}>
        {isLoggedIn ? loggedInRoutes : loggedOffRoutes}
      </LocalizationProvider>
    </Container>
  );
}

export default connect(mapStateToProps)(App);
