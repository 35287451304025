import { all } from "redux-saga/effects";
import load from "./containers/BuyLoadScreen/sagas";
import wifi from "./containers/BuyWifiScreen/sagas";
import emoney from "./containers/BuyEMoneyScreen/sagas";
import bills from "./containers/PayBillsScreen/sagas";
import cignal from "./containers/CignalPrepaidScreen/sagas";
import cignalweb from "./containers/CignalDirectLoadTool/sagas";
import eGrocery from "./containers/BuyEGroceryScreen/sagas";
import signUp from "./containers/SignUpScreen/sagas";
import login from "./containers/LoginScreen/sagas";
import transactions from "./containers/TransactionsScreen/sagas";
import gaming from "./containers/GamingEpinsScreen/sagas";
import global from "./global/sagas";

export default function* rootSaga() {
  yield all([
    load(),
    wifi(),
    emoney(),
    bills(),
    cignal(),
    cignalweb(),
    eGrocery(),
    signUp(),
    login(),
    transactions(),
    gaming(),
    global(),
  ]);
}
