import React from "react";
import PaymentDetail from "../../common/PaymentDetail";

const PrepaidSummary = ({ detail }) => {
  return (
    <>
      <PaymentDetail title="Select Category:" value={detail.category} />
      <PaymentDetail title="Product:" value={detail.product} />
      <PaymentDetail title="Account Number type:" value={detail.accountType} />
      <PaymentDetail title="Account Number:" value={detail.accountNumber} />
    </>
  );
};

export default PrepaidSummary;
