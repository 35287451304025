export const actions = {
  REQUEST_TOKEN: "wifi/REQUEST_TOKEN",
  RECEIVE_TOKEN: "wifi/RECEIVE_TOKEN",
  SET_WIFI_DETAILS: "wifi/SET_WIFI_DETAILS",
  REQUEST_PRODUCTS: "wifi/REQUEST_PRODUCTS",
  RECEIVE_PRODUCTS: "wifi/RECEIVE_PRODUCTS",
  REQUEST_BUY_WIFI: "wifi/REQUEST_BUY_WIFI",
  RECEIVE_BUY_WIFI: "wifi/RECEIVE_BUY_WIFI",
  REQUEST_TRANSACTIONS: "wifi/REQUEST_TRANSACTIONS",
  RECEIVE_TRANSACTIONS: "wifi/RECEIVE_TRANSACTIONS",
  SET_CATEGORY: "wifi/SET_CATEGORY",
  SET_LOADING: "wifi/SET_LOADING",
};

const initialState = {
  wifiDetails: {},
  sessionDetails: {},
  products: [],
  transactions: [],
  category: "",
  loading: true,
};

export default function wifiReducer(state = initialState, action) {
  switch (action.type) {
    case actions.RECEIVE_TOKEN:
      return { ...state, sessionDetails: action.payload };
    case actions.SET_WIFI_DETAILS:
      return { ...state, wifiDetails: action.payload };
    case actions.RECEIVE_BUY_WIFI:
      return { ...state, wifiDetails: action.payload };
    case actions.RECEIVE_TRANSACTIONS:
      return { ...state, transactions: action.payload };
    case actions.RECEIVE_PRODUCTS:
      return { ...state, products: action.payload };
    case actions.SET_CATEGORY:
      return { ...state, category: action.payload };
    case actions.SET_LOADING:
      return { ...state, loading: action.payload };
    default:
      return state;
  }
}
