import { all, takeEvery, put, call } from "redux-saga/effects";
import { push, goBack } from "connected-react-router";
import { reformatDateAndTime } from "../../global/constants";
import * as bills from "./services";
import { actions } from "./reducers";

export function* REQUEST_TOKEN({ payload }) {
  const tokenRes = yield call(bills.getToken, payload);
  yield put({
    type: "bills/RECEIVE_TOKEN",
    payload: tokenRes,
  });
  yield put({
    type: "global/RECEIVE_SESSION_DETAILS",
    payload: tokenRes,
  });

  if (tokenRes.error) {
    if (tokenRes.type === "WEB") {
      yield put(push("/dashboard"));
    } else {
      yield put(push("/not-found"));
    }
  }
}

export function* REQUEST_BILLERS({ payload }) {
  yield put({
    type: "bills/SET_LOADING",
    payload: true,
  });
  const { token } = payload;
  const response = yield call(bills.getBillers, token);
  if (response) {
    yield put({
      type: "bills/SET_LOADING",
      payload: false,
    });
  }
  yield put({
    type: "bills/RECEIVE_BILLERS",
    payload: response,
  });
}

export function* REQUEST_BILL_INFO({ payload }) {
  const { name, token } = payload;
  const response = yield call(bills.getBillInfo, name, token);
  yield put({
    type: "bills/RECEIVE_BILL_INFO",
    payload: response,
  });

  if (response.error) {
    yield put(goBack());
  } else {
    yield put(push("/pay-bills/bill"));
  }
}

export function* REQUEST_BILLER_FEE({ payload }) {
  yield put({
    type: "bills/SET_LOADING",
    payload: true,
  });
  const { name, amount, token } = payload;
  const response = yield call(bills.getBillerFee, name, amount, token);
  if (response) {
    yield put({
      type: "bills/SET_LOADING",
      payload: false,
    });
  }
  yield put({
    type: "bills/RECEIVE_BILLER_FEE",
    payload: response,
  });
}

export function* REQUEST_PAY_BILL({ payload }) {
  yield put({
    type: "bills/SET_TRANSACT_REQUEST",
    payload: payload,
  });
  const returnReformatField = (title) => {
    let label = "";
    const splitKey = title.split("_");

    if (splitKey.length > 1) {
      splitKey.splice(0, 1);
      const newString = splitKey.reduce((previous, current) => {
        return previous + current;
      }, "");

      label = newString;
    } else {
      label = title;
    }
    return label;
  };

  const { token, billerFee, sessionType, ...removeBillerFee } = payload;
  const res = yield call(bills.payBills, removeBillerFee, token);
  const resPayload = {
    biller: payload.billerName,
  };

  Object.keys(payload.billerFields).forEach((key) => {
    const reformattedKey = returnReformatField(key);
    const billObject = payload.billerFields;
    if (payload.billerName === "MERALCO" && reformattedKey === "method") {
      if (!res.error) {
        resPayload.transactionReferenceNo =
          res.transactionReferenceNo || "none";
      }
    } else {
      resPayload[reformattedKey] = billObject[key];
    }
  });
  if (res.error) {
    const dateTime = reformatDateAndTime(res.errorObject.updatedAt);
    yield put({
      type: "bills/SET_TRANSACT_RESPONSE",
      payload: res.errorObject,
    });

    yield put({
      type: "bills/RECEIVE_PAY_BILL",
      payload: {
        ...resPayload,
        transactionId: res.errorObject.billTransId,
        transactionDate: `${dateTime.date} ${dateTime.time}`,
        billerFee: billerFee,
        total: parseInt(billerFee) + parseInt(payload.billerFields.amount),
        result: res.error,
      },
    });
    yield put(push("/result"));
  } else {
    const dateTime = reformatDateAndTime(res.updatedAt);
    yield put({
      type: "bills/SET_TRANSACT_RESPONSE",
      payload: res,
    });
    yield put({
      type: "bills/RECEIVE_PAY_BILL",
      payload: {
        ...resPayload,
        transactionId: res.billTransId,
        transactionDate: `${dateTime.date} ${dateTime.time}`,
        billerFee: billerFee,
        total: parseFloat(billerFee) + parseFloat(payload.billerFields.amount),
        result: res.sessionStatus === "CANCELLED" ? "" : "success",
      },
    });

    if (
      res.sessionStatus !== "PENDING" &&
      res.sessionStatus !== "FOR_CONFIRMATION"
    ) {
      yield put(push("/result"));
    }
  }
}

export function* REQUEST_TRANSACTIONS({ payload }) {
  const { limit, token } = payload;
  const transactionsRes = yield call(bills.getTransactions, token, limit);
  if (transactionsRes) {
    yield put({
      type: "bills/SET_LOADING",
      payload: false,
    });
  }
  const transactions = transactionsRes.map((transactions) => {
    return {
      status: transactions.status,
      time: transactions.createdAt,
      amount: transactions.amountPaid,
      name: transactions.billerCode,
      reference: transactions.referenceId,
    };
  });
  yield put({
    type: "bills/RECEIVE_TRANSACTIONS",
    payload: transactions,
  });
}

export function* REQUEST_INQUIRE({ payload }) {
  // TODO: returnFormatField move to constants
  const returnReformatField = (title) => {
    let label = "";
    const splitKey = title.split("_");

    if (splitKey.length > 1) {
      splitKey.splice(0, 1);
      const newString = splitKey.reduce((previous, current) => {
        return previous + current;
      }, "");

      label = newString;
    } else {
      label = title;
    }
    return label;
  };

  const { token, transactionRequest, billerFee } = payload;
  const res = yield call(bills.inquire, token);
  const resPayload = {
    biller: transactionRequest.billerName,
  };

  Object.keys(transactionRequest.billerFields).forEach((key) => {
    const reformattedKey = returnReformatField(key);
    const billObject = transactionRequest.billerFields;
    if (
      transactionRequest.billerName === "MERALCO" &&
      reformattedKey === "method"
    ) {
      if (!res.error) {
        resPayload.transactionReferenceNo =
          res.transactionReferenceNo || "none";
      }
    } else {
      resPayload[reformattedKey] = billObject[key];
    }
  });
  if (res.error || res.sessionStatus === "FAILED") {
    yield put({
      type: "bills/SET_TRANSACT_RESPONSE",
      payload: res.errorObject || res,
    });

    const detailObject = res.error && res.error.details;
    let message = "";
    if (detailObject) {
      Object.keys(detailObject).forEach((key) => {
        message = detailObject[key][0].message;
      });
    } else {
      message = res.error && res.error.message;
    }
    yield put({
      type: "bills/RECEIVE_INQUIRE",
      payload: {
        ...resPayload,
        billerFee: billerFee,
        total: billerFee + transactionRequest.billerFields.amount,
        result:
          res.error ||
          (message ? message : "There is an error in processing your request."),
      },
    });
    yield put(push("/result"));
  } else {
    yield put({
      type: "bills/SET_TRANSACT_RESPONSE",
      payload: res,
    });
    yield put({
      type: "bills/RECEIVE_INQUIRE",
      payload: {
        ...resPayload,
        billerFee: billerFee,
        total:
          parseFloat(billerFee) +
          parseFloat(transactionRequest.billerFields.amount),
        result: "success",
      },
    });
    if (
      res.sessionStatus !== "PENDING" &&
      res.sessionStatus !== "FOR_CONFIRMATION"
    ) {
      yield put(push("/result"));
    }
  }
}

export default function* rootSaga() {
  yield all([takeEvery(actions.REQUEST_TOKEN, REQUEST_TOKEN)]);
  yield all([takeEvery(actions.REQUEST_BILLERS, REQUEST_BILLERS)]);
  yield all([takeEvery(actions.REQUEST_BILL_INFO, REQUEST_BILL_INFO)]);
  yield all([takeEvery(actions.REQUEST_PAY_BILL, REQUEST_PAY_BILL)]);
  yield all([takeEvery(actions.REQUEST_TRANSACTIONS, REQUEST_TRANSACTIONS)]);
  yield all([takeEvery(actions.REQUEST_BILLER_FEE, REQUEST_BILLER_FEE)]);
  yield all([takeEvery(actions.REQUEST_INQUIRE, REQUEST_INQUIRE)]);
}
