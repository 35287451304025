import { all, takeEvery, put, call } from "redux-saga/effects";
import { push } from "connected-react-router";
import { actions } from "./reducers";
import { removeLocalStorage, setLocalStorage } from "../../global/helpers";
import configs from "../../configs";
import * as login from "./services";
import { domainType } from "../../global/constants";
import theme from "../../global/theme"

export function* REQUEST_CREATE_OTP({ payload }) {
  let realm = "NEBULA"
  if (theme.types) {
    const typesDetail = theme.types[`${domainType}`];
    realm = typesDetail?.realm || "NEBULA";
  }

  yield put({
    type: "global/SET_MOBILE_NUMBER",
    payload: payload,
  });
  setLocalStorage("mobileNumber", payload, configs.config.sessionExpiry);

  yield put({
    type: "login/SET_LOADING",
    payload: true,
  });
  const reqBody = { mobileNumber: payload, realm: realm };
  const res = yield call(login.createOTP, reqBody);
  if (!res.error) {
    yield put({
      type: "login/SET_LOADING",
      payload: false,
    });
    yield put({
      type: "login/RECEIVE_CREATE_OTP",
      payload: res,
    });
    yield put(push("/otp"));
  } else {
    yield put({
      type: "login/SET_LOADING",
      payload: false,
    });
  }
}

export function* REQUEST_VERIFY_OTP({ payload }) {
  yield put({
    type: "login/SET_LOADING",
    payload: true,
  });
  const res = yield call(login.verifyOTP, payload);
  if (!res.error) {
    yield put({
      type: "global/RECEIVE_OTP_DETAILS",
      payload: res,
    });
    yield put({
      type: "login/SET_LOADING",
      payload: false,
    });
  } else {
    yield put({
      type: "login/SET_LOADING",
      payload: false,
    });
  }
}

export function* REQUEST_RESEND_OTP({ payload }) {
  yield put({
    type: "login/SET_LOADING",
    payload: true,
  });
  const res = yield call(login.resendOTP, payload);
  if (res) {
    yield put({
      type: "login/SET_LOADING",
      payload: false,
    });
  }
}

export function* REQUEST_LOGOUT_OTP({ payload }) {
  yield call(login.logoutOTP, payload);
  yield put({
    type: "global/RECEIVE_SESSION_DETAILS",
    payload: null,
  });
  yield put({
    type: "login/RECEIVE_VERIFY_OTP",
    payload: null,
  });
  removeLocalStorage("sessionDetails");
  removeLocalStorage("otpDetails");
  removeLocalStorage("mobileNumber");
  removeLocalStorage("userDetails");
  removeLocalStorage("sessionType");
  yield put(push("/login"));
}

export default function* rootSaga() {
  yield all([takeEvery(actions.REQUEST_CREATE_OTP, REQUEST_CREATE_OTP)]);
  yield all([takeEvery(actions.REQUEST_VERIFY_OTP, REQUEST_VERIFY_OTP)]);
  yield all([takeEvery(actions.REQUEST_RESEND_OTP, REQUEST_RESEND_OTP)]);
  yield all([takeEvery(actions.REQUEST_LOGOUT_OTP, REQUEST_LOGOUT_OTP)]);
}
