import React, { useEffect, useState } from "react";
import { ConnectedRouter, push } from "connected-react-router";
import { connect } from "react-redux";
import {
  Background,
  Content,
  Logo,
  FilledButton,
  ButtonContainer,
} from "./styles";
import InputDetail from "../../components/common/InputDetail";
import * as messages from "./messages";

const mapStateToProps = ({ dispatch, loginReducer, globalReducer }) => ({
  dispatch,
  loginStore: loginReducer,
  globalStore: globalReducer,
});

const LoginScreen = ({ dispatch, loginStore, globalStore }) => {
  const [mobileNumber, setMobileNumber] = useState(null);
  useEffect(() => {
    dispatch({
      type: "global/CURRENT_ENDPOINT",
      payload: "LOGIN",
    });
    if (globalStore.otpDetails) {
      window.location.reload();
    }
  }, []);

  return (
    <>
      <Background>
        <Content>
          <Logo></Logo>
          <InputDetail
            subtitle={messages.numberSubtitle}
            value={mobileNumber}
            onChange={(e) => {
              setMobileNumber(e.target.value);
            }}
            type="number"
          />

          <ButtonContainer>
            <FilledButton
              disabled={
                !mobileNumber ||
                !(mobileNumber && mobileNumber.length === 11) ||
                loginStore.loading
              }
              variant="contained"
              onClick={() => {
                dispatch({
                  type: "login/REQUEST_CREATE_OTP",
                  payload: mobileNumber,
                });
              }}
            >
              {loginStore.loading ? messages.loading : messages.sendButton}
            </FilledButton>
            {/* <OutlinedButton variant="outlined">SIGN UP</OutlinedButton> */}
          </ButtonContainer>
        </Content>
      </Background>
    </>
  );
};

export default connect(mapStateToProps)(LoginScreen);
