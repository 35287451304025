import styled from "styled-components";
import { Button } from "@mui/material/";
import { styled as muiStyled } from "@mui/material/styles/";
import { primaryColor, secondaryColor } from "../../../global/constants";

export const TitleBar = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: white;
  padding-left: 15px;
  height: 50px;
`;

export const Content = styled.div`
  display: flex;
  color: white;
  flex-direction: column;
  justify-content: space-between;
  height: 90vh;
  overflow-y: auto;
`;

// TODO: Create global styles for Green buttons
export const ConfirmButton = muiStyled(Button)({
  textAlign: "center",
  textTransform: "none",
  fontSize: 16,
  padding: "6px 12px",
  lineHeight: 1.5,
  backgroundColor: primaryColor,
  border: "none",
  height: "50px",
  width: "100%",
  marginBottom: "10px",
  "&:hover": {
    backgroundColor: secondaryColor,
    boxShadow: "none",
  },
  "&:active": {
    boxShadow: "none",
    backgroundColor: secondaryColor,
  },
});

export const ButtonBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
`;

export const ModalButtonGreen = muiStyled(Button)({
  textAlign: "center",
  textTransform: "none",
  fontSize: 16,
  padding: "6px 12px",
  height: "40px",
  backgroundColor: primaryColor,
  border: "none",
  width: "40%",
});

export const ModalButtonRed = muiStyled(Button)({
  textAlign: "center",
  textTransform: "none",
  fontSize: 16,
  padding: "6px 12px",
  height: "40px",
  backgroundColor: "#e74c3c",
  border: "none",
  width: "40%",
});

export const ProceedBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 20px;
`;

export const CountDown = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
`;

export const Timer = styled.div`
  font-size: 40px;
  color: ${(props) => (props.time < 4 ? primaryColor : `black`)};
`;

export const ContentContainer = styled.div`
  padding: 10px 20px 10px 20px;
  background-color: white;
  border-radius: 20px;
`;

export const ButtonContent = styled.div`
  padding: 5% 10% 0 10%;
`;

export const Container = styled.div`
  padding: 5% 10% 0 10%;
`;

export const HanepmartConfirmSpiel = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: black;
  font-style: italic;
  font-size: 15px;
  margin-bottom: 10px;
`;
