import styled from "styled-components";
import { Logout } from "@mui/icons-material/";

export const NavBar = styled.div`
  background-color: white;
  display: flex;
  width: 100%;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;
`;

export const Logo = styled.img`
  margin-left: 5%;
  margin-top: 10px;
  margin-bottom: 10px;
  width: 100px;
  object-fit: scale-down;
  overflow: hidden;
`;

export const LogoutIcon = styled(Logout)`
  margin-right: 5%;
  font-size: 25px;
  cursor: pointer;
`;

export const Space = styled.div`
  width: 50px;
`;
