import styled from "styled-components";
import { Button } from "@mui/material/";
import { styled as muiStyled } from "@mui/material/styles/";
import { primaryColor, secondaryColor } from "../../global/constants";

export const Content = styled.div`
  padding: 0px 15px 15px 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 88vh;
  background-color: white;
  overflow-y: auto;
`;

export const ProceedButton = muiStyled(Button)({
  textAlign: "center",
  textTransform: "none",
  fontSize: 16,
  marginTop: "15px",
  padding: "6px 12px",
  height: "50px",
  lineHeight: 1.5,
  backgroundColor: primaryColor,
  border: "none",
  width: "100%",
  "&:hover": {
    backgroundColor: secondaryColor,
    boxShadow: "none",
  },
  "&:active": {
    boxShadow: "none",
    backgroundColor: secondaryColor,
  },
});

export const Title = styled.div`
  margin: auto;
  width: 80%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  padding: 15px 0px 0px 0px;
`;

export const Red = styled.div`
  padding-top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  color: red;
`;
