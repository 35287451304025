import styled from "styled-components";

export const GridBox = styled.div`
  overflow-y: auto;
  overflow-x: hidden;
  padding: 15px;
  display: flex;
  color: black;
  flex-direction: column;
  justify-content: space-between;
`;
