import React from "react";
import { BackButtonContainer, Back } from "./styles";
import { connect } from "react-redux";
import { ArrowBack } from "@mui/icons-material";
import { goBack, push } from "connected-react-router";

const mapStateToProps = ({ dispatch, router }) => ({
  dispatch,
  router,
});

const BackButton = ({ dispatch, title }) => {
  return (
    <BackButtonContainer>
      <ArrowBack
        onClick={() => {
          if (title === "Transactions") {
            dispatch(push("/dashboard"));
          } else {
            dispatch(goBack());
          }
        }}
      />
      <Back> Back </Back>
    </BackButtonContainer>
  );
};

export default connect(mapStateToProps)(BackButton);
