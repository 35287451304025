import { all, takeEvery, put, call } from "redux-saga/effects";
import { push } from "connected-react-router";
import { actions } from "./reducers";
import * as transaction from "./services";

export function* REQUEST_TRANSACTIONS({ payload }) {
  yield put({
    type: "transactions/SET_LOADING",
    payload: true,
  });
  let res = {};

  res = yield call(transaction.getTransactions, payload);
  // TODO: Error handling
  if (res) {
    yield put({
      type: "transactions/SET_LOADING",
      payload: false,
    });

    const finalTransactions = () => {
      if (payload.eservice === "Bills Payment") {
        return res.filter((t) => {
          return t.billerCode;
        });
      } else {
        return res;
      }
    };
    yield put({
      type: "transactions/RECEIVE_TRANSACTIONS",
      payload: finalTransactions(),
    });
  }
}

export default function* rootSaga() {
  yield all([takeEvery(actions.REQUEST_TRANSACTIONS, REQUEST_TRANSACTIONS)]);
}
