import React, { useEffect } from "react";
import { connect } from "react-redux";
import {
  BillersButton,
  RightIcon,
  BillersContent,
  Empty,
  CategoryContent,
  Categories,
  TitleBar,
} from "./styles";
import FooterContainer from "../../common/FooterContainer";
import TitleHeader from "../../common/TitleHeader";
import theme from "../../../global/theme";
import { domainType } from "../../../global/constants";

const mapStateToProps = ({ dispatch, billsReducers, globalReducer }) => ({
  dispatch,
  billsStore: billsReducers,
  globalStore: globalReducer,
});

const BillersList = ({ dispatch, billsStore, globalStore }) => {
  useEffect(() => {
    dispatch({
      type: "global/CURRENT_ENDPOINT",
      payload: "CHOOSE BILLERS",
    });
    dispatch({
      type: "bills/SET_BILL_DETAILS",
      payload: {},
    });
  }, [dispatch]);

  useEffect(() => {
    if (
      billsStore.sessionDetails.sessionToken &&
      billsStore.billers.length < 1
    ) {
      dispatch({
        type: "bills/REQUEST_BILLERS",
        payload: {
          token: billsStore.sessionDetails.sessionToken,
        },
      });
    }
  }, [billsStore.sessionDetails.sessionToken]);

  const returnFilteredBillers = () => {
    const categoryObj = billsStore.billers.filter((bill) => {
      return bill.name.toUpperCase() === billsStore.category.toUpperCase();
    });
    return categoryObj[0].billers;
  };

  const filterNonHiddenBillers = (billers) => {
    if (theme.types) {
      const typesDetail = theme.types[`${domainType}`];
      const hiddenBillers = typesDetail?.hiddenBillers || [];
      return billers.filter((bill) => {
        return !hiddenBillers.includes(bill.code);
      });
    } else {
      return billers;
    }
  };

  const isDisabled = (name) => {
    if (theme.types) {
      const typesDetail = theme.types[`${domainType}`];
      const disabledBillers = typesDetail?.disabledBillers || [];
      return disabledBillers.includes(name);
    } else {
      return false;
    }
  };

  const chooseBiller = (bills) => {
    dispatch({
      type: "bills/REQUEST_BILL_INFO",
      payload: {
        name: bills.code,
        token: billsStore.sessionDetails.sessionToken,
      },
    });
  };

  return (
    <BillersContent>
      <div>
        <TitleHeader
          title={globalStore.pageTitle}
          type={
            globalStore.sessionDetails && globalStore.sessionDetails.sessionType
          }
        />

        {billsStore.loading ? (
          <Empty> Loading ... </Empty>
        ) : (
          <CategoryContent>
            <TitleBar>Choose Biller</TitleBar>

            <Categories>
              {billsStore.billers &&
              billsStore.billers.length > 0 &&
              returnFilteredBillers().length > 0 ? (
                filterNonHiddenBillers(returnFilteredBillers()).map((bills) => {
                  return (
                    <BillersButton
                      disabled={isDisabled(bills.code)}
                      key={bills.code}
                      onClick={() => {
                        chooseBiller(bills);
                      }}
                    >
                      {bills.name}
                      <RightIcon />
                    </BillersButton>
                  );
                })
              ) : (
                <Empty>No Bills available</Empty>
              )}
            </Categories>
          </CategoryContent>
        )}
      </div>
      <FooterContainer />
    </BillersContent>
  );
};

export default connect(mapStateToProps)(BillersList);
