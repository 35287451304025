import React from "react";
import PaymentDetail from "../../common/PaymentDetail";

const SignUpSummary = ({ detail }) => {
  return (
    <>
      <PaymentDetail title="First Name:" value={detail.firstName} />
      <PaymentDetail title="Last Name:" value={detail.lastName} />
      <PaymentDetail title="Mobile Number:" value={detail.mobileNumber} />
      <PaymentDetail title="Email:" value={detail.email} />
      <PaymentDetail title="Full Address:" value={detail.fullAddress} />
      <PaymentDetail
        title="Referral Code:"
        value={detail.referralCode || "None"}
      />
      <PaymentDetail title="ID type:" value={detail.validId} />
      <PaymentDetail title="ID Number:" value={detail.idNumber} />
    </>
  );
};

export default SignUpSummary;
