import styled from "styled-components";
import { Button } from "@mui/material/";
import { styled as muiStyled } from "@mui/material/styles/";
import { primaryColor, secondaryColor } from "../../../global/constants";

export const TransactionsContainer = styled.div`
  flex-direction: column;
  display: flex;
  justify-content: space-between;
  color: white;
`;

export const TransactionContent = styled.div`
  display: flex;
  flex-direction: column;
  height: 90vh;
  padding: 5% 10% 0 10%;
`;

export const Footer = styled.div`
  display: flex;
  justify-content: center;
  padding: 10px 0px 10px 0px;
`;

export const DoneButton = muiStyled(Button)({
  textAlign: "center",
  textTransform: "none",
  fontSize: 16,
  padding: "6px 12px",
  lineHeight: 1.5,
  backgroundColor: primaryColor,
  border: "none",
  width: "90%",
  color: "white",
  "&:hover": {
    backgroundColor: secondaryColor,
    boxShadow: "none",
  },
  "&:active": {
    boxShadow: "none",
    backgroundColor: secondaryColor,
  },
});

export const TransactionTitle = styled.p`
  font-size: 17px;
  padding: 0px;
  margin: 0px;
  padding-top: 20px;
  padding-bottom: 25px;
`;

export const TransactionDetail = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 20px;
`;

export const LeftDetail = styled.div`
  display: flex;
  flex-direction: column;
`;

export const RightDetail = styled.div`
  display: flex;
  flex-direction: column;
  text-align: right;
`;

export const DateDetail = styled.p`
  padding: 0px 0px 5px 0px;
  margin: 0px;
  font-size: 13px;
`;

export const ReferenceDetail = styled.p`
  padding: 10px 0px 10px 0px;
  margin: 0px;
  font-size: 13px;
`;

export const SuccessDetail = styled.p`
  padding: 0px 0px 5px 0px;
  margin: 0px;
  color: ${primaryColor};
  font-size: 15px;
`;

export const FailedDetail = styled.p`
  color: #e74c3c;
  padding: 0px 0px 5px 0px;
  margin: 0px;
  font-size: 15px;
`;

export const AmountDetail = styled.p`
  padding: 10px 0px 10px 0px;
  margin: 0px;
  font-size: 15px;
`;

export const NeutralDetail = styled.p`
  color: black;
  padding: 0px 0px 5px 0px;
  margin: 0px;
  font-size: 15px;
`;
