import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  Background,
  WelcomeContainer,
  Container,
  User,
  CircleButton,
  DashboardContainer,
  GridItem,
  Service,
  ServiceName,
  Img,
} from "./styles";
import { push } from "connected-react-router";
import AvailableBalance from "../../components/common/AvailableBalance";
import { buttons } from "./constants";
import { getLocalStorage, setLocalStorage } from "../../global/helpers";
import { domainType } from "../../global/constants";
import FooterContainer from "../../components/common/FooterContainer";
import configs from "../../configs";
import theme from "../../global/theme";

const mapStateToProps = ({ dispatch, globalReducer }) => ({
  dispatch,
  globalStore: globalReducer,
});

const ServiceButton = ({ name, icon, handleClick }) => {
  return (
    <Service>
      <CircleButton onClick={handleClick}>
        <Img img={icon} />
      </CircleButton>
      <ServiceName>{name}</ServiceName>
    </Service>
  );
};

const filterButtons = (constantList) => {
  if (theme.types) {
    const typesDetail = theme.types[`${domainType}`];
    const hiddenMenus = typesDetail?.hiddenMenus || [
      "Cignal TV",
      "Gaming Epins",
    ];
    return constantList.filter((button) => {
      return !hiddenMenus.includes(button.name);
    });
  } else {
    return constantList;
  }
};

const DashboardScreen = ({ dispatch, globalStore }) => {
  const [mobileNumber, setMobileNumber] = useState(globalStore.mobileNumber);
  const [userDetails, setUserDetails] = useState(
    globalStore.sessionDetails
      ? {
          name: globalStore.sessionDetails.name,
          balance: globalStore.sessionDetails.walletBalance,
        }
      : { name: "Anon", balance: null }
  );
  function clearOutputsOnTransactResponse() {
    dispatch({
      type: "global/SET_NULL_OUTPUTS",
    });

    dispatch({
      type: "global/REQUEST_CREATE_SESSION",
      payload: {
        otpId: globalStore.otpDetails.otpId,
        phoenixId: globalStore.otpDetails.phoenixId,
      },
    });
  }
  useEffect(() => {
    clearOutputsOnTransactResponse();
    dispatch({
      type: "global/CURRENT_ENDPOINT",
      payload: "DASHBOARD",
    });
    setMobileNumber(getLocalStorage("mobileNumber"));
    setUserDetails(getLocalStorage("userDetails"));
  }, []);

  useEffect(() => {
    if (!getLocalStorage("sessionType") === "WEB") {
      setLocalStorage(
        "sessionType",
        globalStore.sessionDetails.sessionType,
        configs.config.sessionExpiry
      );
    }
  }, []);

  useEffect(() => {
    if (globalStore.sessionDetails.error) {
      dispatch({
        type: "global/REQUEST_CREATE_SESSION",
        payload: {
          otpId: globalStore.otpDetails.otpId,
          phoenixId: globalStore.otpDetails.phoenixId,
        },
      });
    }
  }, [globalStore.sessionDetails]);

  return (
    <>
      <Background>
        <Container>
          <WelcomeContainer>
            Welcome to{" "}
            {theme.types[`${domainType}`].id === "ASKANI"
              ? "Cignal Portal"
              : "HanepBuhay"}
            <User>{userDetails.name}!</User>
          </WelcomeContainer>
          <AvailableBalance
            balance={userDetails.balance}
            mobileNumber={mobileNumber}
          />
          <DashboardContainer>
            {globalStore.sessionDetails &&
              !globalStore.loading &&
              filterButtons(
                buttons(
                  globalStore.sessionDetails.sessionId,
                  globalStore.sessionDetails.userId
                )
              ).map((button) => (
                <GridItem>
                  <ServiceButton
                    name={button.name}
                    icon={button.icon}
                    handleClick={() => {
                      if (
                        button.name === "Hanep Games" ||
                        button.name === "Hanep Scanner" ||
                        button.name === "Customer Support"
                      ) {
                        window.open(button.route, "_blank");
                      } else {
                        dispatch(push(button.route));
                      }
                    }}
                  />
                </GridItem>
              ))}
          </DashboardContainer>
        </Container>
        <FooterContainer />
      </Background>
    </>
  );
};

export default connect(mapStateToProps)(DashboardScreen);
