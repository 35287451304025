import apiClient from "../../axios";
import { toastError } from "../../global/constants";

export async function signUp(payload) {
  try {
    const response = await apiClient.post("/signup", payload);
    return response.data.data;
  } catch (e) {
    const message = e.response.data.error.message;
    const code = e.response.data.error.code;
    toastError(`[${code}] ${message}`);
    return { error: message };
  }
}
