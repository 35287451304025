import apiClient from "../../axios";
import { toastError } from "../../global/constants";

export async function getToken(payload) {
  try {
    const response = await apiClient.post("/cignal/get-token", payload);
    return response.data.data;
  } catch (e) {
    const message = e.response.data.error.message;
    const code = e.response.data.error.code;
    toastError(`[${code}] ${message}`);
    return { error: message };
  }
}

export async function getProducts(token, category) {
  try {
    const response = await apiClient.get(`/cignal/list?category=${category}`, {
      headers: {
        Authorization: token,
      },
    });
    return response.data.data.products;
  } catch (e) {
    const message = e.response.data.error.message;
    const code = e.response.data.error.code;
    toastError(`[${code}] ${message}`);
  }
}

export async function getBillInfo(token) {
  try {
    const response = await apiClient.get(`/cignal/biller/CGNAL`, {
      headers: {
        Authorization: token,
      },
    });
    return response.data.data.biller;
  } catch (e) {
    const message = e.response.data.error.message;
    const code = e.response.data.error.code;
    toastError(`[${code}] ${message}`);
    return { error: message };
  }
}

export async function payTransact(payload, token) {
  try {
    const response = await apiClient.post(`/cignal/transact/load`, payload, {
      headers: {
        Authorization: token,
      },
    });
    return response.data.data;
  } catch (e) {
    const message = e.response.data.error.message;
    const code = e.response.data.error.code;
    toastError(`[${code}] ${message}`);
    return { error: message };
  }
}

export async function payTransactKit(payload, token) {
  try {
    const response = await apiClient.post(`/cignal/transact/kit`, payload, {
      headers: {
        Authorization: token,
      },
    });
    return response.data.data;
  } catch (e) {
    const message = e.response.data.error.message;
    const code = e.response.data.error.code;
    toastError(`[${code}] ${message}`);
    return { error: message };
  }
}

export async function applyPostpaid(payload, token) {
  try {
    const response = await apiClient.post(`/cignal/postpaid`, payload, {
      headers: {
        Authorization: token,
      },
    });
    return response.data.data;
  } catch (e) {
    const message = e.response.data.error.message;
    const code = e.response.data.error.code;
    toastError(`[${code}] ${message}`);
    return { error: message };
  }
}

export async function getBillerFee(name, amount, token) {
  try {
    const response = await apiClient.get(
      `/cignal/biller-fee/${name}?amount=${amount}`,
      {
        headers: {
          Authorization: token,
        },
      }
    );
    return response.data.data;
  } catch (e) {
    const message = e.response.data.error.message;
    const code = e.response.data.error.code;
    toastError(`[${code}] ${message}`);
    return { error: message };
  }
}

export async function payTransactBill(payload, token) {
  try {
    const response = await apiClient.post(`/cignal/transact/bill`, payload, {
      headers: {
        Authorization: token,
      },
    });
    return response.data.data;
  } catch (e) {
    const detailObject = e.response.data.error.details;
    let message = "";
    let code = "";
    if (detailObject) {
      Object.keys(detailObject).forEach((key, index) => {
        code = detailObject[key][0].code;
        message = detailObject[key][0].message;
      });
    } else {
      message = e.response.data.error.message;
      code = e.response.data.error.code;
    }
    toastError(`[${code}] ${message}`);
    return { error: message };
  }
}

export async function getTransactions(token, limit) {
  try {
    const response = await apiClient.get(
      `/cignal/transactions/load?limit=${limit}`,
      {
        headers: {
          Authorization: token,
        },
      }
    );
    return response.data.data;
  } catch (e) {
    const message = e.response.data.error.message;
    const code = e.response.data.error.code;
    toastError(`[${code}] ${message}`);
  }
}
export async function getTransactionsBill(token, limit) {
  try {
    const response = await apiClient.get(
      `/cignal/transactions/bill?limit=${limit}`,
      {
        headers: {
          Authorization: token,
        },
      }
    );
    return response.data.data.transactions;
  } catch (e) {
    const message = e.response.data.error.message;
    const code = e.response.data.error.code;
    toastError(`[${code}] ${message}`);
  }
}
