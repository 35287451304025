export const actions = {
  REQUEST_TOKEN: "egrocery/REQUEST_TOKEN",
  RECEIVE_TOKEN: "egrocery/RECEIVE_TOKEN",
  REQUEST_BUY_GROCERY: "egrocery/REQUEST_BUY_GROCERY",
  RECEIVE_BUY_GROCERY: "egrocery/RECEIVE_BUY_GROCERY",
  SET_GROCERY_DETAILS: "egrocery/SET_GROCERY_DETAILS",
  SET_CART_DETAILS: "egrocery/SET_CART_DETAILS",
  REQUEST_TRANSACTIONS: "egrocery/REQUEST_TRANSACTIONS",
  RECEIVE_TRANSACTIONS: "egrocery/RECEIVE_TRANSACTIONS",
  REQUEST_PRODUCTS: "egrocery/REQUEST_PRODUCTS",
  RECEIVE_PRODUCTS: "egrocery/RECEIVE_PRODUCTS",
  SET_LOADING: "egrocery/SET_LOADING",
};

const initialState = {
  cartDetails: [],
  groceryDetails: {},
  sessionDetails: {},
  products: [],
  transactions: [],
  loading: true,
};

export default function eGroceryReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_CART_DETAILS:
      return { ...state, cartDetails: action.payload };
    case actions.SET_GROCERY_DETAILS:
      return { ...state, groceryDetails: action.payload };
    case actions.RECEIVE_BUY_GROCERY:
      return { ...state, groceryDetails: action.payload };
    case actions.RECEIVE_TRANSACTIONS:
      return { ...state, transactions: action.payload };
    case actions.RECEIVE_TOKEN:
      return { ...state, sessionDetails: action.payload };
    case actions.RECEIVE_PRODUCTS:
      const reformattedProducts = action.payload.map((product) => {
        return {
          sku: product.sku,
          name: product.name,
          description: product.shortDescription,
          priceType: product.priceType,
          image: product.image,
          basePrice: product.sellingPrice,
        };
      });
      return { ...state, products: reformattedProducts };
    case actions.SET_LOADING:
      return { ...state, loading: action.payload };
    default:
      return state;
  }
}
