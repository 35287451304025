import styled from "styled-components";
import { styled as muiStyled } from "@mui/material/styles/";
import { CheckCircle, Cancel } from "@mui/icons-material/";
import { secondaryColor } from "../../../global/constants";

export const PaymentSuccessContainer = styled.div`
  flex-direction: column;
  display: flex;
  justify-content: space-between;
  color: white;
  height: 90vh;
  width: 100%;
  overflow-y: auto;
`;
export const SuccessContent = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  color: white;
`;

export const ResultContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const ResultContent = styled.div`
  width: 75%;
`;

export const Footer = styled.div`
  display: flex;
  justify-content: center;
  text-align: center;
  flex-direction: column;
  font-size: 13px;
  padding: 20px 0px 10px 0px;
  line-height: 20px;
`;

export const ResultTitleContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 3vh;
  align-items: center;
`;

export const CircleIcon = muiStyled(CheckCircle)({
  fontSize: 40,
  paddingRight: 10,
  color: secondaryColor,
});

export const CancelIcon = muiStyled(Cancel)({
  paddingRight: 10,
  fontSize: 40,
  color: "#E74C3C",
});

export const ResultTitle = styled.p`
  font-size: 17px;
  padding: 0px;
  margin: 0px;
`;
export const PaymentContent = styled.div`
  margin-top: 25px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const PaymentDetail = styled.div`
  width: 100%;
  display: flex;
  font-size: 13px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 25px;
`;

export const RecentTransactions = styled.div`
  font-size: 13px;
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  flex-direction: column;
`;

export const TransactionsLink = styled.p`
  margin: 10px 0px 0px 0px;
  padding: 0px;
  color: #0080ff;
  cursor: pointer;
`;

export const ErrorMessage = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 5%;
  color: #e74c3c;
`;

export const NotifMessage = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: #7a7a7a;
  font-size: 15px;
`;

export const Left = styled.div`
  text-align: left;
`;
export const Right = styled.div`
  text-align: right;
`;

export const LineBreak = styled.hr`
  color: black;
  width: 100%;
`;

export const DeliveryTitle = styled.div`
  margin-bottom: 10px;
  margin-top: 15px;
`;

export const Logo = styled.img`
  margin-right: 5%;
  margin-top: 10px;
  margin-bottom: 10px;
  width: 70px;
  height: 70px;
  object-fit: scale-down;
  overflow: hidden;
`;

export const BillCaption = styled.div`
  padding-top: 10px;
  width: 100%;
  font-size: 15px;
  font-weight: bold;
  text-align: center;
`;

export const Note = styled.div`
  padding-top: 10px;
  width: 100%;
  font-size: 12px;
  text-align: center;
`;

export const Bill = styled.div`
  width: 75%;
`;

export const MiscContent = styled.div`
  height: 100%;
`;

export const BottomContent = styled.div`
  padding: 5% 10% 0 10%;
`;
