import styled from "styled-components";
import { Button } from "@mui/material/";
import { styled as muiStyled } from "@mui/material/styles/";
import { primaryColor, secondaryColor } from "../../../global/constants";

export const GridItem = styled.div`
  border: 2px solid;
  border-radius: 5px;
  background-color: white;
  border-color: ${(props) => (props.isSelected ? primaryColor : `#f2f2f2`)};
  padding: 5%;
  display: flex;
  height: 35vh;
  font-size: 14px;
  justify-content: center;
  overflow-y: hidden;
  align-items: center;
  flex-direction: column;
  &:hover {
    border-color: ${primaryColor};
  }
  &:active {
    border-color: ${primaryColor};
  }
  @media (max-width: 380px), (max-height: 650px) {
    height: ${(props) => (props.noDescription ? "52vh " : `61vh`)};
  }
  @media (max-width: 320px), (max-height: 480px) {
    height: ${(props) => (props.noDescription ? "40vh " : `70vh`)};
  }
  @media (max-width: 290px), (max-height: 500px) {
    height: ${(props) => (props.noDescription ? "35vh " : `90vh`)};
  }
`;

export const GridImage = styled.img`
  width: 97%;
  object-fit: contain;
  height: 50%;
  overflow: hidden;
  padding-bottom: 5px;
`;

export const Price = styled.div`
  font-size: 16px;
  font-weight: bold;
  padding-top: 5px;
  width: 100%;
`;

export const Description = styled.div`
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 15px;
  width: calc(100%);
  text-overflow: ellipsis;
  overflow: hidden;
  height: 60px
  display: block;
`;

export const ItemName = styled.div`
  height: ${(props) => (props.noDescription ? "70px " : `30px`)};
  font-size: 15px;
  @media (max-width: 350px) {
    height: ${(props) => (props.noDescription ? "80px" : `40px`)};
  }
  @media (max-width: 300px) {
    height: ${(props) => (props.noDescription ? "95px" : `60px`)};
  }
`;

export const AddButton = muiStyled(Button)({
  textAlign: "center",
  textTransform: "none",
  fontSize: 14,
  color: "white",
  marginTop: "10px",
  padding: "6px 12px",
  height: "40px",
  backgroundColor: primaryColor,
  border: "none",
  width: "100%",
  "&:hover": {
    backgroundColor: secondaryColor,
    boxShadow: "none",
  },
  "&:active": {
    boxShadow: "none",
    backgroundColor: secondaryColor,
  },
});

export const QuantityButton = muiStyled(Button)({
  textAlign: "center",
  textTransform: "none",
  fontSize: 14,
  color: "white",
  padding: "6px 12px",
  height: "32px",
  backgroundColor: primaryColor,
  border: "none",
  width: "100%",
  "&:hover": {
    backgroundColor: secondaryColor,
    boxShadow: "none",
  },
  "&:active": {
    boxShadow: "none",
    backgroundColor: secondaryColor,
  },
});

export const QuantityContainer = styled.div`
  margin-top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 35px;
  background-color: ${primaryColor};
  border-radius: 5px;
`;

export const Quantity = styled.div`
  display: flex;
  padding: 5px;
`;
