import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import {
  Content,
  ProceedButton,
  ButtonContent,
  FieldsContent,
  Wait,
  Loading,
  Red,
} from "./styles";
import { push } from "connected-react-router";
import { CircularProgress } from "@mui/material/";
import InputDetail from "../../components/common/InputDetail";
import SelectDetail from "../../components/common/SelectDetail";
import { useLocation } from "react-router";
import queryString from "query-string";
import FooterContainer from "../../components/common/FooterContainer";
import TitleHeader from "../../components/common/TitleHeader";
import { checkValidMobile } from "../../global/helpers";

const mapStateToProps = ({ dispatch, gamingReducer, globalReducer }) => ({
  dispatch,
  gamingStore: gamingReducer,
  globalStore: globalReducer,
});

const GamingEpinsScreen = ({ dispatch, gamingStore, globalStore }) => {
  const location = useLocation();
  const params = queryString.parse(location.search);

  const [gamingDetails, setGamingDetails] = useState({
    mobileNumber: gamingStore.payDetails.mobileNumber,
    amount: gamingStore.payDetails.amount,
  });
  const [error, setError] = useState(false);

  const onProceed = () => {
    if (!checkValidMobile(gamingDetails.mobileNumber)) {
      setError("Invalid mobile number");
    } else {
      dispatch({
        type: "gaming/SET_PAY_DETAILS",
        payload: {
          ...gamingDetails,
          userId: gamingStore.sessionDetails.userId,
          sessionId: gamingStore.sessionDetails.sessionId,
        },
      });
      dispatch(push("/summary"));
    }
  };

  const returnAmount = (products, name) => {
    const product = products.filter((product) => {
      return name === product.name;
    });
    return product[0].basePrice;
  };

  const returnSku = (products, name) => {
    const product = products.filter((product) => {
      return name === product.name;
    });
    return product[0].sku;
  };

  const returnDiscount = (products, name) => {
    const product = products.filter((product) => {
      return name === product.name;
    });
    return product[0].basePrice - product[0].discountedPrice;
  };

  useEffect(() => {
    dispatch({
      type: "global/CURRENT_ENDPOINT",
      payload: "GAMING EPINS",
    });
    dispatch({
      type: "gaming/REQUEST_TOKEN",
      payload: {
        userId: params.userId,
        sessionId: params.sessionId,
      },
    });
  }, [dispatch, params.userId, params.sessionId]);

  useEffect(() => {
    if (gamingStore.sessionDetails.sessionToken) {
      dispatch({
        type: "gaming/REQUEST_PRODUCTS",
        payload: {
          token: gamingStore.sessionDetails.sessionToken,
        },
      });
    }
  }, [gamingStore.sessionDetails.sessionToken, dispatch]);

  return (
    <>
      <Content>
        <div>
          <TitleHeader
            title={globalStore.pageTitle}
            type={
              globalStore.sessionDetails &&
              globalStore.sessionDetails.sessionType
            }
          />
          <FieldsContent>
            {
              <InputDetail
                title={"Mobile Number"}
                subtitle={"Enter Mobile Number"}
                value={gamingDetails.mobileNumber}
                onChange={(e) => {
                  setGamingDetails({
                    ...gamingDetails,
                    mobileNumber: e.target.value,
                  });
                }}
                type="number"
              />
            }
            {gamingDetails.mobileNumber !== "" &&
              gamingDetails.mobileNumber &&
              gamingDetails.mobileNumber.length === 11 &&
              gamingStore.products &&
              gamingStore.products.length > 0 && (
                <SelectDetail
                  title="Select Products"
                  subtitle="Choose your Product"
                  options={gamingStore.products}
                  menuTitle="name"
                  menuSubtitle="description"
                  onChange={(e) => {
                    setGamingDetails({
                      ...gamingDetails,
                      product: returnSku(gamingStore.products, e.target.value),
                      amount: returnAmount(
                        gamingStore.products,
                        e.target.value
                      ),
                      discount: returnDiscount(
                        gamingStore.products,
                        e.target.value
                      ),
                    });
                  }}
                  optionSubtitle
                />
              )}
            {gamingStore.loading && (
              <Loading>
                <CircularProgress />
                <Wait>Please wait...</Wait>
              </Loading>
            )}
            {error && <Red> {error}</Red>}
          </FieldsContent>

          <ButtonContent>
            <ProceedButton
              variant="contained"
              onClick={() => {
                onProceed();
              }}
              disabled={
                !(
                  gamingDetails.mobileNumber &&
                  gamingDetails.mobileNumber.length === 11
                ) || !gamingDetails.product
              }
            >
              Proceed
            </ProceedButton>
          </ButtonContent>
        </div>
        <FooterContainer />
      </Content>
    </>
  );
};

export default connect(mapStateToProps)(GamingEpinsScreen);
