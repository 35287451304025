export const actions = {
  CURRENT_ENDPOINT: "global/CURRENT_ENDPOINT",
  BILLS_FLAG: "global/BILLS_FLAG",
  REQUEST_GENERAL_INQUIRE: "global/REQUEST_GENERAL_INQUIRE",
  RECEIVE_GENERAL_INQUIRE: "global/RECEIVE_GENERAL_INQUIRE",
  RECEIVE_TRANSACTION_OUTPUT: "global/RECEIVE_TRANSACTION_OUTPUT",
  REQUEST_CREATE_SESSION: "global/REQUEST_CREATE_SESSION",
  RECEIVE_SESSION_DETAILS: "global/RECEIVE_SESSION_DETAILS",
  SET_LOADING: "global/SET_LOADING",
  RECEIVE_OTP_DETAILS: "global/RECEIVE_OTP_DETAILS",
  SET_NULL_OUTPUTS: "global/SET_NULL_OUTPUTS",
  SET_IS_SESSION_FETCHED: "global/SET_SESSION_FETCHED",
  SESSION_OFFLINE: "global/SESSION_OFFLINE",
  SET_MOBILE_NUMBER: "global/SET_MOBILE_NUMBER",
};

const initialState = {
  pageTitle: "",
  billsFlag: false,
  transactResponse: null,
  sessionDetails: null,
  otpDetails: null,
  loading: false,
  isSessionFetched: false,
  mobileNumber: null,
};

export default function globalReducer(state = initialState, action) {
  switch (action.type) {
    case actions.CURRENT_ENDPOINT:
      return { ...state, pageTitle: action.payload };
    case actions.BILLS_FLAG:
      return { ...state, billsFlag: action.payload };
    case actions.RECEIVE_GENERAL_INQUIRE:
      return { ...state, inquireResponse: action.payload };
    case actions.RECEIVE_TRANSACTION_OUTPUT:
      return { ...state, transactResponse: action.payload };
    case actions.RECEIVE_SESSION_DETAILS:
      return {
        ...state,
        sessionDetails: action.payload,
        isSessionFetched: true,
      };
    case actions.SET_LOADING:
      return { ...state, loading: action.payload };
    case actions.RECEIVE_OTP_DETAILS:
      return { ...state, otpDetails: action.payload };
    case actions.SET_IS_SESSION_FETCHED:
      return { ...state, isSessionFetched: action.payload };
    case actions.SESSION_OFFLINE:
      return { ...state, isSessionFetched: true, otpDetails: null };
    case actions.SET_MOBILE_NUMBER:
      return { ...state, mobileNumber: action.payload };
    default:
      return state;
  }
}
