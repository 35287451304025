import React, { useState } from "react";
import { connect } from "react-redux";
import { Content, ProceedButton, CheckboxContainer } from "./styles";
import { Checkbox } from "@mui/material/";
import { push } from "connected-react-router";
import InputDetail from "../../common/InputDetail";
import SelectDetail from "../../common/SelectDetail";
import UploadImageDetail from "../../common/UploadImageDetail";

const mapStateToProps = ({ dispatch, signUpReducer }) => ({
  dispatch,
  signUpStore: signUpReducer,
});

const SignUpIDFields = ({ dispatch, signUpStore }) => {
  const [idDetails, setIdDetails] = useState(signUpStore.idDetails);
  const [checked, setChecked] = useState(false);
  const validIdsOptions = [
    "Driver's License",
    "Passport",
    "Philhealth ID",
    "TIN ID",
    "UMID",
    "Postal ID",
    "Voter’s ID",
    "PRC ID",
    "Senior Citizen ID",
    "OFW ID",
  ];

  const onProceed = () => {
    dispatch({
      type: "signup/SET_ID_DETAILS",
      payload: idDetails,
    });
  };

  return (
    <Content>
      <div>
        <SelectDetail
          title="Valid ID"
          subtitle="Choose your Valid ID"
          options={validIdsOptions}
          value={idDetails.validId}
          onChange={(e) => {
            setIdDetails({ ...idDetails, validId: e.target.value });
          }}
          required
        />
        <InputDetail
          title="Valid Identification Number"
          subtitle="Enter Valid Identification Number"
          value={idDetails.idNumber}
          onChange={(e) => {
            setIdDetails({
              ...idDetails,
              idNumber: e.target.value,
            });
          }}
          required
          type="string"
        />
        <UploadImageDetail
          title="Upload Valid ID"
          value={idDetails.idImage}
          onChange={(image) => {
            setIdDetails({
              ...idDetails,
              idImage: image,
            });
          }}
          subtitle="Maximum upload size is 4.5mB"
          required
        />
        <UploadImageDetail
          title="Upload Valid ID with yourself"
          value={idDetails.selfieImage}
          onChange={(image) => {
            setIdDetails({
              ...idDetails,
              selfieImage: image,
            });
          }}
          subtitle="Maximum upload size is 4.5mB"
          required
        />
      </div>
      <div>
        <CheckboxContainer>
          <Checkbox
            onChange={() => {
              setChecked(!checked);
            }}
            checked={checked}
          />
          <div>
            I hereby consent to processing of the personal data that I have
            provided and declare my agreement with the data protection
            regulations in the{" "}
            <a href="https://www.google.com/">data privacy</a> of this website
          </div>
        </CheckboxContainer>

        <ProceedButton
          variant="contained"
          onClick={() => {
            dispatch(push("/summary"));
            onProceed();
          }}
          disabled={
            !idDetails.validId ||
            !idDetails.idNumber ||
            !idDetails.idImage ||
            !idDetails.selfieImage ||
            !checked
          }
        >
          Proceed
        </ProceedButton>
      </div>
    </Content>
  );
};

export default connect(mapStateToProps)(SignUpIDFields);
