import React, { useState, useEffect } from "react";
import {
  PaymentDetail,
  PaymentSuccessContainer,
  SuccessContent,
  CircleIcon,
  ResultTitle,
  PaymentContent,
  RecentTransactions,
  TransactionsLink,
  Footer,
  ResultContainer,
  ResultContent,
  CancelIcon,
  ErrorMessage,
  Left,
  Right,
  LineBreak,
  DeliveryTitle,
  NotifMessage,
  Logo,
  BillCaption,
  Note,
  Bill,
  MiscContent,
  BottomContent,
  ResultTitleContainer,
} from "./styles";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import * as messages from "./messages";
import configs from "../../../configs";
import TitleHeader from "../../common/TitleHeader";
import FooterContainer from "../../common/FooterContainer";

const mapStateToProps = ({
  dispatch,
  loadReducer,
  globalReducer,
  wifiReducer,
  billsReducers,
  emoneyReducer,
  cignalReducers,
  cignalwebReducers,
  eGroceryReducer,
  gamingReducer,
  signUpReducer,
}) => ({
  dispatch,
  globalStore: globalReducer,
  loadStore: loadReducer,
  wifiStore: wifiReducer,
  emoneyStore: emoneyReducer,
  billsStore: billsReducers,
  cignalStore: cignalReducers,
  cignalwebStore: cignalwebReducers,
  eGroceryStore: eGroceryReducer,
  signUpStore: signUpReducer,
  gamingStore: gamingReducer,
});

// TODO: rename to payment result
const PaymentSuccesful = ({
  dispatch,
  loadStore,
  globalStore,
  wifiStore,
  emoneyStore,
  billsStore,
  cignalStore,
  cignalwebStore,
  eGroceryStore,
  signUpStore,
  gamingStore,
}) => {
  const [result, setResult] = useState(null);
  const { REACT_APP_STAGE } = process.env;
  const reformatLoad = (load) => {
    let newObject = [];
    Object.entries(load).forEach(([key, value, index]) => {
      if (key === "load") {
        newObject = newObject.concat({ value: value, label: "Product" });
      } else if (key !== "products") {
        const splitKey = key.split(/(?=[A-Z])/);
        const upperCase =
          splitKey[0].charAt(0).toUpperCase() + splitKey[0].slice(1);

        if (splitKey.length > 1) {
          splitKey.splice(0, 1);
          const newString = splitKey
            .reduce((previous, current) => {
              return previous + current + " ";
            }, "")
            .slice(0, -1);
          newObject = newObject.concat({
            value: value,
            label: upperCase + " " + newString,
          });
        } else {
          newObject = newObject.concat({ value: value, label: upperCase });
        }
      }
    });
    return newObject;
  };

  const Payment = ({ value, label, quantity, list }) => {
    return (
      <PaymentDetail>
        <Left>
          {label}
          {quantity && ` x${quantity}`}
        </Left>
        {list ? (
          <div>
            {value.map((l) => {
              return <Right>{l}</Right>;
            })}
          </div>
        ) : (
          <Right>{value}</Right>
        )}
      </PaymentDetail>
    );
  };

  const RenderContent = ({ store, title }) => {
    setResult(store.result);
    const { result, userId, sessionId, ...removeResult } = store;
    return (
      <ResultContent>
        <ResultContainer>
          {store.result !== "success" ||
          (billsStore.transactResponse &&
            billsStore.transactResponse.sessionStatus === "PENDING") ? (
            <Fail />
          ) : (
            <Success title={title} />
          )}
        </ResultContainer>
        <RenderPaymentList list={removeResult} title={title} />
      </ResultContent>
    );
  };

  const RenderPaymentList = ({ list, title }) => {
    if (title === "HANEPMART") {
      const { selectedProducts, ...grocerDetails } = list;

      return (
        <PaymentContent>
          <Payment
            value={selectedProducts}
            label={"Selected Products"}
            key={"Selected Products"}
            list
          />
          <LineBreak></LineBreak>
          <DeliveryTitle>
            <strong> Delivery Information</strong>
          </DeliveryTitle>
          {reformatLoad(grocerDetails).map((item) => {
            return (
              <Payment value={item.value} label={item.label} key={item.label} />
            );
          })}
        </PaymentContent>
      );
    }
    return (
      <PaymentContent>
        {reformatLoad(list).map((item) => {
          if (
            billsStore.billInfo.name === "MERALCO" &&
            item.label === "Number"
          ) {
            return (
              <Payment
                value={item.value}
                label={"Meralco Customer Account Number (CAN)"}
                key={item.label}
              />
            );
          } else if (
            billsStore.billInfo.name === "MERALCO K-LOAD" &&
            item.label === "Number"
          ) {
            return (
              <Payment value={item.value} label={"SIN"} key={item.label} />
            );
          } else {
            return (
              <Payment value={item.value} label={item.label} key={item.label} />
            );
          }
        })}
      </PaymentContent>
    );
  };

  const Success = ({ title }) => {
    return (
      <ResultTitleContainer>
        <CircleIcon />
        <SuccessMessage title={title} />
      </ResultTitleContainer>
    );
  };

  const SuccessMessage = ({ title }) => {
    switch (title) {
      case "BUY LOAD":
      case "WIFI DATA":
      case "E-Wallet Loading":
      case "BUY PREPAID KIT":
      case "CIGNAL PLAY":
      case "HANEPMART":
      case "GAMING EPINS":
        return <ResultTitle>{messages.enqueuedTitle}</ResultTitle>;
      case "APPLY FOR POSTPAID":
      case "SIGN UP":
        return <ResultTitle>{messages.applyTitle}</ResultTitle>;
      default:
        return <ResultTitle>{messages.successTitle}</ResultTitle>;
    }
  };

  const Fail = () => {
    return (
      <ResultTitleContainer>
        <CancelIcon />

        <ResultTitle>
          {billsStore.transactResponse &&
          billsStore.transactResponse.sessionStatus === "CANCELLED"
            ? "Transaction Cancelled!"
            : "Transaction Failed!"}
        </ResultTitle>
      </ResultTitleContainer>
    );
  };
  const RenderContents = ({ title }) => {
    switch (title) {
      case "BUY LOAD":
        return <RenderContent store={loadStore.loadDetails} title={title} />;
      case "WIFI DATA":
        return <RenderContent store={wifiStore.wifiDetails} title={title} />;
      case "E-Wallet Loading":
        return <RenderContent store={emoneyStore.buyDetails} title={title} />;
      case "GAMING EPINS":
        return <RenderContent store={gamingStore.payDetails} title={title} />;
      case "CIGNAL PREPAID":
      case "BUY PREPAID KIT":
      case "APPLY FOR POSTPAID":
      case "PAY POSTPAID":
      case "CIGNAL PLAY":
        return <RenderContent store={cignalStore.payDetails} title={title} />;
      case "CIGNAL DIRECT LOAD TOOL":
        return (
          <RenderContent store={cignalwebStore.payDetails} title={title} />
        );
      case "HANEPMART":
        return (
          <RenderContent store={eGroceryStore.groceryDetails} title={title} />
        );
      case "SIGN UP":
        return (
          <RenderContent
            store={{
              ...signUpStore.formDetails,
              idType: signUpStore.idDetails.validId,
              idNumber: signUpStore.idDetails.idNumber,
            }}
            title={title}
          />
        );
      default:
        return <RenderContent store={billsStore.billDetails} title={title} />;
    }
  };

  const onDone = () => {
    const { request, payload } = dispatchFunction();
    dispatch({
      type: request,
      payload: payload,
    });
  };

  const dispatchFunction = () => {
    switch (globalStore.pageTitle) {
      case "BUY LOAD":
        return {
          request: "load/REQUEST_TRANSACTIONS",
          payload: {
            token: loadStore.sessionDetails.sessionToken,
            limit: 5,
          },
        };
      case "WIFI DATA":
        return {
          request: "wifi/REQUEST_TRANSACTIONS",
          payload: {
            token: wifiStore.sessionDetails.sessionToken,
            limit: 5,
          },
        };
      case "E-Wallet Loading":
        return {
          request: "emoney/REQUEST_TRANSACTIONS",
          payload: {
            token: emoneyStore.sessionDetails.sessionToken,
            limit: 5,
          },
        };
      case "GAMING EPINS":
        return {
          request: "gaming/REQUEST_TRANSACTIONS",
          payload: {
            token: gamingStore.sessionDetails.sessionToken,
            limit: 5,
          },
        };
      case "CIGNAL PREPAID":
      case "BUY PREPAID KIT":
      case "APPLY FOR POSTPAID":
      case "CIGNAL PLAY":
        return {
          request: "cignal/REQUEST_TRANSACTIONS",
          payload: {
            token: cignalStore.sessionDetails.sessionToken,
            limit: 5,
          },
        };
      case "PAY POSTPAID":
        return {
          request: "cignal/REQUEST_TRANSACTIONS_BILL",
          payload: {
            token: cignalStore.sessionDetails.sessionToken,
            limit: 5,
          },
        };
      case "HANEPMART":
        return {
          request: "egrocery/REQUEST_TRANSACTIONS",
          payload: {
            token: eGroceryStore.sessionDetails.sessionToken,
            limit: 5,
          },
        };
      case "SIGN UP":
        return {
          request: "signup/REQUEST_TRANSACTIONS",
          payload: {
            token: "",
            limit: 5,
          },
        };
      default:
        return {
          request: "bills/REQUEST_TRANSACTIONS",
          payload: {
            token: billsStore.sessionDetails.sessionToken,
            limit: 5,
          },
        };
    }
  };

  const returnStoreOutput = () => {
    switch (globalStore.pageTitle) {
      case "BUY LOAD":
        return loadStore.loadDetails;
      case "WIFI DATA":
        return wifiStore.wifiDetails;
      case "E-Wallet Loading":
        return emoneyStore.buyDetails;
      case "GAMING EPINS":
        return gamingStore.payDetails;
      case "CIGNAL PREPAID":
      case "BUY PREPAID KIT":
      case "APPLY FOR POSTPAID":
      case "PAY POSTPAID":
      case "CIGNAL PLAY":
        return cignalStore.payDetails;
      case "CIGNAL DIRECT LOAD TOOL":
        return cignalwebStore.payDetails;
      case "HANEPMART":
        return eGroceryStore.groceryDetails;
      case "SIGN UP":
        return signUpStore.formDetails;
      default:
        return billsStore.billDetails;
    }
  };
  useEffect(() => {
    if (JSON.stringify(returnStoreOutput()) === "{}") {
      dispatch(push("/dashboard"));
    }
  });

  const showLogo = () => {
    return globalStore.billsFlag && configs.config.showLogo;
  };

  return (
    <PaymentSuccessContainer>
      <div>
        <TitleHeader
          title={globalStore.pageTitle}
          type={
            globalStore.sessionDetails && globalStore.sessionDetails.sessionType
          }
        />{" "}
        <SuccessContent>
          <RenderContents title={globalStore.pageTitle} />
          {result === "success" &&
            billsStore.transactResponse.billerMessage && (
              <Bill>
                <BillCaption>
                  {typeof billsStore.transactResponse.billerMessage === "string"
                    ? billsStore.transactResponse.billerMessage
                    : billsStore.transactResponse.billerMessage.message}
                </BillCaption>
                {typeof billsStore.transactResponse.billerMessage !==
                  "string" && (
                  <Note>
                    {billsStore.transactResponse.billerMessage.footer}
                  </Note>
                )}
              </Bill>
            )}
          <>
            {globalStore.pageTitle !== "SIGN UP" &&
              globalStore.pageTitle !== "CIGNAL DIRECT LOAD TOOL" && (
                <RecentTransactions>
                  {showLogo() && (
                    <Logo
                      src={
                        "https://phx-hanepbuhay.s3.amazonaws.com/bc_logo.png"
                      }
                    />
                  )}
                  {messages.listTransactonsTitle}
                  <TransactionsLink
                    onClick={() => {
                      onDone();
                      dispatch(push("/transactions"));
                    }}
                  >
                    {messages.transactionsLink}
                  </TransactionsLink>
                </RecentTransactions>
              )}
          </>
        </SuccessContent>
        <BottomContent>
          <MiscContent>
            {result && result !== "success" ? (
              <ErrorMessage> {result}</ErrorMessage>
            ) : (
              globalStore.pageTitle === "SIGN UP" && (
                <NotifMessage>
                  Wait for our notification within 24 hours.
                </NotifMessage>
              )
            )}
          </MiscContent>
          <Footer>
            {globalStore.sessionDetails.sessionType === "WEB" && (
              <TransactionsLink
                onClick={() => {
                  dispatch(push("/dashboard"));
                }}
              >
                Return to home page
              </TransactionsLink>
            )}

            <div>You have just completed a transaction.</div>
            <div>
              Please close this window if you want to create a new transaction.
            </div>
          </Footer>
        </BottomContent>
      </div>

      <FooterContainer />
    </PaymentSuccessContainer>
  );
};

export default connect(mapStateToProps)(PaymentSuccesful);
