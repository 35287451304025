const hiddenBillers = ["UNBNK", "SLIFE", "GLOBE", "INNOV", "MBCC1", "MECOP", "MMDA1", "PLDT6", "BNECO", "CGNAL", "CLPCO", "DVOLT", "ILECO", "LEYC2", "NORWD", "PNCO1", "STMWD", "VIECO"];

const defaultFAQDetails = [
  { text: "Q: Ano ang HanepBuhay?", type: "centeredfaq" },
  {
    text: "A: Ang mobile negosyo na kung saan ay pwede kang magbenta ng prepaid SMS at data load, at bills payment gamit ang iyong Hanep Credits.",
    type: "faq",
  },
  { text: "Q: Ano ang Hanep Credits?", type: "centeredfaq" },
  {
    text: "A: Ito ang laman ng iyong HanepBuhay wallet. Ito ang gagamitin mo bilang pambayad sa mga e-services.",
    type: "faq",
  },
  { text: " Q: Paano mag-top up ng Hanep Credits?", type: "centeredfaq" },
  {
    text: "A: Tatlong paraan:",
    type: "faq",
  },
  {
    text: "1. Instapay - I-click lamang ang 'Top Up' sa main menu. Wala itong transaction fees!",
    type: "faq",
  },
  {
    text: "2. ScratchIt reimbursement - Pag nagbenta kayo ng ScratchIt at sa inyo nag-claim ng premyo, aabonohan niyo ang prize money kapalit ng winning ticket. Ibibigay niyo ang winning tickets sa inyong Distributor at pag na-validate ang mga winning tickets, ibabalik sa inyo ang inabono niyong pera bilang Hanep Credits.",
    type: "faq",
  },
  {
    text: "3. Distributor - Maari kayong magrequest ng Hanep Credits at magbayad sa inyong Distributor para dito.",
    type: "faq",
  },
  { text: "Q: Paano kumita?", type: "centeredfaq" },
  {
    text: "A: Prepaid load - May discount ka pag nagbenta ka ng prepaid load. Halimbawa: Nagpa-load sayo ang iyong customer ng PHP100. PHP97 lang ang mababawas sa iyong Hanep Credits.",
    type: "faq",
  },
  {
    text: "E-Wallet Cash In (GCash at PayMaya) - Maari kayong mag-charge ng service fee. Paalala: May 1% service charge ang HanepBuhay sa bawat Cash In transaction. Halimbawa: Pag may nagpa-cash in sa iyo ng PHP 500, merong service charge na PHP 5. Ang mababawas sa iyong Hanep Credits ay worth PHP 505.Maari kang kumita sa paglalagay ng markup sa Cash In amount. Halimbawa, kung ang mababawas sa iyong HanepBuhay wallet ay Hanep Credits worth PHP 505, maari mong singilin ng PHP 515 ang iyong customer para sa serbisyo",
    type: "faq",
  },
  {
    text: "Bills payment - May PHP2.50 ka sa bawat bills payment transaction. Maari ka rin mag-charge ng service fee sa iyong customer pangdagdag.Halimbawa: Pag ang customer ay magbayad ng PHP 1,000 para sa bills payment, PHP 997.5 ang mababawas sa iyong Hanep Credits dahil sa iyo ang PHP 2.50. Tapos, pwede kang mag-charge ng P20 service fee para may dagdag kita ka.",
    type: "faq",
  },
  { text: "Q: Paano magbenta ng ScratchIt?", type: "centeredfaq" },
  {
    text: "A: Pag nagbenta kayo ng ScratchIt at may nanalong customer, maari siyang mag-claim ng prize money sa inyong tindahan bilang HanepBuhay retailer. Kukunin mo ang winning ticket bilang kapalit.",
    type: "faq",
  },
  {
    text: "Ibibigay mo ang winning ticket sa iyong Distributor. I-vavalidate ito ng iyong Distributor at pag-navalidate na ito, makukuha mo ang inabono mong prize money bilang Hanep Credits pagkatapos ng 24-48 hours pagkatapos itong ma-scan.",
    type: "faq",
  },
];
const angkasFAQDetails = [
  { text: "Q: Ano ang HanepBuhay?", type: "centeredfaq" },
  {
    text: "A: Ang mobile negosyo na kung saan ay pwede kang magbenta ng prepaid SMS at data load, at bills payment gamit ang iyong Hanep Credits.",
    type: "faq",
  },
  { text: "Q: Ano ang Hanep Credits?", type: "centeredfaq" },
  {
    text: "A: Ito ang laman ng iyong HanepBuhay wallet. Ito ang gagamitin mo bilang pambayad sa mga e-services.",
    type: "faq",
  },
  { text: " Q: Paano mag-top up ng Hanep Credits?", type: "centeredfaq" },
  {
    text: "A: Tatlong paraan:",
    type: "faq",
  },
  {
    text: "1. Instapay - I-click lamang ang 'Top Up' sa main menu. Wala itong transaction fees!",
    type: "faq",
  },
  {
    text: "2. ScratchIt reimbursement - Pag nagbenta kayo ng ScratchIt at sa inyo nag-claim ng premyo, aabonohan niyo ang prize money kapalit ng winning ticket. Ibibigay niyo ang winning tickets sa inyong Distributor at pag na-validate ang mga winning tickets, ibabalik sa inyo ang inabono niyong pera bilang Hanep Credits.",
    type: "faq",
  },
  {
    text: "3. Distributor - Maari kayong magrequest ng Hanep Credits at magbayad sa inyong Distributor para dito.",
    type: "faq",
  },
  { text: "Q: Paano kumita?", type: "centeredfaq" },
  {
    text: "A: Prepaid load - May discount ka pag nagbenta ka ng prepaid load. Halimbawa: Nagpa-load sayo ang iyong customer ng PHP100. PHP97.50 lang ang mababawas sa iyong Hanep Credits.",
    type: "faq",
  },
  {
    text: "E-Wallet Cash In (PayMaya) - Maari kayong mag-charge ng service fee. Paalala: May 1% service charge ang HanepBuhay sa bawat Cash In transaction. Halimbawa: Pag may nagpa-cash in sa iyo ng PHP 500, merong service charge na PHP 5. Ang mababawas sa iyong Hanep Credits ay worth PHP 505.Maari kang kumita sa paglalagay ng markup sa Cash In amount. Halimbawa, kung ang mababawas sa iyong HanepBuhay wallet ay Hanep Credits worth PHP 505, maari mong singilin ng PHP 515 ang iyong customer para sa serbisyo",
    type: "faq",
  },
  {
    text: "Bills payment - May PHP2.50 ka sa bawat bills payment transaction. Maari ka rin mag-charge ng service fee sa iyong customer pangdagdag.Halimbawa: Pag ang customer ay magbayad ng PHP 1,000 para sa bills payment, PHP 997.5 ang mababawas sa iyong Hanep Credits dahil sa iyo ang PHP 2.50. Tapos, pwede kang mag-charge ng P20 service fee para may dagdag kita ka.",
    type: "faq",
  },
  { text: "Q: Paano magbenta ng ScratchIt?", type: "centeredfaq" },
  {
    text: "A: Pag nagbenta kayo ng ScratchIt at may nanalong customer, maari siyang mag-claim ng prize money sa inyong tindahan bilang HanepBuhay retailer. Kukunin mo ang winning ticket bilang kapalit.",
    type: "faq",
  },
  {
    text: "Ibibigay mo ang winning ticket sa iyong Distributor. I-vavalidate ito ng iyong Distributor at pag-navalidate na ito, makukuha mo ang inabono mong prize money bilang Hanep Credits pagkatapos ng 24-48 hours pagkatapos itong ma-scan.",
    type: "faq",
  },
];

const FAQ96Details = [
  { text: "Q: Ano ang HanepBuhay?", type: "centeredfaq" },
  {
    text: "A: Ang mobile negosyo na kung saan ay pwede kang magbenta ng prepaid SMS at data load, at bills payment gamit ang iyong Hanep Credits.",
    type: "faq",
  },
  { text: "Q: Ano ang Hanep Credits?", type: "centeredfaq" },
  {
    text: "A: Ito ang laman ng iyong HanepBuhay wallet. Ito ang gagamitin mo bilang pambayad sa mga e-services.",
    type: "faq",
  },
  { text: " Q: Paano mag-top up ng Hanep Credits?", type: "centeredfaq" },
  {
    text: "A: Tatlong paraan:",
    type: "faq",
  },
  {
    text: "1. Instapay - I-click lamang ang 'Top Up' sa main menu. Wala itong transaction fees!",
    type: "faq",
  },
  {
    text: "2. ScratchIt reimbursement - Pag nagbenta kayo ng ScratchIt at sa inyo nag-claim ng premyo, aabonohan niyo ang prize money kapalit ng winning ticket. Ibibigay niyo ang winning tickets sa inyong Distributor at pag na-validate ang mga winning tickets, ibabalik sa inyo ang inabono niyong pera bilang Hanep Credits.",
    type: "faq",
  },
  {
    text: "3. Distributor - Maari kayong magrequest ng Hanep Credits at magbayad sa inyong Distributor para dito.",
    type: "faq",
  },
  { text: "Q: Paano kumita?", type: "centeredfaq" },
  {
    text: "A: Prepaid load - May discount ka pag nagbenta ka ng prepaid load. Halimbawa: Nagpa-load sayo ang iyong customer ng PHP100. PHP96 lang ang mababawas sa iyong Hanep Credits.",
    type: "faq",
  },
  {
    text: "E-Wallet Cash In (GCash at PayMaya) - Maari kayong mag-charge ng service fee. Paalala: May 1% service charge ang HanepBuhay sa bawat Cash In transaction. Halimbawa: Pag may nagpa-cash in sa iyo ng PHP 500, merong service charge na PHP 5. Ang mababawas sa iyong Hanep Credits ay worth PHP 505.Maari kang kumita sa paglalagay ng markup sa Cash In amount. Halimbawa, kung ang mababawas sa iyong HanepBuhay wallet ay Hanep Credits worth PHP 505, maari mong singilin ng PHP 515 ang iyong customer para sa serbisyo",
    type: "faq",
  },
  {
    text: "Bills payment - May PHP2.50 ka sa bawat bills payment transaction. Maari ka rin mag-charge ng service fee sa iyong customer pangdagdag.Halimbawa: Pag ang customer ay magbayad ng PHP 1,000 para sa bills payment, PHP 997.5 ang mababawas sa iyong Hanep Credits dahil sa iyo ang PHP 2.50. Tapos, pwede kang mag-charge ng P20 service fee para may dagdag kita ka.",
    type: "faq",
  },
  { text: "Q: Paano magbenta ng ScratchIt?", type: "centeredfaq" },
  {
    text: "A: Pag nagbenta kayo ng ScratchIt at may nanalong customer, maari siyang mag-claim ng prize money sa inyong tindahan bilang HanepBuhay retailer. Kukunin mo ang winning ticket bilang kapalit.",
    type: "faq",
  },
  {
    text: "Ibibigay mo ang winning ticket sa iyong Distributor. I-vavalidate ito ng iyong Distributor at pag-navalidate na ito, makukuha mo ang inabono mong prize money bilang Hanep Credits pagkatapos ng 24-48 hours pagkatapos itong ma-scan.",
    type: "faq",
  },
];

const jgFAQDetails = [
  { text: "Q: Ano ang HanepBuhay?", type: "centeredfaq" },
  {
    text: "A: Ang mobile negosyo na kung saan ay pwede kang magbenta ng prepaid SMS at data load, at bills payment gamit ang iyong Hanep Credits.",
    type: "faq",
  },
  { text: "Q: Ano ang Hanep Credits?", type: "centeredfaq" },
  {
    text: "A: Ito ang laman ng iyong HanepBuhay wallet. Ito ang gagamitin mo bilang pambayad sa mga e-services.",
    type: "faq",
  },
  { text: " Q: Paano mag-top up ng Hanep Credits?", type: "centeredfaq" },
  {
    text: "A: Dalawang paraan:",
    type: "faq",
  },
  {
    text: "1. Instapay - I-click lamang ang 'Top Up' sa main menu. Wala itong transaction fees!",
    type: "faq",
  },
  {
    text: "2. Distributor - Maari kayong magrequest ng Hanep Credits at magbayad sa inyong Distributor para dito.",
    type: "faq",
  },
  { text: "Q: Paano kumita?", type: "centeredfaq" },
  {
    text: "A: Prepaid load - May discount ka pag nagbenta ka ng prepaid load. Halimbawa: Nagpa-load sayo ang iyong customer ng PHP100. PHP97 lang ang mababawas sa iyong Hanep Credits.",
    type: "faq",
  },
  {
    text: "E-Wallet Cash In (GCash at PayMaya) - Maari kayong mag-charge ng service fee. Paalala: May 1% service charge ang HanepBuhay sa bawat Cash In transaction. Halimbawa: Pag may nagpa-cash in sa iyo ng PHP 500, merong service charge na PHP 5. Ang mababawas sa iyong Hanep Credits ay worth PHP 505.Maari kang kumita sa paglalagay ng markup sa Cash In amount. Halimbawa, kung ang mababawas sa iyong HanepBuhay wallet ay Hanep Credits worth PHP 505, maari mong singilin ng PHP 515 ang iyong customer para sa serbisyo",
    type: "faq",
  },
  {
    text: "Bills payment - May PHP2.50 ka sa bawat bills payment transaction. Maari ka rin mag-charge ng service fee sa iyong customer pangdagdag.Halimbawa: Pag ang customer ay magbayad ng PHP 1,000 para sa bills payment, PHP 997.5 ang mababawas sa iyong Hanep Credits dahil sa iyo ang PHP 2.50. Tapos, pwede kang mag-charge ng P20 service fee para may dagdag kita ka.",
    type: "faq",
  },
];

const themes = {
  hanepwebview: {
    id: "hanepwebview",
    name: "Hanepwebview",
    colors: {
      primaryColor: "#58BB65",
      secondaryColor: "#98CA4A",
      login: "#1C75BC",
    },
    images: {
      main: "https://phx-hanepbuhay.s3.amazonaws.com/web/home-bg.jpg",
      login: "https://phx-hanepbuhay.s3.amazonaws.com/web/log-in-bg.jpg",
      logo: "https://phx-hanepbuhay.s3.amazonaws.com/web/HanepBuhay+Logo_2500x1000px.png",
    },
    types: {
      default: {},
    },
  },
  hanepbuhay: {
    id: "hanepbuhay",
    name: "Hanepbuhay",
    colors: {
      primaryColor: "#58BB65",
      secondaryColor: "#98CA4A",
      login: "#1C75BC",
    },
    images: {
      main: "https://phx-hanepbuhay.s3.amazonaws.com/web/home-bg.jpg",
      login: "https://phx-hanepbuhay.s3.amazonaws.com/web/log-in-bg.jpg",
      logo: "https://phx-hanepbuhay.s3.amazonaws.com/web/HanepBuhay+Logo_2500x1000px.png",
    },
    topupLink: "https://topup.hanepbuhay.biz/",
    types: {
      default: {},
      localhost: {
        id: 'ASKANI',
        logo: "https://s3-ap-southeast-1.amazonaws.com/glyph-public-assets/phoenix/cignal.png",
        favicon: "https://glyph-public-assets.s3.ap-southeast-1.amazonaws.com/phoenix/cignalfavicon.ico",
        realm: "ASKANI",
        hiddenMenus: [
          'Customer Support',
          "Prepaid",
          "Wifi Data",
          "E-Wallet Loading",
          "Bills Payment",
          "Hanep Mart",
          "Hanep Scanner",
          "Gaming Epins",
          "Hanep FAQs",
        ],
        hiddenBillers: hiddenBillers,
      },
      acsa: {
        hiddenMenus: ["Hanep Scanner", "Hanep Mart", "Gaming Epins", "Paymaya"],
        hiddenBillers: hiddenBillers,
      },
      vitalize: {
        hiddenMenus: ["Hanep Scanner", "Hanep Mart", "Gaming Epins", "Paymaya"],
        hiddenBillers: hiddenBillers,
      },
      jlnet: {
        hiddenMenus: ["Hanep Scanner", "Hanep Mart", "Gaming Epins"],
        hiddenBillers: hiddenBillers,
      },
      webview: {
        hiddenMenus: ["Cignal TV", "Gaming Epins"],
        hiddenBillers: hiddenBillers,
      },
      retailer: {
        hiddenMenus: ["Hanep Scanner", "Cignal TV"],
      },
      prci: {
        hiddenMenus: ["Hanep Scanner", "Cignal TV", "Gaming Epins"],
        logo: "https://phx-hanepbuhay.s3.amazonaws.com/web/logos/Hanepbuhay-prci.png",
        hiddenBillers: hiddenBillers,
        faqDetails: FAQ96Details,
      },
      kensington: {
        hiddenMenus: ["Hanep Scanner", "Cignal TV", "Gaming Epins"],
        logo: "https://phx-hanepbuhay.s3.amazonaws.com/web/logos/Hanepbuhay-kensington.png",
        hiddenBillers: hiddenBillers,
        faqDetails: FAQ96Details,
      },
      valuemart28: {
        hiddenMenus: ["Hanep Scanner", "Cignal TV", "Gaming Epins"],
        logo: "https://phx-hanepbuhay.s3.amazonaws.com/web/logos/Hanepbuhay-valuemart28.png",
        hiddenBillers: hiddenBillers,
        faqDetails: FAQ96Details,
      },
      wti: {
        hiddenMenus: ["Cignal TV", "Gaming Epins"],
        logo: "https://phx-hanepbuhay.s3.amazonaws.com/web/logos/Hanepbuhay-wti.png",
        hiddenBillers: hiddenBillers,
        faqDetails: FAQ96Details,
      },
      jg: {
        hiddenMenus: ["Cignal TV", "Gaming Epins"],
        hiddenBillers: hiddenBillers,
        faqDetails: jgFAQDetails,
      },
      bdp: {
        hiddenMenus: ["Hanep Scanner", "Gaming Epins"],
        faqDetails: defaultFAQDetails,
        hiddenBillers: hiddenBillers,
      },
      apex: {
        hiddenMenus: ["Hanep Scanner", "Cignal TV", "Gaming Epins"],
        logo: "https://phx-hanepbuhay.s3.amazonaws.com/web/logos/Hanepbuhay-apex.png",
        hiddenBillers: hiddenBillers,
        faqDetails: defaultFAQDetails,
      },
      angkas: {
        logo: "https://phx-hanepbuhay.s3.amazonaws.com/web/logos/Hanepbuhay-angkas.png",
        hiddenBillers: hiddenBillers,
        faqDetails: angkasFAQDetails,
        hiddenMenus: [
          "Bills Payment",
          "Hanep Scanner",
          "Hanep Mart",
          "GCash",
          "Cignal TV",
          "Gaming Epins",
        ],
      },
      johari: {
        gcash50: true,
        hiddenMenus: ["Hanep Scanner", "Hanep Mart", "Gaming Epins"],
        hiddenBillers: hiddenBillers,
      },
      z3: {
        gcash50: true,
        hiddenMenus: ["Hanep Scanner", "Hanep Mart"],
        hiddenBillers: hiddenBillers,
      },
      goldentouch: {
        gcash50: true,
        hiddenMenus: ["Hanep Scanner", "Hanep Mart", "Gaming Epins"],
        hiddenBillers: hiddenBillers,
      },
      jjnetwork: {
        gcash50: true,
        hiddenMenus: [
          "Hanep Scanner",
          "Hanep Mart",
          "Cignal TV",
          "Gaming Epins",
        ],
        hiddenBillers: hiddenBillers,
      },
      rload: {
        hiddenMenus: ["Hanep Scanner", "Hanep Mart", "Gaming Epins"],
        hiddenBillers: hiddenBillers,
      },
      goldenwealth: {
        gcash50: true,
        hiddenMenus: ["Hanep Scanner", "Hanep Mart"],
        hiddenBillers: hiddenBillers,
      },
    },
  },
  phoenixph: {
    id: "phoenixph",
    name: "Phoenixph",
    colors: {
      primaryColor: "#58BB65",
      secondaryColor: "#98CA4A",
      login: "#1C75BC",
    },
    images: {
      main: "https://phx-hanepbuhay.s3.amazonaws.com/web/home-bg.jpg",
      login: "https://phx-hanepbuhay.s3.amazonaws.com/web/log-in-bg.jpg",
      logo: "https://phx-hanepbuhay.s3.amazonaws.com/web/HanepBuhay+Logo_2500x1000px.png",
    },
    topupLink: "https://topup.hanepbuhay.biz/",
    types: {
      default: {},
      "cignal-portal-retailer": {
        id: 'ASKANI',
        favicon: "https://glyph-public-assets.s3.ap-southeast-1.amazonaws.com/phoenix/cignalfavicon.ico",
        logo: "https://s3-ap-southeast-1.amazonaws.com/glyph-public-assets/phoenix/cignal.png",
        realm: "ASKANI",
        hiddenMenus: [
          'Customer Support',
          "Prepaid",
          "Wifi Data",
          "E-Wallet Loading",
          "Bills Payment",
          "Hanep Mart",
          "Hanep Scanner",
          "Gaming Epins",
          "Hanep FAQs",
        ],
      },
      "stg-cignal-portal-retailer": {
        id: 'ASKANI',
        favicon: "https://glyph-public-assets.s3.ap-southeast-1.amazonaws.com/phoenix/cignalfavicon.ico",
        logo: "https://s3-ap-southeast-1.amazonaws.com/glyph-public-assets/phoenix/cignal.png",
        realm: "ASKANI",
        hiddenMenus: [
          'Customer Support',
          "Prepaid",
          "Wifi Data",
          "E-Wallet Loading",
          "Bills Payment",
          "Hanep Mart",
          "Hanep Scanner",
          "Gaming Epins",
          "Hanep FAQs",
        ],
      },
      "stg-hanepwebview": {
        hiddenBillers: hiddenBillers,
      },
      "stg-angkas": {
        logo: "https://phx-hanepbuhay.s3.amazonaws.com/web/logos/Hanepbuhay-angkas.png",
        faqDetails: jgFAQDetails,
        hiddenMenus: [
          "Bills Payment",
          "Hanep Scanner",
          "Hanep Mart",
          "Cignal TV",
          "Gaming Epins",
        ],
        hiddenBillers: hiddenBillers,
      },
      "stg-cdsg": {
        id: "stg-cdsg",
        name: "Staging CDSG",
        colors: {
          primaryColor: "#58BB65",
          secondaryColor: "#98CA4A",
          login: "#1C75BC",
        },
        images: {
          main: "https://phx-hanepbuhay.s3.amazonaws.com/web/home-bg.jpg",
          login: "https://phx-hanepbuhay.s3.amazonaws.com/web/log-in-bg.jpg",
          logo: "https://phx-hanepbuhay.s3.amazonaws.com/web/cdsg/CDSG-logo-colored.png",
        },
        topupLink: "https://stg-cdsg-topup.phoenixph.com/",
        types: {
          "stg-cdsg": {
            hiddenMenus: [
              "Bills Payment",
              "Hanep Scanner",
              "Hanep Mart",
              "Hanep FAQs",
              "Gaming Epins",
            ],
          },
        },
        hiddenBillers: hiddenBillers,
      },
      cdsg: {
        id: "cdsg",
        name: "CDSG",
        colors: {
          primaryColor: "#58BB65",
          secondaryColor: "#98CA4A",
          login: "#1C75BC",
        },
        images: {
          main: "https://phx-hanepbuhay.s3.amazonaws.com/web/home-bg.jpg",
          login: "https://phx-hanepbuhay.s3.amazonaws.com/web/log-in-bg.jpg",
          logo: "https://phx-hanepbuhay.s3.amazonaws.com/web/cdsg/CDSG-logo-colored.png",
        },
        topupLink: "https://cdsg-topup.phoenixph.com",
        types: {
          cdsg: {
            hiddenMenus: [
              "Bills Payment",
              "Hanep Scanner",
              "Hanep Mart",
              "Hanep FAQs",
              "Gaming Epins",
            ],
          },
        },
        hiddenBillers: hiddenBillers,
      },
    },
  },
};

const url = window.location.hostname;
const splitURL = url.split(".");
let theme = themes.hanepbuhay;

if (Object.keys(themes).includes(splitURL[0])) {
  theme = themes[`${splitURL[0]}`];
} else if (Object.keys(themes).includes(splitURL[1])) {
  theme = themes[`${splitURL[1]}`];
}

export default theme;
