import React from "react";
import { Container, Title } from "./styles";
import BackButton from "../BackButton";
import { useLocation } from "react-router";

const TitleHeader = ({ title, type }) => {
  const location = useLocation();
  const showBackButton = () => {
    const notAllowedLocations = [
      "/buy-load",
      "/buy-wifi",
      "/buy-emoney",
      "/hanepmart",
      "/pay-bills",
      "/cignal/prepaid",
      "/cignal/postpaid",
      "/cignal/kit",
      "/cignal/play",
      "/cignal/pay",
    ];

    const notAllowed =
      notAllowedLocations.includes(location.pathname) ||
      notAllowedLocations.includes(location.pathname.slice(0, -1));
    if (type === "BOT") {
      return !notAllowed;
    } else if (
      location.pathname === "/result" ||
      location.pathname === "result"
    ) {
      return false;
    } else {
      return notAllowed || type === "WEB";
    }
  };

  return (
    <Container>
      {showBackButton() && <BackButton title={title} />}
      <Title>{title}</Title>
    </Container>
  );
};

export default TitleHeader;
