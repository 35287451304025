import React, { useState } from "react";
import { Grid } from "@mui/material/";
import { Add, Remove } from "@mui/icons-material/";

import {
  GridItem,
  GridImage,
  ItemName,
  Price,
  Description,
  AddButton,
  QuantityButton,
  QuantityContainer,
  Quantity,
} from "./styles";

const Item = ({
  item,
  value,
  returnCartItem,
  setter,
  multiSelect,
  noDescription,
  deletedFromCart,
}) => {
  const [quantity, setQuantity] = useState(
    multiSelect && returnCartItem(item.name).length > 0
      ? returnCartItem(item.name)[0].quantity
      : 0
  );

  const itemIndex = (name, list) => {
    const index = list
      .map(function (e) {
        return e.name;
      })
      .indexOf(name);
    return index;
  };
  return (
    <>
      <Grid item xs={6}>
        <GridItem
          isSelected={
            multiSelect
              ? returnCartItem(item.name).length > 0
              : value.product.name === item.name
          }
          noDescription={noDescription}
          onClick={() => {
            !multiSelect &&
              setter({
                ...value,
                product: item,
              });
          }}
        >
          <GridImage src={item.image} />
          <div>
            <ItemName noDescription={noDescription}>
              <strong>{item.name}</strong>
            </ItemName>
            {!noDescription && <Description>{item.description}</Description>}
          </div>

          <Price>
            {new Intl.NumberFormat("en-US", {
              style: "currency",
              currency: "PHP",
            }).format(item.basePrice)}
          </Price>
          {multiSelect && quantity < 1 && (
            <AddButton
              onClick={() => {
                setter(value.concat({ ...item, quantity: quantity + 1 }));
                setQuantity(quantity + 1);
              }}
            >
              Add to Cart{" "}
            </AddButton>
          )}
          {quantity > 0 && (
            <QuantityContainer>
              <QuantityButton
                onClick={() => {
                  value.splice(itemIndex(item.name, value), 1, {
                    ...item,
                    quantity: quantity - 1,
                  });
                  if (quantity - 1 === 0) {
                    setter(deletedFromCart(item.name));
                  }
                  setQuantity(quantity - 1);
                }}
              >
                <Remove />
              </QuantityButton>
              <Quantity> {quantity}</Quantity>
              <QuantityButton
                onClick={() => {
                  value.splice(itemIndex(item.name, value), 1, {
                    ...item,
                    quantity: quantity + 1,
                  });

                  setQuantity(quantity + 1);
                }}
              >
                <Add />
              </QuantityButton>
            </QuantityContainer>
          )}
        </GridItem>
      </Grid>
    </>
  );
};

export default Item;
