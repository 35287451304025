import loadReducer from "./containers/BuyLoadScreen/reducers";
import globalReducer from "./global/reducers";
import wifiReducer from "./containers/BuyWifiScreen/reducers";
import emoneyReducer from "./containers/BuyEMoneyScreen/reducers";
import billsReducers from "./containers/PayBillsScreen/reducers";
import cignalReducers from "./containers/CignalPrepaidScreen/reducers";
import eGroceryReducer from "./containers/BuyEGroceryScreen/reducers";
import signUpReducer from "./containers/SignUpScreen/reducers";
import loginReducer from "./containers/LoginScreen/reducers";
import transactionsReducer from "./containers/TransactionsScreen/reducers";
import gamingReducer from "./containers/GamingEpinsScreen/reducers";
import cignalwebReducers from "./containers/CignalDirectLoadTool/reducers";

import { connectRouter } from "connected-react-router";
import { combineReducers } from "redux";

const createRootReducer = (history) =>
  combineReducers({
    router: connectRouter(history),
    loadReducer,
    globalReducer,
    wifiReducer,
    emoneyReducer,
    billsReducers,
    cignalReducers,
    eGroceryReducer,
    signUpReducer,
    loginReducer,
    gamingReducer,
    transactionsReducer,
    cignalwebReducers
  });
export default createRootReducer;
