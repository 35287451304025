import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import {
  Content,
  ProceedButton,
  CustomTitle,
  Value,
  LineBreak,
  ErrorMessage,
  DeleteIcon,
  ItemContainer,
  FieldsContent,
  ButtonContent,
  Price,
} from "./styles";
import { push } from "connected-react-router";
import InputDetail from "../../common/InputDetail";
import TitleHeader from "../../common/TitleHeader";

const mapStateToProps = ({ dispatch, eGroceryReducer, globalReducer }) => ({
  dispatch,
  globalStore: globalReducer,
  eGroceryStore: eGroceryReducer,
});

const EGroceryFields = ({ dispatch, eGroceryStore, globalStore }) => {
  const [groceryDetails, setGroceryDetails] = useState({
    firstName: eGroceryStore.groceryDetails.firstName,
    lastName: eGroceryStore.groceryDetails.lastName,
    address: eGroceryStore.groceryDetails.address,
    email: eGroceryStore.groceryDetails.email,
    cartDetails: eGroceryStore.cartDetails,
    mobileNumber: eGroceryStore.groceryDetails.mobileNumber,
  });
  const [cartDetails, setCartDetails] = useState(groceryDetails.cartDetails);
  const [error, setError] = useState(null);

  const cartTotal = (cart) => {
    return cart.reduce((total, product) => {
      return total + parseFloat(product.basePrice) * parseInt(product.quantity);
    }, 0);
  };

  const checkFields = () => {
    if (cartDetails.length < 1) {
      return "Cart is empty, return to the previous page to pick a product";
    } else if (
      /[^a-zA-Z ]/.test(groceryDetails.firstName) ||
      /[^a-zA-Z ]/.test(groceryDetails.lastName)
    ) {
      return "First/Last name should not have any numbers or special characters";
    } else if (!/\S+@\S+\.\S+/.test(groceryDetails.email)) {
      return "Input a valid email address.";
    } else if (groceryDetails.mobileNumber.length !== 11) {
      return "Input a valid mobile number.";
    } else {
      return false;
    }
  };

  const onProceed = () => {
    const checkedFields = checkFields();
    if (checkedFields) {
      setError(checkedFields);
    } else {
      dispatch(push("/summary"));
      dispatch({
        type: "egrocery/SET_GROCERY_DETAILS",
        payload: {
          ...groceryDetails,
          cartTotal: cartTotal(eGroceryStore.cartDetails),
          userId: eGroceryStore.sessionDetails.userId,
          sessionId: eGroceryStore.sessionDetails.sessionId,
        },
      });
    }
  };

  const deleteItem = (product, cart) => {
    const deletedItemCart = cart.filter((item) => {
      return item !== product;
    });
    setCartDetails(deletedItemCart);
    dispatch({
      type: "egrocery/SET_CART_DETAILS",
      payload: deletedItemCart,
    });
  };

  useEffect(() => {
    function run() {
      setError(false);
    }
    run();
  }, [groceryDetails]);
  return (
    <>
      <Content>
        <div>
          <TitleHeader
            title={globalStore.pageTitle}
            type={
              globalStore.sessionDetails &&
              globalStore.sessionDetails.sessionType
            }
          />{" "}
          <FieldsContent>
            <div>
              <CustomTitle>Selected Product/s</CustomTitle>
              {cartDetails.length > 0 &&
                cartDetails.map((product) => {
                  return (
                    <Value>
                      <ItemContainer>
                        <DeleteIcon
                          onClick={() => {
                            deleteItem(product, cartDetails);
                          }}
                        />
                        {product.name} x {product.quantity}
                      </ItemContainer>

                      <Price>
                        {new Intl.NumberFormat("en-US", {
                          style: "currency",
                          currency: "PHP",
                        }).format(product.basePrice * product.quantity)}
                      </Price>
                    </Value>
                  );
                })}
              <LineBreak></LineBreak>

              <Value>
                <div>Total Amount</div>
                <div>
                  {new Intl.NumberFormat("en-US", {
                    style: "currency",
                    currency: "PHP",
                  }).format(cartTotal(cartDetails))}
                </div>
              </Value>
              <CustomTitle>
                <strong>Delivery Details</strong>
              </CustomTitle>
            </div>
            <div>
              <InputDetail
                title={"First Name"}
                subtitle={"Enter First Name"}
                onChange={(e) => {
                  setGroceryDetails({
                    ...groceryDetails,
                    firstName: e.target.value,
                  });
                }}
                value={groceryDetails.firstName}
              />
              <InputDetail
                title={"Last Name"}
                subtitle={"Enter Last Name"}
                onChange={(e) => {
                  setGroceryDetails({
                    ...groceryDetails,
                    lastName: e.target.value,
                  });
                }}
                value={groceryDetails.lastName}
              />
              <InputDetail
                title="Mobile Number"
                subtitle="Enter Mobile Number"
                value={groceryDetails.mobileNumber}
                onChange={(e) => {
                  setGroceryDetails({
                    ...groceryDetails,
                    mobileNumber: e.target.value,
                  });
                }}
                type="number"
              />
              <InputDetail
                title={"Address"}
                subtitle={"Enter Address"}
                onChange={(e) => {
                  setGroceryDetails({
                    ...groceryDetails,
                    address: e.target.value,
                  });
                }}
                value={groceryDetails.address}
              />
              <InputDetail
                title={"Email"}
                subtitle={"Enter Email"}
                onChange={(e) => {
                  setGroceryDetails({
                    ...groceryDetails,
                    email: e.target.value.trim(),
                  });
                }}
                value={groceryDetails.email}
              />
              {error && <ErrorMessage> {error} </ErrorMessage>}
            </div>
          </FieldsContent>
        </div>

        <ButtonContent>
          <ProceedButton
            variant="contained"
            onClick={() => {
              onProceed();
            }}
            disabled={
              !groceryDetails.mobileNumber ||
              !groceryDetails.firstName ||
              !groceryDetails.lastName ||
              !groceryDetails.address ||
              !groceryDetails.email
            }
          >
            Proceed
          </ProceedButton>
        </ButtonContent>
      </Content>
    </>
  );
};

export default connect(mapStateToProps)(EGroceryFields);
