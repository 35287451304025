import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import {
  Content,
  ProceedButton,
  Loading,
  ButtonContent,
  FieldsContent,
} from "./styles";
import { push } from "connected-react-router";
import ItemGrid from "../../components/common/ItemGrid";
import { useLocation } from "react-router";
import queryString from "query-string";
import FooterContainer from "../../components/common/FooterContainer";
import TitleHeader from "../../components/common/TitleHeader";

const mapStateToProps = ({ dispatch, cignalReducers, globalReducer }) => ({
  dispatch,
  cignalStore: cignalReducers,
  globalStore: globalReducer,
});

const CignalPostpaidScreen = ({ dispatch, cignalStore, globalStore }) => {
  const location = useLocation();
  const params = queryString.parse(location.search);
  const [payDetails, setPayDetails] = useState({
    product: cignalStore.product,
  });

  const returnFilteredProducts = (products, subcategory) => {
    const filtered = products.filter((products) => {
      return (
        products.subcategory &&
        products.subcategory.toUpperCase() === subcategory.toUpperCase()
      );
    });

    return filtered;
  };

  const onProceed = (product) => {
    dispatch({
      type: "cignal/SET_PRODUCT",
      payload: product,
    });
  };

  useEffect(() => {
    dispatch({
      type: "global/CURRENT_ENDPOINT",
      payload: "APPLY FOR POSTPAID",
    });
    dispatch({
      type: "cignal/REQUEST_TOKEN",
      payload: {
        userId: params.userId,
        sessionId: params.sessionId,
      },
    });
    dispatch({
      type: "cignal/SET_CATEGORY",
      payload: "POSTPAID",
    });
  }, [dispatch, params.userId, params.sessionId]);

  useEffect(() => {
    if (cignalStore.sessionDetails.sessionToken) {
      dispatch({
        type: "cignal/REQUEST_PRODUCTS",
        payload: {
          token: cignalStore.sessionDetails.sessionToken,
          category: "cignal",
        },
      });
    }
  }, [cignalStore.sessionDetails.sessionToken, dispatch]);

  return (
    <>
      <Content>
        <div>
          <TitleHeader
            title={globalStore.pageTitle}
            type={
              globalStore.sessionDetails &&
              globalStore.sessionDetails.sessionType
            }
          />
          <FieldsContent>
            {cignalStore.loading ? (
              <Loading>Loading ...</Loading>
            ) : (
              cignalStore.products &&
              returnFilteredProducts(cignalStore.products, "POSTPAID").length >
                0 && (
                <ItemGrid
                  list={returnFilteredProducts(
                    cignalStore.products,
                    "POSTPAID"
                  )}
                  setter={setPayDetails}
                  value={payDetails}
                />
              )
            )}
          </FieldsContent>
          <ButtonContent>
            <ProceedButton
              variant="contained"
              onClick={() => {
                dispatch(push("/cignal/fields"));
                onProceed(payDetails.product);
              }}
              disabled={!payDetails.product}
            >
              Proceed
            </ProceedButton>
          </ButtonContent>
        </div>
        <FooterContainer />
      </Content>
    </>
  );
};

export default connect(mapStateToProps)(CignalPostpaidScreen);
