import apiClient from "../../axios";
import { toastError, toastSuccess } from "../../global/constants";

export async function createOTP(payload) {
  try {
    const response = await apiClient.post("/otp/create", payload, {
      auth: {
        username: "test",
        password: "test",
      },
    });
    // const response = {
    //   data: {
    //     data: {
    //       phoenixId: "d252adf5-45cc-4403-9ef2-e7c0fde9de03",
    //       createdAt: "2022-03-03T08:06:39.441Z",
    //       otpId: "822245f7-8f8c-469d-86a1-34f1868de1d8",
    //       otpStatus: "PENDING",
    //     },
    //     message: "SUCCESS",
    //   },
    // };
    return response.data.data;
  } catch (e) {
    const message = e.response.data.error.message;
    const code = e.response.data.error.code;
    toastError(`[${code}] ${message}`);
    return { error: message };
  }
}

export async function verifyOTP(payload) {
  try {
    const response = await apiClient.post("/otp/verify", payload);

    return response.data.data;
  } catch (e) {
    const message = e.response.data.error.message;
    const code = e.response.data.error.code;
    toastError(`[${code}] ${message}`);
    return { error: message };
  }
}

export async function resendOTP(payload) {
  try {
    const response = await apiClient.post("/otp/create", payload, {
      auth: {
        username: "test",
        password: "test",
      },
    });

    toastSuccess(`Successfully resent OTP`);
    return response.data;
  } catch (e) {
    const message = e.response.data.error.message;
    const code = e.response.data.error.code;
    toastError(`[${code}] ${message}`);
    return { error: message };
  }
}

export async function logoutOTP(payload) {
  try {
    const response = await apiClient.post("/otp/logout", payload);
    return response.data;
  } catch (e) {
    const message = e.response.data.error.message;
    const code = e.response.data.error.code;
    toastError(`[${code}] ${message}`);
    return { error: message };
  }
}
