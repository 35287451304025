import React from "react";
import { Container } from "./styles";
import theme from "../../../global/theme";
import { domainType } from "../../../global/constants";
const Footer = () => {
  return (
    <Container>
      {theme.types[`${domainType}`].id === "ASKANI" ?
        'Copyright 2023 Team Phoenix'
        :
        'Copyright © 2021 - present HanepBuhay Negosyo Center. All rights reserved.'
      }

    </Container>
  );
};

export default Footer;
