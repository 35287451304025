import { all, takeEvery, put, call } from "redux-saga/effects";
import { push } from "connected-react-router";
import { actions } from "./reducers";
import * as signUp from "./services";

export function* REQUEST_SIGNUP({ payload }) {
  const { formDetails, idDetails } = payload;
  const reqPayload = {
    firstName: formDetails.firstName,
    lastName: formDetails.lastName,
    referralCode: formDetails.referralCode || "N/A",
    userId: formDetails.userId,
    recipientEmail: formDetails.email,
    recipientMobile: formDetails.mobileNumber,
    recipientAddress: formDetails.fullAddress,
    selfieImage: idDetails.selfieImage,
    idImage: idDetails.idImage,
    validId: idDetails.validId,
    validIdNo: idDetails.idNumber,
  };
  const { userId, ...withoutId } = formDetails;
  let formData = new FormData();

  for (var key in reqPayload) {
    formData.append(key, reqPayload[key]);
  }

  const res = yield call(signUp.signUp, formData);

  if (res.error) {
    yield put({
      type: "signup/RECEIVE_SIGNUP",
      payload: {
        ...withoutId,
        result: res.error,
      },
    });
    yield put(push("/result"));
  } else {
    yield put({
      type: "signup/RECEIVE_SIGNUP",
      payload: {
        ...withoutId,
        result: "success",
      },
    });
    yield put(push("/result"));
  }
}

export default function* rootSaga() {
  yield all([takeEvery(actions.REQUEST_SIGNUP, REQUEST_SIGNUP)]);
}
