const dev = {
  apiRoot: "https://bq7xf3e3p8.execute-api.us-east-1.amazonaws.com/staging",
  showLogo: true,
  encryptor: "bmTO0HfnmbfWCiMUf0gumUkKVDS00qJY",
  epinEncryptor: "0ea6d931-40b7-45cc-acf2-f9cdd879"
};

const staging = {
  apiRoot: "https://bq7xf3e3p8.execute-api.us-east-1.amazonaws.com/staging",
  showLogo: true,
  encryptor: "bmTO0HfnmbfWCiMUf0gumUkKVDS00qJY",
  epinEncryptor: "0ea6d931-40b7-45cc-acf2-f9cdd879",
  inquireCountdown: 10,
  otpResendCountdown: 120000,
  sessionExpiry: 28800000,
  topUpLink: "https://stg-topup.phoenixph.com/",
};

const prod = {
  apiRoot: "https://ig9p1jr28l.execute-api.us-east-1.amazonaws.com/production",
  showLogo: true,
  encryptor: "JxRuXaZ0HbQHXLHvcu1249fxfijeXO8U",
  epinEncryptor: "0ea6d931-40b7-45cc-acf2-f9cdd879",
  inquireCountdown: 10,
  otpResendCountdown: 120000,
  sessionExpiry: 28800000,
  topUpLink: "https://topup.phoenixph.com/",
};

const { REACT_APP_STAGE } = process.env;

let config = staging;

if (REACT_APP_STAGE === "production") config = prod;
else if (REACT_APP_STAGE === "staging") config = staging;

export default {
  // Add common config values here
  config,
};
