import React, { useState, useEffect } from "react";
import { domainType } from "../../global/constants";
import { connect } from "react-redux";
import theme from "../../global/theme";
import {
  Background,
  SelectContainer,
  TransactionContainer,
  Transaction,
  GreenStrip,
  Content,
  Col,
  BottomDetail,
  ModalText,
  ButtonBox,
  ModalButtonRed,
  Details,
  DetailItem,
  Error,
  Loading,
  LeftDetail,
  Wait,
  Empty
} from "./styles";
import FooterContainer from "../../components/common/FooterContainer";
import TitleHeader from "../../components/common/TitleHeader";
import SelectDetail from "../../components/common/SelectDetail";
import ModalDetail from "../../components/common/ModalDetail";
import { CircularProgress } from "@mui/material/";
import { eserviceOptions, dateOptions, inputStyle } from "./constants";
import {
  reformatDateAndTime,
  formatPrice,
  hasValue,
} from "./../../global/helpers";

const mapStateToProps = ({
  dispatch,
  transactionsReducer,
  globalReducer,
  loginReducer,
}) => ({
  dispatch,
  globalStore: globalReducer,
  transactionsStore: transactionsReducer,
  loginStore: loginReducer,
});

const TransactionsScreen = ({
  dispatch,
  transactionsStore,
  globalStore,
  loginStore,
}) => {
  const [openModal, setOpenModal] = useState(false);
  const [selectedTransaction, setSelectedTransaction] = useState(null);
  const [eservice, setEservice] = useState(null);
  const [date, setDate] = useState(null);

  // TODO: On refresh doesnt call endpoint
  useEffect(() => {
    dispatch({
      type: "global/CURRENT_ENDPOINT",
      payload: "TRANSACTIONS",
    });
    if (globalStore.sessionDetails && loginStore.createResponse) {
      theme.types[`${domainType}`].id === "ASKANI" ?
        dispatch({
          type: "transactions/REQUEST_TRANSACTIONS",
          payload: {
            token: globalStore.sessionDetails.sessionToken,
            eservice: "Cignal TV",
            date: null,
          },
        }) :
        dispatch({
          type: "transactions/REQUEST_TRANSACTIONS",
          payload: {
            token: globalStore.sessionDetails.sessionToken,
            eservice: null,
            date: null,
          },
        });
    }
  }, []);

  useEffect(() => {
    if (globalStore.sessionDetails) {
      theme.types[`${domainType}`].id === "ASKANI" ?
        dispatch({
          type: "transactions/REQUEST_TRANSACTIONS",
          payload: {
            token: globalStore.sessionDetails.sessionToken,
            eservice: "Cignal TV",
            date: null,
          },
        }) :
        dispatch({
          type: "transactions/REQUEST_TRANSACTIONS",
          payload: {
            token: globalStore.sessionDetails.sessionToken,
            eservice: null,
            date: null,
          },
        });
    }
  }, [globalStore.sessionDetails]);

  const TransactionItem = ({ transactionDetails }) => {
    const dateTime = reformatDateAndTime(transactionDetails.updatedAt);
    const transactionName =
      transactionDetails.subcategory || transactionDetails.billerName;
    const transactionPrice =
      transactionDetails.totalPrice ||
      transactionDetails.amountPaid ||
      transactionDetails.amount;
    return (
      <Transaction>
        <GreenStrip status={transactionDetails.status}></GreenStrip>
        <Content>
          <Col style={{ textAlign: "left" }}>
            <ModalText
              onClick={() => {
                setSelectedTransaction(transactionDetails);
                setOpenModal(true);
              }}
            >
              <strong>
                {transactionName && transactionName.toUpperCase()} #
                {transactionDetails.referenceId}
              </strong>
            </ModalText>
            <BottomDetail>Date: {dateTime.date}</BottomDetail>
          </Col>
          <Col style={{ textAlign: "right" }}>
            <div>Time: {dateTime.time}</div>
            <BottomDetail>
              <strong>
                {hasValue(transactionPrice)
                  ? formatPrice(transactionPrice)
                  : "No Price"}
              </strong>
            </BottomDetail>
          </Col>
        </Content>
      </Transaction>
    );
  };
  const TransactionModal = ({ transaction }) => {
    const dateTime = reformatDateAndTime(transaction.updatedAt);
    const amountPaid =
      (hasValue(transaction.billerCode) && transaction.deductedAmount) ||
      transaction.totalPrice ||
      transaction.amountPaid ||
      transaction.amount;
    const startBalance = transaction.initialBalance || transaction.startBalance;
    const transactionFeeFlag =
      transaction.billerCode ||
      (transaction.items &&
        hasValue(transaction.items[0].productSku) &&
        transaction.items[0].productSku.includes("GCASH"));
    const transactionFee = hasValue(transaction.markupAmount)
      ? transaction.markupAmount
      : transaction.items
        ? transaction.items[0].priceType === "FIXED"
          ? transaction.items[0].discountedPrice - transaction.items[0].basePrice
          : transaction.items[0].discountedPrice - transaction.items[0].denom
        : null;
    return (
      <ModalDetail
        open={openModal}
        handleClose={() => {
          setOpenModal(false);
        }}
        title={"Transaction Details"}
        content={
          <Details>
            {" "}
            <DetailItem>
              <div>Date:</div>

              {dateTime.date}
            </DetailItem>
            <DetailItem newLine={true && window.screen.availWidth < 550}>
              <LeftDetail>Reference Id:</LeftDetail>

              {transaction.referenceId}
            </DetailItem>
            {hasValue(transaction.recipientMobile) && (
              <DetailItem newLine={true && window.screen.availWidth < 550}>
                <LeftDetail>Recipient Account:</LeftDetail>

                {transaction.recipientMobile}
              </DetailItem>
            )}
            {transaction.items && hasValue(transaction.items[0].productSku) && (
              <DetailItem>
                <div>Product:</div>

                {transaction.items[0].productSku}
              </DetailItem>
            )}
            {hasValue(transactionFee) && (
              <DetailItem>
                {transactionFee > 0 || transactionFeeFlag ? (
                  <>
                    <div>Transaction Fee:</div>
                    {formatPrice(transactionFee)}
                  </>
                ) : (
                  <>
                    <div>Discount:</div>
                    {formatPrice(Math.abs(transactionFee))}
                  </>
                )}
              </DetailItem>
            )}
            <DetailItem>
              <div>Amount Paid:</div>

              {formatPrice(amountPaid)}
            </DetailItem>
            {hasValue(startBalance) && (
              <DetailItem>
                <div>Initial Balance:</div>
                {formatPrice(startBalance)}
              </DetailItem>
            )}
            <DetailItem>
              <div> End Balance:</div>
              {formatPrice(transaction.endBalance)}
            </DetailItem>
            {transaction.items && hasValue(transaction.items[0].error) && (
              <DetailItem newLine={true && window.screen.availWidth < 550}>
                <div style={{ color: "red", minWidth: "100px" }}>Error:</div>

                {transaction.items[0].error}
              </DetailItem>
            )}
            <DetailItem>
              <div>Status:</div>

              {transaction.status}
            </DetailItem>
          </Details>
        }
        buttons={() => {
          return (
            <ButtonBox>
              <ModalButtonRed
                variant="contained"
                onClick={() => {
                  setOpenModal(false);
                }}
              >
                Close
              </ModalButtonRed>
            </ButtonBox>
          );
        }}
      />
    );
  };

  return (
    <>
      <Background>
        <div>
          <TitleHeader
            title={"Transactions"}
            type={
              globalStore.sessionDetails &&
              globalStore.sessionDetails.sessionType
            }
          />{" "}
          <SelectContainer>
            <SelectDetail
              subtitle={theme.types[`${domainType}`].id === "ASKANI" ? "Cignal TV" : "E-Service"}
              options={eserviceOptions}
              selectStyle={inputStyle}
              containerStyle={{
                padding: "0px 10px 10px 0px",
                width: "50%",
              }}
              value={eservice}
              onChange={(e) => {
                setEservice(e.target.value);
                dispatch({
                  type: "transactions/REQUEST_TRANSACTIONS",
                  payload: {
                    token: globalStore.sessionDetails.sessionToken,
                    eservice: e.target.value,
                    date: date ? date : null,
                  },
                });
              }}
            />
            <SelectDetail
              subtitle=" Date Filter"
              options={dateOptions}
              selectStyle={inputStyle}
              containerStyle={{
                padding: "0px 0px 10px 0px",
                width: "50%",
              }}
              value={date}
              onChange={(e) => {
                setDate(e.target.value);
                dispatch({
                  type: "transactions/REQUEST_TRANSACTIONS",
                  payload: {
                    token: globalStore.sessionDetails.sessionToken,
                    eservice: eservice ? eservice : null,
                    date: e.target.value,
                  },
                });
              }}
            />
          </SelectContainer>
          <TransactionContainer>
            {transactionsStore.loading ? (
              <Loading>
                <CircularProgress />
                <Wait>Please wait...</Wait>
              </Loading>
            ) : transactionsStore.transactions &&
              transactionsStore.transactions.error ? (
              <Error> {transactionsStore.transactions.error}</Error>
            ) : (
              transactionsStore.transactions.length > 0 ?
                transactionsStore.transactions.map((transaction) => (
                  <TransactionItem transactionDetails={transaction} />
                )) :
                <Empty> No Transactions found</Empty>
            )}
          </TransactionContainer>
          {selectedTransaction && (
            <TransactionModal transaction={selectedTransaction} />
          )}
        </div>
        <FooterContainer />
      </Background>
    </>
  );
};

export default connect(mapStateToProps)(TransactionsScreen);
