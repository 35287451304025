import { all, takeEvery, put, call } from "redux-saga/effects";
import { push } from "connected-react-router";
import * as egrocery from "./services";
import { actions } from "./reducers";

export function* REQUEST_TOKEN({ payload }) {
  const tokenRes = yield call(egrocery.getToken, payload);
  yield put({
    type: "egrocery/RECEIVE_TOKEN",
    payload: tokenRes,
  });
  yield put({
    type: "global/RECEIVE_SESSION_DETAILS",
    payload: tokenRes,
  });

  if (tokenRes.error) {
    if (tokenRes.type === "WEB") {
      yield put(push("/dashboard"));
    } else {
      yield put(push("/not-found"));
    }
  }
}

export function* REQUEST_PRODUCTS({ payload }) {
  yield put({
    type: "egrocery/SET_LOADING",
    payload: true,
  });
  const { token, category } = payload;
  const productsRes = yield call(egrocery.getProducts, token, category);
  if (productsRes) {
    yield put({
      type: "egrocery/SET_LOADING",
      payload: false,
    });
  }
  yield put({
    type: "egrocery/RECEIVE_PRODUCTS",
    payload: productsRes,
  });
}

export function* REQUEST_BUY_GROCERY({ payload }) {
  const {
    userId,
    sessionId,
    token,
    cartDetails,
    sessionType,
    ...refactoredPayload
  } = payload;
  const reqCartDetails = cartDetails.map((product) => {
    return {
      sku: product.sku,
      basePrice: product.basePrice,
      quantity: product.quantity,
      name: product.name,
      totalPrice: product.basePrice * product.quantity,
    };
  });
  const reqPayload = {
    sku: "HANEPMART",
    recipientMobile: refactoredPayload.mobileNumber,
    recipientAddress: refactoredPayload.address,
    recipientEmail: refactoredPayload.email,
    firstName: refactoredPayload.firstName,
    lastName: refactoredPayload.lastName,
    denom: refactoredPayload.cartTotal,
    sessionId: sessionId,
    userId: userId,
    category: "HANEPBUHAY",
    recipientRemarks: {
      items: reqCartDetails,
    },
  };
  const resCartDetails = cartDetails.map((c) => {
    return `${c.name} x${c.quantity}`;
  });
  const res = yield call(egrocery.orderGrocery, reqPayload, token);

  if (res.error) {
    yield put({
      type: "global/RECEIVE_TRANSACTION_OUTPUT",
      payload: { ...res, result: res.error },
    });

    yield put({
      type: "egrocery/RECEIVE_BUY_GROCERY",
      payload: {
        ...refactoredPayload,
        selectedProducts: resCartDetails,
        result: res.error,
      },
    });
    yield put(push("/result"));
  } else {
    yield put({
      type: "global/RECEIVE_TRANSACTION_OUTPUT",
      payload: { ...res, result: res.error },
    });
    yield put({
      type: "egrocery/RECEIVE_BUY_GROCERY",
      payload: {
        ...refactoredPayload,
        selectedProducts: resCartDetails,
        result: "success",
      },
    });
    if (sessionType === "BOT") {
      yield put(push("/result"));
    }
  }
}

export function* REQUEST_TRANSACTIONS({ payload }) {
  const { limit, token } = payload;
  const transactionsRes = yield call(egrocery.getTransactions, token, limit);
  if (transactionsRes) {
    yield put({
      type: "egrocery/SET_LOADING",
      payload: false,
    });
  }
  const transactions = transactionsRes.map((transactions) => {
    return {
      status: transactions.status,
      time: transactions.updatedAt,
      amount: transactions.totalPrice,
      name: transactions.items[0].productSku,
      reference: transactions.referenceId,
    };
  });
  yield put({
    type: "egrocery/RECEIVE_TRANSACTIONS",
    payload: transactions,
  });
}

export default function* rootSaga() {
  yield all([takeEvery(actions.REQUEST_PRODUCTS, REQUEST_PRODUCTS)]);
  yield all([takeEvery(actions.REQUEST_TOKEN, REQUEST_TOKEN)]);
  yield all([takeEvery(actions.REQUEST_BUY_GROCERY, REQUEST_BUY_GROCERY)]);
  yield all([takeEvery(actions.REQUEST_TRANSACTIONS, REQUEST_TRANSACTIONS)]);
}
