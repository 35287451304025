import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { push } from "connected-react-router";

import {
  Background,
  Content,
  Logo,
  FilledButton,
  ButtonContainer,
  OutlinedButton,
  Separator,
  Link,
  LinkContainer,
} from "./styles";
import OtpInput from "react-otp-input";
import "./styles.css";
import * as messages from "./messages";
import { encrypt } from "../../../global/helpers";
import config from "../../../configs";
import { domainType } from "../../../global/constants";
import theme from "../../../global/theme";             

const mapStateToProps = ({ dispatch, loginReducer, globalReducer }) => ({
  dispatch,
  globalStore: globalReducer,
  loginStore: loginReducer,
});

const OTPScreen = ({ dispatch, loginStore, globalStore }) => {
  let realm = "NEBULA"
  if (theme.types) {
    const typesDetail = theme.types[`${domainType}`];
    realm = typesDetail?.realm || "NEBULA";
  }
  const [disabled, setDisabled] = useState(false);
  const [otp, setOtp] = useState(null);
  const [clicked, setClicked] = useState(false);
  setTimeout(() => {
    setDisabled(false);
  }, config.config.otpResendCountdown);
  useEffect(() => {
    dispatch({
      type: "global/CURRENT_ENDPOINT",
      payload: "OTP",
    });
  }, [dispatch]);
  useEffect(() => {
    if (!(loginStore.createResponse && loginStore.createResponse.otpId)) {
      dispatch(push("/login"));
    }
  }, []);

  return (
    //   NO SIGNUP
    <>
      <Background>
        <Content>
          <Logo></Logo>
          <OtpInput
            inputStyle="otpInput"
            shouldAutoFocus
            value={otp}
            onChange={(otpValue) => {
              setOtp(otpValue);
            }}
            numInputs={6}
            separator={<Separator> </Separator>}
          />
          <LinkContainer>
            {messages.codeSpiel}{" "}
            <Link
              onClick={() => {
                if (!disabled) {
                  setDisabled(true);
                  dispatch({
                    type: "login/REQUEST_RESEND_OTP",
                    payload: {
                      otpId: loginStore.createResponse.otpId,
                      realm: realm,
                    },
                  });
                }
              }}
              disabled={disabled}
            >
              {messages.resend}
            </Link>
          </LinkContainer>
          <ButtonContainer>
            <FilledButton
              variant="contained"
              disabled={
                !otp ||
                !(otp && otp.length === 6) ||
                loginStore.loading ||
                !!globalStore.otpDetails
              }
              onClick={() => {
                setClicked(true);
                dispatch({
                  type: "login/REQUEST_VERIFY_OTP",
                  payload: {
                    otp: encrypt(otp, config.config.encryptor),
                    otpId: loginStore.createResponse.otpId,
                  },
                });
              }}
            >
              {loginStore.loading ? messages.loading : messages.enterButton}
            </FilledButton>
            {/* <OutlinedButton variant="outlined">SIGN UP</OutlinedButton> */}
          </ButtonContainer>
        </Content>
      </Background>
    </>
  );
};

export default connect(mapStateToProps)(OTPScreen);
