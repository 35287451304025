export const actions = {
  REQUEST_TRANSACT: "cignalweb/REQUEST_TRANSACT",
  RECEIVE_TRANSACT: "cignalweb/RECEIVE_TRANSACT",
  SET_PAY_DETAILS: "cignalweb/SET_PAY_DETAILS",
  SET_LOADING: "cignalweb/SET_LOADING",
};

const initialState = {
  payDetails: {},
  sessionDetails: {},
  loading: true,
};

export default function cignalwebReducers(state = initialState, action) {
  switch (action.type) {
    // DO I NEED TOKEN???
    case actions.RECEIVE_TRANSACT:
      return { ...state, payDetails: action.payload };
    case actions.SET_PAY_DETAILS:
      return { ...state, payDetails: action.payload };
    case actions.SET_LOADING:
      return { ...state, loading: action.payload };
    default:
      return state;
  }
}
