import apiClient from "../../axios";
import { toastError } from "../../global/constants";
import { hasValue } from "../../global/helpers";

export async function getTransactions(payload) {
  const categories = {
    Prepaid: "GLOBE,SMART,DITO,TM,SUN,TNT",
    "Wifi data": "GLOBE,SMART,PLDT",
    "E-Wallet Loading": "GCASH,PAYMAYA",
    "Cignal TV": "CIGNAL",
    Hanepmart: "HANEPBUHAY",
    "Gaming Epins": "GAMES",
  };
  const subcategories = {
    Prepaid: "LOAD,DATA,Call and Text Promo",
    "Wifi data": "wifi data",
    "E-Wallet Loading": "CREDITS,CASHOUT",
    "Cignal TV": "Lite,Plan,Play,PPV,Satlite,Prepaid",
    Hanepmart: "",
  };

  const reformatDate = (days) => {
    const date = new Date();
    date.setDate(date.getDate() + days);
    const month = date.getMonth() + 1 > 9 ? date.getMonth() + 1 : `0${date.getMonth() + 1}`;
    const day = date.getDate() + 1 > 9 ? date.getDate()  : `0${date.getDate()}`;
    return `${date.getFullYear()}-${month}-${day}`;
  };
  const dates = {
    Today: reformatDate(0),
    Yesterday: reformatDate(-1),
    "Last 7 days": reformatDate(-7),
    "Last 30 days": reformatDate(-30),
  };

  try {
    let requestUrl = `/web/transactions?`;
    let gamingUrl = `/gaming/transactions?`;
    let filters = [];
    if (hasValue(payload.date)) {
      filters.push(`fromDate=${dates[payload.date]}&toDate=${dates['Today']}`);
    }
    if (hasValue(payload.eservice) && payload.eservice !== "Bills Payment") {
      if (payload.eservice === "Gaming Epins") {
        filters.push(`categories=${categories[payload.eservice]}`);
      } else {
        filters.push(
          `categories=${categories[payload.eservice]}&subcategory=${
            subcategories[payload.eservice]
          }`
        );
      }
    }
    if (filters.length > 0) {
      requestUrl = requestUrl + filters.join("&");
      gamingUrl = gamingUrl + filters.join("&");
    }
    let billsRequestUrl = `/paybills/transactions?`;
    let billsFilters = [];
    if (hasValue(payload.date)) {
      billsFilters.push(`fromDate=${dates[payload.date]}&toDate=${dates['Today']}`);
    }
    if (billsFilters.length > 0) {
      billsRequestUrl = billsRequestUrl + billsFilters.join("&");
    }
    let response = null;
    let billsResponse = null;
    if (hasValue(payload.eservice)) {
      if (payload.eservice === "Bills Payment") {
        billsResponse = await apiClient.get(billsRequestUrl, {
          headers: {
            Authorization: payload.token,
          },
        });
      } else {
        if (payload.eservice === "Gaming Epins") {
          response = await apiClient.get(gamingUrl, {
            headers: {
              Authorization: payload.token,
            },
          });
        } else {
          response = await apiClient.get(requestUrl, {
            headers: {
              Authorization: payload.token,
            },
          });
        }
      }
    } else {
      billsResponse = await apiClient.get(billsRequestUrl, {
        headers: {
          Authorization: payload.token,
        },
      });
      response = await apiClient.get(requestUrl, {
        headers: {
          Authorization: payload.token,
        },
      });
    }

    const billsTransactions =
      hasValue(payload.eservice) && payload.eservice === "Bills Payment"
        ? billsResponse.data.data.transactions
        : [];
    return response
      ? response.data.data.concat(billsTransactions)
      : billsTransactions;
  } catch (e) {
    const message = e.response.data.error.message;
    const code = e.response.data.error.code;
    toastError(`[${code}] ${message}`);
    return { error: message };
  }
}
